import { stableSort } from "./generalUtils";
import type { RichTextStyle } from "./types/editorPlaceholder";
import { RichTextStyleSetting } from "./types/editorPlaceholder";

export type WithFontMetadata<T> = T & FontMetadata;

export type FontMetadata = {
    id: string;
    familyName: string;
    weight: number;
    isBold: boolean;
    isItalic: boolean;
    creationTime: number;
    url: string;
};

export type FontVariations<T extends FontMetadata> = {
    regular?: T;
    italic?: T;
    bold?: T;
    boldItalic?: T;
};

/*
* Get the italic variation of a font.
* In order to get the same italic variation for every call, the fonts list should be sorted by a unique, serial identifier (creation time).
 */
const getFontItalicVariation = <T extends FontMetadata>(font: T, fonts: T[]): T | undefined => {
    return fonts.find(f =>
        f.familyName === font.familyName &&
        f.weight === font.weight &&
        f.isBold === font.isBold &&
        f.isItalic === !font.isItalic);
};

/*
* Gets font family defaults (regular, italic, bold, boldItalic) from the fonts list.
* In order to get the same defaults for every call, the fonts list should be sorted by a unique, serial identifier (creation time).
 */
const getFontFamilyDefaults = <T extends FontMetadata>(familyName: string, fonts: T[]): { regular?: T, italic?: T, bold?: T, boldItalic?: T } => {
    const regular = getFontFamilyRegular(familyName, fonts);
    const italic = regular && getFontItalicVariation(regular, fonts);
    const bold = getFontFamilyBold(familyName, fonts);
    const boldItalic = bold && getFontItalicVariation(bold, fonts);
    return { regular, italic, bold, boldItalic };
};

/*
* Get the regular font for a font family from the fonts list.
* In order to get the same regular font for every call, the fonts list should be sorted by a unique, serial identifier (creation time).
*/
const getFontFamilyRegular = <T extends FontMetadata>(familyName: string, fonts: T[]): T => {
    const regularOptions = fonts.filter(f => f.familyName === familyName && f.weight >= 400 && f.weight < 700 && !f.isBold && !f.isItalic);
    const sortedRegularOptions = stableSort(regularOptions, (a, b) => a.weight - b.weight);
    return sortedRegularOptions.length ? sortedRegularOptions[0] : undefined;
};

/*
* Get the bold font for a font family from the fonts list.
* In order to get the same bold font for every call, the fonts list should be sorted by a unique, serial identifier (creation time).
 */
const getFontFamilyBold = <T extends FontMetadata>(familyName: string, fonts: T[]): T => {
    return fonts.find(f => f.familyName === familyName && f.weight === 700 && f.isBold && !f.isItalic);
};

const getFontVariations = <T extends FontMetadata>(font: T, fontItalicVariation: T, fontFamilyDefaults: FontVariations<T>): FontVariations<T> => {
    const fontVariations: FontVariations<T> = {};
    if (font.isBold && font.isItalic) {
        fontVariations.boldItalic = font;
        fontVariations.bold = fontItalicVariation;
    }
    else if (font.isBold && !font.isItalic) {
        fontVariations.bold = font;
        fontVariations.boldItalic = fontItalicVariation;
    }
    else if (!font.isBold && font.isItalic) {
        fontVariations.italic = font;
        fontVariations.regular = fontItalicVariation;
    }
    else {
        fontVariations.regular = font;
        fontVariations.italic = fontItalicVariation;
    }
    return {
        ...fontFamilyDefaults,
        ...fontVariations
    };
};

const getFontVariation = <T>(fontVariations: FontVariations<WithFontMetadata<T>>, isBold: boolean, isItalic: boolean): WithFontMetadata<T> | undefined => {
    if (isBold && isItalic) {
        return fontVariations.boldItalic;
    }
    else if (isBold && !isItalic) {
        return fontVariations.bold;
    }
    else if (!isBold && isItalic) {
        return fontVariations.italic;
    }
    else {
        return fontVariations.regular;
    }
};

const convertRichTextStyleSettingToBoolean = (fontUnsetValue: boolean, setting: RichTextStyleSetting): boolean => {
    switch (setting) {
        case RichTextStyleSetting.TRUE:
            return true;
        case RichTextStyleSetting.FALSE:
            return false;
        default:
            return fontUnsetValue;
    }
};

export class FontsManager<T> {
    private readonly fonts: WithFontMetadata<T>[] = [];
    private readonly fontsById: Map<string, WithFontMetadata<T>> = new Map<string, WithFontMetadata<T>>();
    private fontFamiliesDefaults: Map<string, FontVariations<WithFontMetadata<T>>> = new Map<string, FontVariations<WithFontMetadata<T>>>();
    private fontsVariations: Map<string, FontVariations<WithFontMetadata<T>>> = new Map<string, FontVariations<WithFontMetadata<T>>>();

    constructor(fonts: WithFontMetadata<T>[]) {
        this.fonts = fonts.sort((a, b) => a.creationTime - b.creationTime);
        this.fonts.forEach(font => this.fontsById.set(font.id, font));
    }

    public getFont = (fontId: string): WithFontMetadata<T> | undefined => {
        return this.fontsById.get(fontId);
    };
    
    /*
    * Get the font variations for formatting.
    * If the font does not support styling, the same font will be returned for all variations.
    * If the font partially supports styling, default variations will be returned.
     */
    public getFontVariationsForFormatting = (fontId: string): Required<FontVariations<WithFontMetadata<T>>> => {
        if (this.isFontDoNotSupportStyling(fontId)) {
            const font = this.getFont(fontId);
            return {
                regular: font,
                italic: font,
                bold: font,
                boldItalic: font
            };
        }
        const fontVariations = this.getFontVariations(fontId);
        return {
            regular: fontVariations.regular,
            italic: fontVariations.italic || fontVariations.regular,
            bold: fontVariations.bold || fontVariations.regular,
            boldItalic: fontVariations.boldItalic || fontVariations.bold || fontVariations.italic
        };
    };

    public doesFontSupportItalic = (fontId: string): boolean => {
        return !!this.getFontVariations(fontId)?.italic;
    };

    public doesFontSupportBold = (fontId: string): boolean => {
        return !!this.getFontVariations(fontId)?.bold;
    };

    public doesFontSupportBoldItalic = (fontId: string): boolean => {
        return !!this.getFontVariations(fontId)?.boldItalic;
    };

    public doesFontSupportRegular = (fontId: string): boolean => {
        return !!this.getFontVariations(fontId)?.regular;
    };

    public getFontVariationUrlForRichTextStyle = (fontId: string, style: RichTextStyle): string => {
        if (!fontId || !this.fontsById.has(fontId)) {
            return undefined;
        }
        return this.getFontVariationForRichTextStyle(fontId, style).url;
    };

    private getFontVariationForRichTextStyle = (fontId: string, richTextStyle: RichTextStyle): WithFontMetadata<T> => {
        if (!this.fontsById.has(fontId)) {
            return;
        }
        const font = this.fontsById.get(fontId);
        const fontVariations = this.getFontVariationsForFormatting(fontId);
        const isBold = convertRichTextStyleSettingToBoolean(font.isBold, richTextStyle.bold);
        const isItalic = convertRichTextStyleSettingToBoolean(font.isItalic, richTextStyle.italic);
        return getFontVariation(fontVariations, isBold, isItalic);
    };

    public getFonts = (): WithFontMetadata<T>[] => {
        return this.fonts;
    };

    //full styling support - has all variation
    public isFontFullySupportStyling = (fontId: string): boolean => {
        const fontVariations = this.getFontVariations(fontId);
        return !!fontVariations.regular && !!fontVariations.italic && !!fontVariations.bold && !!fontVariations.boldItalic;
    };

    //partial styling support - some variations are missing but we support styling
    public isFontPartiallySupportStylingOnly = (fontId: string): boolean => {
        const fontVariations = this.getFontVariations(fontId);
        return !this.isFontFullySupportStyling(fontId) && !!fontVariations.regular && (!!fontVariations.italic || !!fontVariations.bold);
    };

    //no styling support - key variations are missing
    public isFontDoNotSupportStyling = (fontId: string): boolean => {
        return !this.isFontFullySupportStyling(fontId) && !this.isFontPartiallySupportStylingOnly(fontId);
    };

    private getFontVariations = (fontId: string): FontVariations<WithFontMetadata<T>> => {
        if (!this.fontsById.has(fontId)) {
            return {};
        }
        const font = this.fontsById.get(fontId);
        if (!this.fontsVariations.has(font.id)) {
            const fontItalicVariation = getFontItalicVariation(font, this.fonts);
            const fontFamilyDefaults = this.getFontFamilyDefaults(font.familyName);
            this.fontsVariations.set(font.id, getFontVariations(font, fontItalicVariation, fontFamilyDefaults));
        }
        return this.fontsVariations.get(font.id);
    };

    private getFontFamilyDefaults = (familyName: string): FontVariations<WithFontMetadata<T>> => {
        if (!this.fontFamiliesDefaults.has(familyName)) {
            this.fontFamiliesDefaults.set(familyName, getFontFamilyDefaults(familyName, this.fonts));
        }
        return this.fontFamiliesDefaults.get(familyName);
    };
}
