import toposort from "toposort";

export interface IGraph<T> {
    hasVertex: (vertex: T) => boolean;
    addVertex: (vertex: T) => void;
    removeVertex: (vertex: T) => void;
    addEdge: (vertex1: T, vertex2: T) => void;
    detectCycles: () => T[];
    getReversedGraph: () => IGraph<T>;
    getLinkedVertices: (vertex: T) => T[];
    sortByDependencies: () => T[];
    getGraph?: () => any;
}

export class Graph<T> implements IGraph<T> {
    private graph: Map<T, T[]>;

    constructor() {
        this.graph = new Map();
    }

    hasVertex(vertex: T): boolean {
        return this.graph.has(vertex);
    }

    addVertex(vertex: T): void {
        if (!this.graph.has(vertex)) {
            this.graph.set(vertex, []);
        }
    }

    removeVertex(vertex: T): void {
        this.graph.delete(vertex);
    }

    addEdge(vertex1: T, vertex2: T): void {
        if (!this.graph.get(vertex1).includes(vertex2)) {
            this.graph.get(vertex1).push(vertex2);
        }
    }

    detectCycles(): T[] {
        const graphNodes = Array.from(this.graph.keys());
        const visited = {};
        const recStack = {};

        for (let i = 0; i < graphNodes.length; i++) {
            const node = graphNodes[i];
            if (this.detectCycleUtil(node, visited, recStack)) {
                let cycleElements = [];
                Object.keys(recStack).forEach((rec) => {
                    recStack[rec] && cycleElements.push(rec);
                });
                return cycleElements;
            }
        }
        return [];
    }

    getReversedGraph(): IGraph<T> {
        let newGraph = new Graph<T>();

        const vertices = Array.from(this.graph.keys());
        vertices.forEach((vertex) => newGraph.addVertex(vertex));

        vertices.forEach((vertex) => {
            let neighbours = this.graph.get(vertex);
            neighbours.forEach((neighbour) => {
                newGraph.addEdge(neighbour, vertex);
            });
        });

        return newGraph;
    }

    getLinkedVertices(vertex: T): T[] {
        let linkedVertices = new Set<T>();

        // Create our queue and push our root node into it.
        let queue = [];
        queue.push(vertex);

        // Continue searching through as queue as long as it's not empty.
        while (queue.length > 0) {
            // Create a reference to currentNode, at the top of the queue.
            let currentNode = queue[0];
            this.graph.get(currentNode).forEach((neighbour) => {
                if (!linkedVertices.has(neighbour)) {
                    queue.push(neighbour);
                    linkedVertices.add(neighbour);
                }
            });

            queue.shift();
        }

        return Array.from(linkedVertices);
    }

    detectCycleUtil(node, visited, recStack) {
        if (!visited[node]) {
            visited[node] = true;
            recStack[node] = true;
            const nodeNeighbors = this.graph.get(node);

            for (let i = 0; nodeNeighbors && i < nodeNeighbors.length; i++) {
                const currNode = nodeNeighbors[i];
                if (!visited[currNode] && this.detectCycleUtil(currNode, visited, recStack)) {
                    return true;
                }
                else if (recStack[currNode]) {
                    return true;
                }
            }
        }
        recStack[node] = false;
        return false;
    }

    sortByDependencies(): T[] {
        const vertices = Array.from(this.graph.keys());

        const edges = vertices.reduce((acc, vertex) => {
            this.graph.get(vertex).forEach((neighbour) => {
                acc.push([vertex, neighbour]);
            });
            return acc;
        }, []);

        return toposort.array(vertices, edges).reverse();
    }
}
