import type { GraphQLFormattedError } from "graphql";
import type { AxiosError } from "axios";

export const ERROR_INVALID_EMAIL = "Invalid email";
export const ERROR_MISSING_VERIFICATION_CODE = "Missing verification code";
export const ERROR_UNABLE_TO_SEND_VERIFICATION_CODE_EMAIL = "Unable to send email with verification code";
export const ERROR_MISSING_OR_INVALID_PASSWORD = "Missing or invalid new password";
export const ERROR_VERIFICATION_CODE_IS_INCORRECT = "verification code is incorrect";
export const ERROR_VERIFICATION_CODE_IS_EXPIRED = "verification code is expired";
export const ERROR_UNABLE_TO_RESET_PASSWORD = "Unable to reset password";

export const SSKY_ERROR_CODE_KEY = "sskyCode";
export const ERROR_ID_KEY = "errorId";

export enum SskyErrorCode {
    ImageAssetSizeTooBig = "ImageAssetSizeTooBig",
    VideoAssetSizeTooBig = "VideoAssetSizeTooBig",
    AudioAssetSizeTooBig = "AudioAssetSizeTooBig",
    UnsupportedAssetTypeImageAndVideo = "UnsupportedAssetTypeImageAndVideo",
    UnsupportedAssetTypeMusic = "UnsupportedAssetTypeMusic",
    AssetUploadError = "AssetUploadError",
    BackgroundMutationError = "BackgroundMutationError",
    RecordingRequestFailed = "RecordingRequestFailed",
    OutdatedEditorVersion = "OutdatedEditorVersion",
    Unauthorized = "Unauthorized",
    UnexpectedServerError = "UnexpectedServerError",
    UnexpectedClientError = "UnexpectedClientError",
    ClientRuntimeError = "ClientRuntimeError",
    LivePreviewError = "LivePreviewError",
    PreviewError = "PreviewError",
    UnsupportedAssetFontType = "UnsupportedAssetFontType",
    AssetFontUploadError = "AssetFontUploadError",
    DownloadVideoError = "DownloadVideoError",
    ShareVideoToSocialError = "ShareVideoToSocialError",
    ShareToSocialPreviewError = "ShareToSocialPreviewError",
    AssetFontUploadNameExtractionError = "AssetFontUploadNameExtractionError",
    AssetFontUploadImageCreationError = "AssetFontUploadImageCreationError",
    LifecycleChangeDisallowed = "LifecycleChangeDisallowed",
    CopyProgramFailed = "CopyProgramFailed",
    CopyProgramBetweenAccountsFailed = "CopyProgramBetweenAccountsFailed",
    AssetFontSlugCreationError = "AssetFontSlugCreationError",
    ViewerProfileSaveError = "ViewerProfileSaveError",
    ViewerProfileCreateError = "ViewerProfileCreateError",
    ViewerProfileDeleteError = "ViewerProfileDeleteError",
    AssetMediaUploadError = "AssetMediaUploadError",
    AssetMediaDeleteError = "AssetMediaDeleteError",
    AssetMediaEditBadInput = "AssetMediaEditBadInput",
    AssetMediaEditError = "AssetMediaEditError",
    AssetMusicUploadError = "AssetMediaUploadError",
    FavoriteAssetMediaCreationError = "FavoriteAssetMediaCreationError",
    FavoriteAssetMediaDeletionError = "FavoriteAssetMediaDeletionError",
    CsvUploadUnsupportedFile = "CsvUploadUnsupportedFile",
    CsvUploadFileNotUtf8 = "CsvUploadFileNotUtf8",
    CsvUploadFileTooBig = "CsvUploadFileTooBig",
    CsvUploadFileEmpty = "CsvUploadFileEmpty",
    CsvUploadDuplicateHeaders = "CsvUploadDuplicateHeaders",
    CsvUploadIllegalCharacterInHeaders = "CsvUploadIllegalCharacterInHeaders",
    EditorProgramGenerateVideoLinksFailed = "EditorProgramGenerateVideoLinksFailed",
    DataLibraryImportDataFailed = "DataLibraryImportDataFailed",
    StudioElementsCreationErrorParsingCsv = "StudioElementsCreationErrorParsingCsv",
    PreviewVideoPartialNarrations = "PreviewVideoPartialNarrations",
    BulkUploadAssetsTooMany = "BulkUploadAssetsTooMany",
    GettyImagesGeneralError = "GettyImagesGeneralError",
    LandingPagePreviewError = "LandingPagePreviewError",
    LandingPageSaveError = "LandingPageSaveError",
    LandingPageActivationError = "LandingPageActivationError",
    LandingPageDeactivationError = "LandingPageDeactivationError",
    EmbedVideoPagePreviewError = "LandingPagePreviewError",
    EmbedVideoPageSaveError = "LandingPageSaveError",
    PublishToChannelsError = "PublishToChannelsError",
    LandingPageOutOfAppPreviewError = "LandingPageOutOfAppPreviewError",
    LandingPageOutOfAppPreviewOpenNewTabError = "LandingPageOutOfAppPreviewOpenNewTabError",
    EmbedVideoPageOutOfAppPreviewError = "EmbedVideoPageOutOfAppPreviewError",
    EmbedVideoPageOutOfAppPreviewOpenNewTabError = "EmbedVideoPageOutOfAppPreviewOpenNewTabError",
    ReviewPageOutOfAppPreviewError = "ReviewPageOutOfAppPreviewError",
    ReviewPageOutOfAppPreviewOpenNewTabError = "ReviewPageOutOfAppPreviewOpenNewTabError",
    LandingPageDeletionError = "LandingPageDeletionError",
    InvalidEmailError = "InvalidEmail",
    CustomThumbnailUploadError = "CustomThumbnailUploadError",
    MediaCropHintsError = "MediaCropHintsError",
    ExportVideoDescriptionError = "ExportVideoDescriptionError",
    StoryblocksAssetMusicUploadError = "StoryblocksAssetMusicUploadError",
    StoryblocksAssetMusicDownloadError = "StoryblocksAssetMusicDownloadError",
    ProgramReviewQueryForbiddenError = "ProgramReviewQueryForbiddenError",
    ProgramReviewQueryNotExistError = "ProgramReviewQueryNotExistError",
    PromptToVideoError = "PromptToVideoError",
    ProgramReviewCreationFailed = "ProgramReviewCreationFailed",
    ProgramCreateThumbnailFailed = "ProgramCreateThumbnailFailed",
    SubscriptionConnectionFailed = "SubscriptionConnectionFailed",
    BrandConfigurationCollectionInUseError = "BrandConfigurationCollectionInUseError",
    BrandConfigurationNotValid = "BrandConfigurationNotValid",
    CreatedEditorScenesIllegalInput = "CreatedEditorScenesIllegalInput",
    CreateAiPreviewProgramError = "CreateAiPreviewProgramError",
    DuplicateProgramWithRemovedWireframeError = "DuplicateProgramWithRemovedWireframeError",
    DataImportFailed = "DataImportFailed",
    AiImageGenerationFailedGeneral = "AiImageGenerationFailedGeneral",
    AiImageGenerationFailedBadPrompt = "AiImageGenerationFailedBadPrompt",
    AiVideoGenerationFailedGeneral = "AiVideoGenerationFailedGeneral",
    ThirdPartyOAuth2Failed = "ThirdPartyOAuth2Failed",
    DeprecatedProviderError = "DeprecatedProviderError",
    CustomAvatarCreationFailed = "CustomAvatarCreationFailed",
    OpeningImglyFailed = "OpeningImglyFailed"
}

export type FormattedGqlError = GraphQLFormattedError & {
    extensions: GraphQLFormattedError["extensions"] & {
        [ERROR_ID_KEY]: string;
        [SSKY_ERROR_CODE_KEY]: SskyErrorCode;
    };
};

export const getSskyErrorCodeFromGqlError = (error: GraphQLFormattedError): SskyErrorCode => {
    return error?.extensions?.[SSKY_ERROR_CODE_KEY] as SskyErrorCode ?? SskyErrorCode.UnexpectedServerError;
};

export const getSskyErrorCodeFromRestError = (error: AxiosError<{ SSKY_ERROR_CODE_KEY: SskyErrorCode }>): SskyErrorCode => {
    return error?.response?.data?.[SSKY_ERROR_CODE_KEY] ?? SskyErrorCode.UnexpectedServerError;
};

export const getErrorIdFromRestError = (error: AxiosError<{ errorId: SskyErrorCode }>): SskyErrorCode => {
    return error?.response?.data?.errorId;
};
