import type { ConnectorConfiguration, CustomLambdaConfiguration, DataSourceEntityInfo, TrayGoogleSheetsConfiguration } from "@sundaysky/customer-data-common-goblin-ds";
import { ConnectorType } from "@sundaysky/customer-data-common-goblin-ds";

export const TRAY_ENTITY_ID_DELIMITER = "|";

export const getCustomExtractorNameFromConnectorConfiguration = (connectorData: ConnectorConfiguration): string | undefined => {
    if (connectorData?.type === ConnectorType.CustomLambda) {
        return (connectorData.runtimeExtractor as CustomLambdaConfiguration)?.customExtractorName;
    }
};

export const getGoogleSheetsConfigurationFromConnectorConfiguration = (connectorData: ConnectorConfiguration): { spreadSheetId: string, sheetName: string } => {
    let spreadSheetId: string;
    let sheetName: string;
    if (connectorData?.type === ConnectorType.TrayGoogleSheets) {
        const googleSheetsConfiguration = connectorData.runtimeExtractor as TrayGoogleSheetsConfiguration;
        ({ spreadSheetId, sheetName } = googleSheetsConfiguration);
    }
    return { spreadSheetId, sheetName };
};

export const constructTrayEntityId = (connectorType: ConnectorType, entityId: string, parentEntityId?: string): string => {
    // No support for multiple entities (sheets) in TrayGoogleSheets for now
    if (parentEntityId && connectorType !== ConnectorType.TrayGoogleSheets) {
        return `${parentEntityId}${TRAY_ENTITY_ID_DELIMITER}${entityId}`;
    }
    return `${connectorType}${TRAY_ENTITY_ID_DELIMITER}${entityId}`;
};

export const deconstructTrayEntityId = (entityId: string): string => {
    return entityId.split(TRAY_ENTITY_ID_DELIMITER).pop();
};

const getFirstEntityFromFullTrayId = (fullTrayId: string): string => {
    return fullTrayId.split(TRAY_ENTITY_ID_DELIMITER).shift();
};

export const getSourceEntitiesFromSourceFields = (fieldFullIds: Array<string>): Set<string> => {
    const allObjectIds = new Set<string>();
    fieldFullIds.forEach(id => {
        if (id && id != "") {
            allObjectIds.add(getFirstEntityFromFullTrayId(id));
        }
    });
    return allObjectIds;
};

export const getDataSourceEntityInfo = (connectorType: ConnectorType, entityIdentifier): DataSourceEntityInfo => {
    switch (connectorType) {
        case ConnectorType.Salesforce:
            return {
                entityIdentifier,
                entryIdQueryParamName: entityIdentifier
            };
        default:
            return {
                entityIdentifier,
                entryIdQueryParamName: entityIdentifier
            };
    }
};
