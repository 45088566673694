/**** General Types ****/

export enum LogicRuleType {
    Static = "Static",
    Dynamic = "Dynamic",
}

export enum Adjustment {
    ScaleToFit = "scaleToFit",
    ScaleToFill = "scaleToFill",
    Stretch = "stretch",
}

export enum VerticalAlignment {
    Top = "top",
    Center = "center",
    Bottom = "bottom",
}

export enum HorizontalAlignment {
    Left = "left",
    Center = "center",
    Right = "right",
}

export enum TextFit {
    ResizeFont = "resize-font",
    ScrollDown = "scroll-down",
    SplitIntoSections= "split-into-sections"
}

export type AlignmentPair = {
    horizontal: HorizontalAlignment;
    vertical: VerticalAlignment;
};

export enum BreathingType {
    "In"= "In",
    "Out"= "Out",
    "None"= "None",
}

export enum DurationType {
    TruncateContentAtSceneEnd = "TruncateContentAtSceneEnd",
    PlayToEndOfContent = "PlayToEndOfContent",
    LoopContent = "LoopContent"
}

export enum SoundUnit {
    Percent = "%"
}

export type Sound = {
    value: number,
    unit: SoundUnit
}

export enum PlaceholderShowType {
    Static = "static",
    Dynamic = "dynamic",
}

/**
 * Type to define a simple block that shows either static or dynamic content.
 * @param StaticShowType - The type of the static content to show (for example, in media this will be the mediaId, etc.). This should be identical
 *      to the type we use for staticFallback in the PlaceholderContent.
 */
type StaticDynamicShowContent<StaticShowType> = {
    showType: PlaceholderShowType;
    static: StaticShowType;
    dynamic: DataElementDescriptor;
};

/**
 * Type representing a single case in a "message by audience" switch statement.
 * It includes the IDs of the values that will trigger this case and the content to show for that case.
 */
type CaseItemType<T, StaticShowType extends StaticDynamicShowContent<T>> = {
    valueIds: SwitchByType[];
    show: StaticShowType;
}

/**
 * Type representing a "message by audience" statement that shows different content based on the audience.
 * It includes the "by" field that determines which data element to use for audiences, and an array of "cases",
 *      each one defining an audience.
 */
type ContentRulesType<T, StaticShowType extends StaticDynamicShowContent<T>> = {
    by: SwitchBy;
    cases: CaseItemType<T, StaticShowType>[];
};

/**
 * Type representing the "no rules" flow (i.e., when NOT using message by audience).
 */
type ContentNoRulesType<T, StaticShowType extends StaticDynamicShowContent<T>> = {
    show: StaticShowType;
};

//
// Placeholder content fundamentals
//

export type LiteralText = string;
export type MediaUrl = string;
export type DataElementDescriptor = {
    /**
     * Local ID of a Studio Data Element
     */
    localId: string;
};

//
// Text Show
//
export type DynamicTextAtomContent = LiteralText | DataElementDescriptor;
export type StaticTextShow = LiteralText;
export type DynamicTextShow = DynamicTextAtomContent[];

//
// Types of media to show
//
export type StaticMediaShow = MediaUrl;
export type DynamicMediaShow = DataElementDescriptor | MediaUrl;

//
// Narration Show
//
export type StaticNarrationShow = LiteralText;
export type DynamicNarrationShow = DynamicTextAtomContent[];

//
// Switch Fundamentals
//
export type SwitchBy = {
    /**
     * Local ID of a Studio Data Element with a value set (to be used for the byAudience switch)
     */
    localId: string;
};

//TBD: There are possibly more types to be supported here
export type SwitchByType = string;

export type TextCase = {
    valueIds: SwitchByType[];
    show: DynamicTextShow;
};

//Media case moved to V4

export type NarrationCase = {
    valueIds: SwitchByType[];
    show: DynamicNarrationShow;
};

/**** Change here to set content and settings version ****/

export type PlaceholderContent = PlaceholderContentVersion10;
export const PLACEHOLDER_CONTENT_VERSION = 10;

export type TextPlaceholderContent = PlaceholderContentVersion10Text;
export type MediaPlaceholderContent = PlaceholderContentVersion10Media;
export type NarrationPlaceholderContent = PlaceholderContentVersion10Narration;
export type ButtonPlaceholderContent = PlaceholderContentVersion10Button;
export type AvatarPlaceholderContent = PlaceholderContentVersion10Avatar;

//update PlaceholderSettings to point the latest Placeholder Settings version
export type PlaceholderSettings = PlaceholderSettingsVersion14;
export const PLACEHOLDER_SETTINGS_VERSION = 14;

//For convert methods

export type PlaceholderContentVersions = (
    PlaceholderContentVersion1
    | PlaceholderContentVersion2
    | PlaceholderContentVersion3
    | PlaceholderContentVersion4
    | PlaceholderContentVersion5
    | PlaceholderContentVersion6
    | PlaceholderContentVersion7
    | PlaceholderContentVersion8
    | PlaceholderContentVersion9
    | PlaceholderContentVersion10
);
export type PlaceholderSettingsVersions = (
    PlaceholderSettingsVersion1
    | PlaceholderSettingsVersion2
    | PlaceholderSettingsVersion3
    | PlaceholderSettingsVersion4
    | PlaceholderSettingsVersion5
    | PlaceholderSettingsVersion6
    | PlaceholderSettingsVersion7
    | PlaceholderSettingsVersion8
    | PlaceholderSettingsVersion9
    | PlaceholderSettingsVersion10
    | PlaceholderSettingsVersion11
    | PlaceholderSettingsVersion12
    | PlaceholderSettingsVersion13
    | PlaceholderSettingsVersion14
);
/**** Placeholder Content Types ****/

/** version 1 **/
// region version 1

export type PlaceholderContentVersion1 = {
    version: 1;
    logicType: LogicRuleType;
    staticValue: string;
    dynamicValue: {
        // todo - TBD
    };
};
// endregion

/** version 2 **/
// region version 2

// Text placeholder content blocks
export type TextNoRules = {
    show: DynamicTextShow;
    staticFallback: StaticTextShow;
};

export type TextRules = {
    by: SwitchBy;
    cases: TextCase[];
    staticFallback: StaticTextShow;
};

// Media placeholder content blocks
export type MediaNoRules = {
    show: DynamicMediaShow;
    staticFallback: StaticMediaShow;
};

export type MediaRules = {
    by: SwitchBy;
    cases: MediaCase[];
    staticFallback: StaticMediaShow;
};

// Narration placeholder content blocks
export type NarrationNoRules = {
    show: DynamicNarrationShow;
    staticFallback: StaticNarrationShow;
};

export type NarrationRules = {
    by: SwitchBy;
    cases: NarrationCase[];
    staticFallback: StaticNarrationShow;
};

//ContentVersionV2
export type PlaceholderContentVersion2Base<NoRulesType, RulesType> = {
    version: 2;
    useRules: boolean;
    noRules: NoRulesType;
    rules: RulesType;
};

export type PlaceholderContentVersion2 =
    | PlaceholderContentVersion2Base<TextNoRules, TextRules>
    | PlaceholderContentVersion2Base<MediaNoRules, MediaRules>
    | PlaceholderContentVersion2Base<NarrationNoRules, NarrationRules>;

// endregion

/** version 3 **/
// region version 3

// Text placeholder content blocks
export type TextNoRulesV3 = {
    show: DynamicTextShow;
};

export type TextRulesV3 = {
    by: SwitchBy;
    cases: TextCase[];
};

// Media placeholder content blocks
export type MediaNoRulesV3 = {
    show: DynamicMediaShow;
};

export type MediaRulesV3 = {
    by: SwitchBy;
    cases: MediaCase[];
};

// Narration placeholder content blocks
export type NarrationNoRulesV3 = {
    show: DynamicNarrationShow;
};

export type NarrationRulesV3 = {
    by: SwitchBy;
    cases: NarrationCase[];
};

//ContentVersionV3
export type PlaceholderContentVersion3Base<NoRulesType, RulesType, staticFallbackType> = {
    version: 3;
    useRules: boolean;
    noRules: NoRulesType;
    rules: RulesType;
    staticFallback: staticFallbackType;
};

export type PlaceholderContentVersion3 =
    | PlaceholderContentVersion3Base<TextNoRulesV3, TextRulesV3, StaticTextShow>
    | PlaceholderContentVersion3Base<MediaNoRulesV3, MediaRulesV3, StaticMediaShow>
    | PlaceholderContentVersion3Base<NarrationNoRulesV3, NarrationRulesV3, StaticNarrationShow>;

// endregion

/** version 4 **/
// region version 4

// Text placeholder content blocks
export type TextNoRulesV4 = TextNoRulesV3;
export type TextRulesV4 = TextRulesV3;

// Media placeholder content blocks

export type MediaShow = {
    showType: PlaceholderShowType;
    static: StaticMediaShow;
    dynamic: DataElementDescriptor;
};

export type MediaNoRulesV4 = {
    show: MediaShow;
};

export type MediaRulesV4 = {
    by: SwitchBy;
    cases: MediaCase[];
};

export type MediaCase = {
    valueIds: SwitchByType[];
    show: MediaShow;
};

// Narration placeholder content blocks
export type NarrationNoRulesV4 = NarrationNoRulesV3;
export type NarrationRulesV4 = NarrationRulesV3;

//ContentVersionV3
export type PlaceholderContentVersion4Base<NoRulesType, RulesType, staticFallbackType> = {
    version: 4;
    useRules: boolean;
    noRules: NoRulesType;
    rules: RulesType;
    staticFallback: staticFallbackType;
};

export type PlaceholderContentVersion4Text = PlaceholderContentVersion4Base<TextNoRulesV4, TextRulesV4, StaticTextShow>;
export type PlaceholderContentVersion4Media = PlaceholderContentVersion4Base<MediaNoRulesV4, MediaRulesV4, StaticMediaShow>;
export type PlaceholderContentVersion4Narration = PlaceholderContentVersion4Base<NarrationNoRulesV4, NarrationRulesV4, StaticNarrationShow>;

export type CaseType = MediaCaseV8 | TextCaseV5 | ButtonCaseV9 | AvatarCaseV1 | NarrationCaseV10;
export type ShowType = MediaShowV8 | DynamicRichTextAtoms | ButtonShowV9<DynamicTextAtoms, DynamicRichTextAtoms> | AvatarShowV1 | NarrationShow<DynamicTextAtoms>;

export type PlaceholderContentVersion4 = PlaceholderContentVersion4Text | PlaceholderContentVersion4Media | PlaceholderContentVersion4Narration;

// endregion

/** version 5 **/
// region version 5
// version 5 handles CTA Buttons that can have
// * multiple actions (open url, send email, download app, etc)
// * multiple values per show (text, url, icon, label, etc)

export const ButtonActions = { OpenUrl: "openUrl" };
export type ButtonActionType = keyof typeof ButtonActions;

export enum CommonPlaceholderType {
    TEXT = "text",
    MEDIA = "media",
    NARRATION = "narration",
    BUTTON = "button",
    AVATAR = "avatar"
}

export type ButtonShowContentItem = {
    name: ContentName;
    content: MediaShowV6 | DynamicTextShow;
    type: CommonPlaceholderType;
};
export type ButtonShowContent = Array<ButtonShowContentItem>;
export type ButtonActionShow = Record<ButtonActionType, ButtonShowContent>;
export type ButtonShow = {
    useAction: ButtonActionType;
    actionShow: ButtonActionShow;
};

export type ButtonCase = {
    valueIds: SwitchByType[];
    show: ButtonShow;
};

export type ButtonNoRulesV5 = {
    show: ButtonShow;
};

export type ButtonRulesV5 = {
    by: SwitchBy;
    cases: ButtonCase[];
};

export type StaticButtonShow = ButtonShow;

export type PlaceholderContentVersion5Base<PlaceholderType extends CommonPlaceholderType, NoRulesType, RulesType, staticFallbackType> = {
    version: 5;
    placeholderType: PlaceholderType;
    useRules: boolean;
    noRules: NoRulesType;
    rules: RulesType;
    staticFallback: staticFallbackType;
};

export type PlaceholderContentVersion5Text = PlaceholderContentVersion5Base<CommonPlaceholderType.TEXT, TextNoRulesV4, TextRulesV4, StaticTextShow>;
export type PlaceholderContentVersion5Media = PlaceholderContentVersion5Base<CommonPlaceholderType.MEDIA, MediaNoRulesV4, MediaRulesV4, StaticMediaShow>;
export type PlaceholderContentVersion5Narration = PlaceholderContentVersion5Base<CommonPlaceholderType.NARRATION, NarrationNoRulesV4, NarrationRulesV4, StaticNarrationShow>;
export type PlaceholderContentVersion5Button = PlaceholderContentVersion5Base<CommonPlaceholderType.BUTTON, ButtonNoRulesV5, ButtonRulesV5, StaticButtonShow>;
export type PlaceholderContentVersion5 = PlaceholderContentVersion5Text | PlaceholderContentVersion5Media | PlaceholderContentVersion5Narration | PlaceholderContentVersion5Button;

// endregion

/** version 6 **/
// region version 6

export type PlaceholderContentVersion6Base<PlaceholderType extends CommonPlaceholderType, NoRulesType, RulesType, staticFallbackType> = {
    version: 6;
    placeholderType: PlaceholderType;
    useRules: boolean;
    noRules: NoRulesType;
    rules: RulesType;
    staticFallback: staticFallbackType;
};

export type MediaShowV6 = {
    showType: PlaceholderShowType;
    static: StaticMediaShowV6;
    dynamic: DataElementDescriptor;
};

export type StaticMediaShowV6 = {
    mediaId?: string;
    location: StaticMediaShow;
};

export type MediaRulesV6 = {
    by: SwitchBy;
    cases: MediaCaseV6[];
};

export type MediaNoRulesV6 = {
    show: MediaShowV6;
};

export type MediaCaseV6 = {
    valueIds: SwitchByType[];
    show: MediaShowV6;
};

export type PlaceholderContentVersion6Media = PlaceholderContentVersion6Base<CommonPlaceholderType.MEDIA, MediaNoRulesV6, MediaRulesV6, StaticMediaShowV6>;
export type PlaceholderContentVersion6Text = PlaceholderContentVersion6Base<CommonPlaceholderType.TEXT, TextNoRulesV4, TextRulesV4, StaticTextShow>;
export type PlaceholderContentVersion6Narration = PlaceholderContentVersion6Base<CommonPlaceholderType.NARRATION, NarrationNoRulesV4, NarrationRulesV4, StaticNarrationShow>;
export type PlaceholderContentVersion6Button = PlaceholderContentVersion6Base<CommonPlaceholderType.BUTTON, ButtonNoRulesV5, ButtonRulesV5, StaticButtonShow>;
export type PlaceholderContentVersion6 = PlaceholderContentVersion6Media | PlaceholderContentVersion6Text | PlaceholderContentVersion6Narration | PlaceholderContentVersion6Button;

// endregion

/** version 7 **/
// region version 7
// version 7 allows CTA button show items to have the same content as the button text's content

export type PlaceholderContentVersion7Base<PlaceholderType extends CommonPlaceholderType, NoRulesType, RulesType, staticFallbackType> = {
    version: 7;
    placeholderType: PlaceholderType;
    useRules: boolean;
    noRules: NoRulesType;
    rules: RulesType;
    staticFallback: staticFallbackType;
};

export type ButtonShowContentItemV7 = {
    name: string;
    content: MediaShowV6 | DynamicTextShow;
    type: CommonPlaceholderType;
    isContentSameAsButtonText: boolean;
};
export type ButtonShowContentV7 = Array<ButtonShowContentItemV7>;
export type ButtonActionShowV7 = Record<ButtonActionType, ButtonShowContentV7>;
export type ButtonShowV7 = {
    useAction: ButtonActionType;
    actionShow: ButtonActionShowV7;
};

export type ButtonCaseV7 = {
    valueIds: SwitchByType[];
    show: ButtonShowV7;
};

export type ButtonNoRulesV7 = {
    show: ButtonShowV7;
};

export type ButtonRulesV7 = {
    by: SwitchBy;
    cases: ButtonCaseV7[];
};

export type StaticButtonShowV7 = ButtonShowV7;


export type PlaceholderContentVersion7Media = PlaceholderContentVersion7Base<CommonPlaceholderType.MEDIA, MediaNoRulesV6, MediaRulesV6, StaticMediaShowV6>;
export type PlaceholderContentVersion7Text = PlaceholderContentVersion7Base<CommonPlaceholderType.TEXT, TextNoRulesV4, TextRulesV4, StaticTextShow>;
export type PlaceholderContentVersion7Narration = PlaceholderContentVersion7Base<CommonPlaceholderType.NARRATION, NarrationNoRulesV4, NarrationRulesV4, StaticNarrationShow>;
export type PlaceholderContentVersion7Button = PlaceholderContentVersion7Base<CommonPlaceholderType.BUTTON, ButtonNoRulesV7, ButtonRulesV7, StaticButtonShowV7>;
export type PlaceholderContentVersion7 = PlaceholderContentVersion7Media | PlaceholderContentVersion7Text | PlaceholderContentVersion7Narration | PlaceholderContentVersion7Button;

// endregion

/** version 8 **/
// region version 8
// version 8 requires mediaId in static media (instead of it being optional), and removes the location from it.
// It is NOT backward compatible (since items in v7 might not have a mediaId), and we will therefore remove all previous versions once we finish migrating to it

export type PlaceholderContentVersion8Base<PlaceholderType extends CommonPlaceholderType, NoRulesType, RulesType, staticFallbackType> = {
    version: 8;
    placeholderType: PlaceholderType;
    useRules: boolean;
    noRules: NoRulesType;
    rules: RulesType;
    staticFallback: staticFallbackType;
};

export type StaticMediaShowV8 = {
    mediaId: string;
};

export type MediaShowV8 = StaticDynamicShowContent<StaticMediaShowV8>;
export type MediaCaseV8 = CaseItemType<StaticMediaShowV8, MediaShowV8>;
export type MediaRulesV8 = ContentRulesType<StaticMediaShowV8, MediaShowV8>;
export type MediaNoRulesV8 = ContentNoRulesType<StaticMediaShowV8, MediaShowV8>;


export type ButtonShowContentItemV8 = {
    name: string;
    content: MediaShowV8 | DynamicTextShow;
    type: CommonPlaceholderType;
    isContentSameAsButtonText: boolean;
};
export type ButtonShowContentV8 = Array<ButtonShowContentItemV8>;
export type ButtonActionShowV8 = Record<ButtonActionType, ButtonShowContentV8>;
export type ButtonShowV8 = {
    useAction: ButtonActionType;
    actionShow: ButtonActionShowV8;
};

export type ButtonCaseV8 = {
    valueIds: SwitchByType[];
    show: ButtonShowV8;
};

export type ButtonNoRulesV8 = {
    show: ButtonShowV8;
};

export type ButtonRulesV8 = {
    by: SwitchBy;
    cases: ButtonCaseV8[];
};

export type StaticButtonShowV8 = ButtonShowV8;

export type PlaceholderContentVersion8Media = PlaceholderContentVersion8Base<CommonPlaceholderType.MEDIA, MediaNoRulesV8, MediaRulesV8, StaticMediaShowV8>;
export type PlaceholderContentVersion8Text = PlaceholderContentVersion8Base<CommonPlaceholderType.TEXT, TextNoRulesV4, TextRulesV4, StaticTextShow>;
export type PlaceholderContentVersion8Narration = PlaceholderContentVersion8Base<CommonPlaceholderType.NARRATION, NarrationNoRulesV4, NarrationRulesV4, StaticNarrationShow>;
export type PlaceholderContentVersion8Button = PlaceholderContentVersion8Base<CommonPlaceholderType.BUTTON, ButtonNoRulesV8, ButtonRulesV8, StaticButtonShowV8>;
export type PlaceholderContentVersion8 = PlaceholderContentVersion8Media | PlaceholderContentVersion8Text | PlaceholderContentVersion8Narration | PlaceholderContentVersion8Button;

// endregion

/** version 9 - support for rich text **/
/** also in version 9 - support for Avatars **/
// region version 9

export enum RichTextStyleSetting {
    TRUE = "true",
    FALSE = "false",
    UNSET = "unset"
}

export type RichTextStyle = {
    bold: RichTextStyleSetting;
    italic: RichTextStyleSetting;
}

export type ContentAtom<T> = {
    content: T;
}

export type RichTextAtom<T> = ContentAtom<T> & {
    style: RichTextStyle;
}

export type StaticTextAtom = ContentAtom<LiteralText>;
export type StaticTextAtoms = StaticTextAtom[];
export type DynamicTextAtom = ContentAtom<DynamicTextAtomContent>
export type DynamicTextAtoms = DynamicTextAtom[]
export type StaticRichTextAtom = RichTextAtom<LiteralText>;
export type StaticRichTextAtoms = StaticRichTextAtom[];
export type DynamicRichTextAtom = RichTextAtom<DynamicTextAtomContent>;
export type DynamicRichTextAtoms = DynamicRichTextAtom[];


export type PlaceholderContentVersion9Base<PlaceholderType extends CommonPlaceholderType, NoRulesType, RulesType, staticFallbackType> = {
    version: 9;
    placeholderType: PlaceholderType;
    useRules: boolean;
    noRules: NoRulesType;
    rules: RulesType;
    staticFallback: staticFallbackType;
};

export type TextNoRulesV5 = {
    show: DynamicRichTextAtoms;
};

export type TextCaseV5 = {
    valueIds: SwitchByType[];
    show: DynamicRichTextAtoms;
};

export type TextRulesV5 = {
    by: SwitchBy;
    cases: TextCaseV5[];
};

export type NarrationNoRulesV5 = {
    show: DynamicTextAtoms;
};

export type NarrationCaseV5 = {
    valueIds: SwitchByType[];
    show: DynamicTextAtoms;
};

export type NarrationRulesV5 = {
    by: SwitchBy;
    cases: NarrationCaseV5[];
};

export enum ButtonShowContentItemElement {
    Text = "text",
    URL = "url",
    Report = "report"
}

export type ButtonShowContentTextItemV9<RichTextShow> = {
    content: RichTextShow;
    isContentSameAsButtonText: boolean;
};

export type ButtonShowContentUrlItemV9<TextShow> = {
    content: TextShow;
    isContentSameAsButtonText: boolean;
};

export type ButtonShowContentReportItemV9 = {
    content: StaticTextAtoms;
    isContentSameAsButtonText: boolean;
};

export type ButtonShowContentV9<TextShow, RichTextShow> = {
    [ButtonShowContentItemElement.Text]: ButtonShowContentTextItemV9<RichTextShow>
    [ButtonShowContentItemElement.URL]: ButtonShowContentUrlItemV9<TextShow>
    [ButtonShowContentItemElement.Report]: ButtonShowContentReportItemV9
};
export type ButtonActionShowV9<TextShow, RichTextShow> = Record<ButtonActionType, ButtonShowContentV9<TextShow, RichTextShow>>;
export type ButtonShowV9<TextShow, RichTextShow> = {
    useAction: ButtonActionType;
    actionShow: ButtonActionShowV9<TextShow, RichTextShow>;
};

export type ButtonCaseV9 = {
    valueIds: SwitchByType[];
    show: ButtonShowV9<DynamicTextAtoms, DynamicRichTextAtoms>;
};

export type ButtonNoRulesV9 = {
    show: ButtonShowV9<DynamicTextAtoms, DynamicRichTextAtoms>;
};

export type ButtonRulesV9 = {
    by: SwitchBy;
    cases: ButtonCaseV9[];
};

// region Avatar atom types

export enum AvatarSource {
    CC = "cc",
    ACCOUNT = "account",
}

export type StaticAvatarShowV1 = {
    avatarLibrary: AvatarSource;
    // local ID of the avatar (if the avatar is from cc, this is the value of "avatar_id" field in 'cc_avatars',
    //              if the avatar is from account, this is the value of "avatar_id" field in 'editor_account_level_avatars')
    avatarId: string;
};

export type AvatarShowV1 = StaticDynamicShowContent<StaticAvatarShowV1>;
export type AvatarCaseV1 = CaseItemType<StaticAvatarShowV1, AvatarShowV1>;
export type AvatarRulesV1 = ContentRulesType<StaticAvatarShowV1, AvatarShowV1>;
export type AvatarNoRulesV1 = ContentNoRulesType<StaticAvatarShowV1, AvatarShowV1>;

// endregion

export type StaticButtonShowV9 = ButtonShowV9<StaticTextAtoms, StaticRichTextAtoms>;

export type PlaceholderContentVersion9Media = PlaceholderContentVersion9Base<CommonPlaceholderType.MEDIA, MediaNoRulesV8, MediaRulesV8, StaticMediaShowV8>;
export type PlaceholderContentVersion9Text = PlaceholderContentVersion9Base<CommonPlaceholderType.TEXT, TextNoRulesV5, TextRulesV5, StaticRichTextAtoms>;
export type PlaceholderContentVersion9Narration = PlaceholderContentVersion9Base<CommonPlaceholderType.NARRATION, NarrationNoRulesV5, NarrationRulesV5, StaticTextAtoms>;
export type PlaceholderContentVersion9Button = PlaceholderContentVersion9Base<CommonPlaceholderType.BUTTON, ButtonNoRulesV9, ButtonRulesV9, StaticButtonShowV9>;
export type PlaceholderContentVersion9Avatar = PlaceholderContentVersion9Base<CommonPlaceholderType.AVATAR, AvatarNoRulesV1, AvatarRulesV1, StaticAvatarShowV1>;
export type PlaceholderContentVersion9 = (
    PlaceholderContentVersion9Media
    | PlaceholderContentVersion9Text
    | PlaceholderContentVersion9Narration
    | PlaceholderContentVersion9Button
    | PlaceholderContentVersion9Avatar
);

// endregion

/** version 10 - support narration placeholder speed **/
// region version 10

export type PlaceholderContentVersion10Base<PlaceholderType extends CommonPlaceholderType, NoRulesType, RulesType, StaticFallbackType> = {
    version: 10;
    placeholderType: PlaceholderType;
    useRules: boolean;
    noRules: NoRulesType;
    rules: RulesType;
    staticFallback: StaticFallbackType;
};

export type NarrationShow<TextType> = {
    text: TextType;
    speed: number;
}
export type NarrationNoRulesV10 = {
    show: NarrationShow<DynamicTextAtoms>;
};
type NarrationCaseV10 = {
    valueIds: SwitchByType[];
    show: NarrationShow<DynamicTextAtoms>;
};
export type NarrationRulesV10 = {
    by: SwitchBy;
    cases: NarrationCaseV10[];
};

export type PlaceholderContentVersion10Media = PlaceholderContentVersion10Base<CommonPlaceholderType.MEDIA, MediaNoRulesV8, MediaRulesV8, StaticMediaShowV8>;
export type PlaceholderContentVersion10Text = PlaceholderContentVersion10Base<CommonPlaceholderType.TEXT, TextNoRulesV5, TextRulesV5, StaticRichTextAtoms>;
export type PlaceholderContentVersion10Button = PlaceholderContentVersion10Base<CommonPlaceholderType.BUTTON, ButtonNoRulesV9, ButtonRulesV9, StaticButtonShowV9>;
export type PlaceholderContentVersion10Avatar = PlaceholderContentVersion10Base<CommonPlaceholderType.AVATAR, AvatarNoRulesV1, AvatarRulesV1, StaticAvatarShowV1>;
export type PlaceholderContentVersion10Narration = PlaceholderContentVersion10Base<CommonPlaceholderType.NARRATION, NarrationNoRulesV10, NarrationRulesV10, NarrationShow<StaticTextAtoms>>;

export type PlaceholderContentVersion10 = PlaceholderContentVersion10Media | PlaceholderContentVersion10Text | PlaceholderContentVersion10Narration | PlaceholderContentVersion10Button
    | PlaceholderContentVersion10Avatar;

// endregion

// region placeholder settings
/**** Placeholder Settings ****/

/** version 1 **/

export type PlaceholderSettingsVersion1 = (PlaceholderSettingsVersion1Media | {}) & {
    // add other settings types if needed
    version: 1;
};

export type PlaceholderSettingsVersion1Media = {
    overrideDefaultAlignment: boolean;
    mediaAdjustment: Adjustment;
    mediaAlignment: AlignmentPair;
};

/** version 2 **/
// Design - https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/2230157440/Text+Placeholder+Color+and+Placeholder+Settings+V2+Design
// ContentType members roughly correlate to 'primitive' placeholder types
export enum ContentType {
    Text = "text",
    Media = "media",
    Button = "button",
    Avatar = "avatar",
    Narration = "narration"
}

// Setting (singular) key is use to define fundamental properties that can change
// SettingKey is split to enums by Content
export enum MediaSettingKey {
    Adjustment = "Adjustment",
    Alignment = "Alignment",
    Color = "Color",
    Breathing = "Breathing",
    PlayToEnd = "PlayToEnd",
    Sound = "Sound",
    OverrideLogo = "OverrideLogo",
    Duration = "Duration"
}

export enum TextSettingKey {
    Color = "Color",
    FontSize = "FontSize",
    BackgroundColor = "BackgroundColor",
    HorizontalAlignment = "HorizontalAlignment",
    VerticalAlignment = "VerticalAlignment",
    Style = "Style",
    Fit = "Fit"
}

export enum NarrationSettingsKey {
    AlternativeVoice = "AlternativeVoice"
}

export enum AvatarSettingsKey {
    // The offset of the avatar content inside the placeholder (with units)
    Offset = "Offset",
    Crop = "Crop",
    DeprecatedAlignment = "Alignment"
}

export enum GlobalSettingKey {
    IsHidden = "IsHidden"
}

export type SettingAtoms = (
    MediaAlignmentSetting
    | AdjustmentSetting
    | ColorSetting
    | HiddenSetting
    | BreathingSetting
    | FontSizeSetting
    | SoundSetting
    | TextHorizontalAlignmentSetting
    | TextVerticalAlignmentSetting
    | OverrideLogoSetting
    | DurationSetting
    | TextStyleSetting
    | TextFitSetting
    | AvatarCropSetting
    | AvatarAlignmentSetting
    | AlternativeVoiceSetting
);
export type SettingKey = (
    MediaSettingKey
    | TextSettingKey
    | GlobalSettingKey
    | NarrationSettingsKey
    | AvatarSettingsKey
);
export type SettingAtom<Key extends SettingKey, Value extends object> = {
    value: Value;
    useValue: boolean;
};

export enum NarratorLibrary {
    CC_NARRATORS = "cc_narrators",
    CC_AVATARS = "cc_avatars",
    EDITOR_ACCOUNT_LEVEL_AVATARS = "editor_account_level_avatars",
}

export type NarratorVoiceData = {
    narratorLibrary: NarratorLibrary;
    // local ID of the narrator record:
    //      If the narratorLibrary is CC_NARRATORS, this is the value of "narrator_id" field in 'cc_narrators'.
    //      If the narratorLibrary is CC_AVATARS, this is the value of "avatar_id" field in 'cc_avatars' (we need to extract the voice from it).
    //      If the narratorLibrary is EDITOR_ACCOUNT_LEVEL_AVATARS, this is the value of "avatar_id" field in 'editor_account_level_avatars'
    //                      (we need to extract the voice from it).
    narratorId: string;
}

export enum AvatarCropPreset {
    FULL_BODY = "full-body",
    CLOSE_UP = "close-up",
}

export type AvatarCropValue = AvatarCropPreset;

export type AdjustmentSetting = SettingAtom<MediaSettingKey.Adjustment, { mediaAdjustment: Adjustment }>;

export type MediaAlignmentSetting = SettingAtom<MediaSettingKey.Alignment, { mediaAlignment: AlignmentPair }>;

export type BreathingSetting = SettingAtom<MediaSettingKey.Breathing, { breathing: BreathingType }>;

export type PlayToEndSetting = SettingAtom<MediaSettingKey.PlayToEnd, null>;

export type SoundSetting = SettingAtom<MediaSettingKey.Sound, Sound>;

export type OverrideLogoSetting = SettingAtom<MediaSettingKey.OverrideLogo, { initialized: boolean }>;

export type DurationSetting = SettingAtom<MediaSettingKey.Duration, { duration: DurationType }>;

export type HiddenSetting = SettingAtom<GlobalSettingKey.IsHidden, { isHidden: boolean } >;

export type AlternativeVoiceSetting = SettingAtom<NarrationSettingsKey.AlternativeVoice, { narratorVoice: NarratorVoiceData } >;

export type AvatarOffsetSetting = SettingAtom<AvatarSettingsKey.Offset, { offset: Position } >;

export type AvatarCropSetting = SettingAtom<AvatarSettingsKey.Crop, { crop: AvatarCropValue }>;

export type AvatarAlignmentSetting = SettingAtom<AvatarSettingsKey.DeprecatedAlignment, { alignment: AlignmentPair }>;

export type ColorSetting = SettingAtom<
    TextSettingKey.Color | MediaSettingKey.Color | TextSettingKey.BackgroundColor,
    {
        customColor: string | null; // null (initially) or 6 digit hex format (#abcdef). See https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/1599275013/fill
        ccDofLocalId: string | null;
    }
>;

export enum FontSizeUnit {
    EM = "em",
    PX = "px"
}

export type FontSizeType = {
    value: number,
    unit: FontSizeUnit
}

export type FontSizeSetting = SettingAtom<
    TextSettingKey.FontSize,
    {
       fontSize: FontSizeType | null;
    }
>;

export type TextHorizontalAlignmentSetting = SettingAtom<TextSettingKey.HorizontalAlignment, { horizontalAlignment: HorizontalAlignment }>;

export type TextVerticalAlignmentSetting = SettingAtom<TextSettingKey.VerticalAlignment, { verticalAlignment: VerticalAlignment }>;

export type TextStyleSetting = SettingAtom<TextSettingKey.Style, { style: string }>;

export type TextFitSetting = SettingAtom<TextSettingKey.Fit, { fit: TextFit }>;

// Settings are collections of setting atoms
type Settings<C extends ContentType, S extends object> = {
    type: C;
    settings: S;
};

export type TextSettingsCollectionV1 = {
    [TextSettingKey.Color]: ColorSetting;
    [TextSettingKey.FontSize]: FontSizeSetting;
};

export type TextSettingsV1 = Settings<
    ContentType.Text,
    TextSettingsCollectionV1
>;

export type TextSettingsCollectionV2 = TextSettingsCollectionV1 & {
    [TextSettingKey.BackgroundColor]: ColorSetting;
};

export type TextSettingsV2 = Settings<
    ContentType.Text,
    TextSettingsCollectionV2
>;

export type TextSettingsCollectionV3 = TextSettingsCollectionV2 & {
    [TextSettingKey.HorizontalAlignment]: TextHorizontalAlignmentSetting;
    [TextSettingKey.VerticalAlignment]: TextVerticalAlignmentSetting;
};

export type TextSettingsV3 = Settings<
    ContentType.Text,
    TextSettingsCollectionV3
>;

export type TextSettingsCollectionV4 = TextSettingsCollectionV3 & {
    [GlobalSettingKey.IsHidden]: HiddenSetting;
};

export type TextSettingsV4 = Settings<
    ContentType.Text,
    TextSettingsCollectionV4
>;

export type TextSettingsCollectionV5 = TextSettingsCollectionV4 & {
    [TextSettingKey.Style]: TextStyleSetting;
    [TextSettingKey.Fit]: TextFitSetting;
};

export type TextSettingsV5 = Settings<
    ContentType.Text,
    TextSettingsCollectionV5
>;

export type MediaSettingsCollectionV2 = {
    [MediaSettingKey.Adjustment]: AdjustmentSetting;
    [MediaSettingKey.Alignment]: MediaAlignmentSetting;
    [MediaSettingKey.Color]: ColorSetting;
    [GlobalSettingKey.IsHidden]: HiddenSetting;
};

export type MediaSettingsV2 = Settings<
    ContentType.Media,
    MediaSettingsCollectionV2
>;

export type MediaSettingsCollectionV3 = MediaSettingsCollectionV2 & {
    [MediaSettingKey.Breathing]: BreathingSetting;
    [MediaSettingKey.PlayToEnd]: PlayToEndSetting;
};

export type MediaSettingsV3 = Settings<
    ContentType.Media,
    MediaSettingsCollectionV3
>;

export type MediaSettingsCollectionV4 = MediaSettingsCollectionV3 & {
    [MediaSettingKey.Sound]: SoundSetting;
};

export type MediaSettingsV4 = Settings<
    ContentType.Media,
    MediaSettingsCollectionV4
    >;

export type MediaSettingsCollectionV5 = MediaSettingsCollectionV4 & {
    [MediaSettingKey.OverrideLogo]: OverrideLogoSetting;
};

export type MediaSettingsV5 = Settings<
    ContentType.Media,
    MediaSettingsCollectionV5
>;

export type MediaSettingsCollectionV6 = Omit<MediaSettingsCollectionV5, MediaSettingKey.PlayToEnd> & {
    [MediaSettingKey.Duration]: DurationSetting;
};

export type MediaSettingsV6 = Settings<
    ContentType.Media,
    MediaSettingsCollectionV6
>;

export type ButtonSettingsCollectionV1 = {
    [GlobalSettingKey.IsHidden]: HiddenSetting;
};

export type ButtonSettingsV1 = Settings<
    ContentType.Button,
    ButtonSettingsCollectionV1
>;

export enum PositionUnit {
    Percent = "%",
    Pixel = "px"
}

export type Coordinate = {
    value: number;
    unit: PositionUnit;
}

export type Position = {
    top: Coordinate;
    left: Coordinate;
    width: Coordinate;
    height: Coordinate;
}

export type AvatarSettingsCollectionV1 = {};

export type AvatarSettingsV1 = Settings<
    ContentType.Avatar,
    AvatarSettingsCollectionV1
>;

export type AvatarSettingsCollectionV2 = AvatarSettingsCollectionV1 & {
    [AvatarSettingsKey.Crop]: AvatarCropSetting;
};

export type AvatarSettingsV2 = Settings<
    ContentType.Avatar,
    AvatarSettingsCollectionV2
>;

export type AvatarSettingsCollectionV3 = AvatarSettingsCollectionV2 & {
    [AvatarSettingsKey.DeprecatedAlignment]: AvatarAlignmentSetting;
};

export type AvatarSettingsV3 = Settings<
    ContentType.Avatar,
    AvatarSettingsCollectionV3
>;

export type AvatarSettingsCollectionV4 = Omit<AvatarSettingsCollectionV3, AvatarSettingsKey.DeprecatedAlignment>;

export type AvatarSettingsV4 = Settings<
    ContentType.Avatar,
    AvatarSettingsCollectionV4
>;

export type AvatarSettings = AvatarSettingsV4;

export type NarrationSettingsCollectionV1 = {
    [NarrationSettingsKey.AlternativeVoice]: AlternativeVoiceSetting;
};

export type NarrationSettingsV1 = Settings<
    ContentType.Narration,
    NarrationSettingsCollectionV1
>;

//update MediaSettings to point to the latest Media Settings version
export type MediaSettings = MediaSettingsV6;
//update TextSettings to point to the latest Text Settings version
export type TextSettings = TextSettingsV5;

export type ButtonSettings = ButtonSettingsV1;

export type SettingsCollectionsV2 = TextSettingsV1 | MediaSettingsV2;
export type SettingsCollectionsV3 = TextSettingsV1 | MediaSettingsV3;
export type SettingsCollectionsV4 = TextSettingsV1 | MediaSettingsV4;
export type SettingsCollectionsV5 = TextSettingsV2 | MediaSettingsV4;
export type SettingsCollectionsV6 = TextSettingsV3 | MediaSettingsV4;
export type SettingsCollectionsV7 = TextSettingsV3 | MediaSettingsV5;
export type SettingsCollectionsV8 = TextSettingsV4 | MediaSettingsV5 | ButtonSettingsV1;
export type SettingsCollectionsV9 = TextSettingsV5 | MediaSettingsV5 | ButtonSettingsV1;
export type SettingsCollectionsV10 = TextSettingsV5 | MediaSettingsV6 | ButtonSettingsV1 | AvatarSettingsV1 | NarrationSettingsV1;
export type SettingsCollectionsV11 = TextSettingsV5 | MediaSettingsV6 | ButtonSettingsV1 | AvatarSettingsV2 | NarrationSettingsV1;
export type SettingsCollectionsV12 = TextSettingsV5 | MediaSettingsV6 | ButtonSettingsV1 | AvatarSettingsV3 | NarrationSettingsV1;
export type SettingsCollectionsV13 = TextSettingsV5 | MediaSettingsV6 | ButtonSettingsV1 | AvatarSettingsV3 | NarrationSettingsV1;
export type SettingsCollectionsV14 = TextSettingsV5 | MediaSettingsV6 | ButtonSettingsV1 | AvatarSettingsV4 | NarrationSettingsV1;

export type EmptyContent = {}; // for ph types which don't have settings, in order to align them with the latest settings version

// for single content placeholders like text and media, we will put settings under '__defaultContent'
// for multi-content placeholders like Buttons, we need to identify which content to change:
// imagine a Button with 'text', 'url' and 'icon' - the content of all three are editable to the user,
// but only text and icon are onscreen and should have settings. this is where we assign relevant settings to each content
export const DEFAULT_CONTENT = "__defaultContent";
type ContentName = string | typeof DEFAULT_CONTENT;
export type ContentsWithV2Settings = Record<ContentName, SettingsCollectionsV2>;
export type ContentsV2 = ContentsWithV2Settings | EmptyContent;
export type ContentsWithV3Settings = Record<ContentName, SettingsCollectionsV3>;
export type ContentsV3 = ContentsWithV3Settings | EmptyContent;
export type ContentsWithV4Settings = Record<ContentName, SettingsCollectionsV4>;
export type ContentsV4 = ContentsWithV4Settings | EmptyContent;
export type ContentsWithV5Settings = Record<ContentName, SettingsCollectionsV5>;
export type ContentsV5 = ContentsWithV5Settings | EmptyContent;
export type ContentsWithV6Settings = Record<ContentName, SettingsCollectionsV6>;
export type ContentsV6 = ContentsWithV6Settings | EmptyContent;
export type ContentsWithV7Settings = Record<ContentName, SettingsCollectionsV7>;
export type ContentsV7 = ContentsWithV7Settings | EmptyContent;
export type ContentsWithV8Settings = Record<ContentName, SettingsCollectionsV8>;
export type ContentsV8 = ContentsWithV8Settings | EmptyContent;
export type ContentsWithV9Settings = Record<ContentName, SettingsCollectionsV9>;
export type ContentsV9 = ContentsWithV9Settings | EmptyContent;
export type ContentsWithV10Settings = Record<ContentName, SettingsCollectionsV10>;
export type ContentsV10 = ContentsWithV10Settings | EmptyContent;
export type ContentsWithV11Settings = Record<ContentName, SettingsCollectionsV11>;
export type ContentsV11 = ContentsWithV11Settings | EmptyContent;
export type ContentsWithV12Settings = Record<ContentName, SettingsCollectionsV12>;
export type ContentsV12 = ContentsWithV12Settings | EmptyContent;
export type ContentsWithV13Settings = Record<ContentName, SettingsCollectionsV13>;
export type ContentsV13 = ContentsWithV13Settings | EmptyContent;
export type ContentsWithV14Settings = Record<ContentName, SettingsCollectionsV14>;
export type ContentsV14 = ContentsWithV14Settings | EmptyContent;

export type PlaceholderSettingsVersion2 = {
    version: 2;
    contents: ContentsV2;
};

export type PlaceholderSettingsVersion3 = {
    version: 3;
    contents: ContentsV3;
};

export type PlaceholderSettingsVersion4 = {
    version: 4;
    contents: ContentsV4;
};

export type PlaceholderSettingsVersion5 = {
    version: 5;
    contents: ContentsV5;
};

export type PlaceholderSettingsVersion6 = {
    version: 6;
    contents: ContentsV6;
};

export type PlaceholderSettingsVersion7 = {
    version: 7;
    contents: ContentsV7;
};

export type PlaceholderSettingsVersion8 = {
    version: 8;
    contents: ContentsV8;
};

export type PlaceholderSettingsVersion9 = {
    version: 9;
    contents: ContentsV9;
};

export type PlaceholderSettingsVersion10 = {
    version: 10;
    contents: ContentsV10;
};

export type PlaceholderSettingsVersion11 = {
    version: 11;
    contents: ContentsV11;
};

export type PlaceholderSettingsVersion12 = {
    version: 12;
    contents: ContentsV12;
};

export type PlaceholderSettingsVersion13 = {
    version: 13;
    contents: ContentsV13;
};

export type PlaceholderSettingsVersion14 = {
    version: 14;
    contents: ContentsV14;
};

// endregion

//https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/1539702793/Placeholders+Intent+Tagging
export enum PlaceholderIntentName {
    Preheading = "Pre-heading",
    Heading = "Heading",
    Subheading = "Sub-heading",
    BodyText = "Body Text",
    Footnote = "Footnote",
    Bullet = "Bullet",
    Price = "Price",
    Button = "Button", // TODO. This isn't consistent with the placeholder name in the DB. In the DB it's CTA Button.
    ListItem = "List Item",
    Icon = "Icon",
    Logo = "Logo",
    ImageOrVideo = "Image/Video",
    Narration = "Narration",
    CTAButton = "CTA Button",
    TextOverlay = "Text Overlay",
    Avatar = "Avatar",
    MediaOverlay = "Media Overlay"
}

/**
 * Generate a media placeholder content from a static media string
 * @param staticMedia static media to use
 * @returns MediaPlaceholderContent whose sole possible output is the location
 */
export function generateMediaPlaceholderFromStaticMedia(staticMedia: StaticMediaShowV8): MediaPlaceholderContent {
    return {
        version: PLACEHOLDER_CONTENT_VERSION,
        placeholderType: CommonPlaceholderType.MEDIA,
        useRules: false,
        staticFallback: staticMedia,
        rules: { by: { localId: undefined }, cases: [] },
        noRules: {
            show: {
                showType: PlaceholderShowType.Static,
                static: staticMedia,
                dynamic: undefined
            }
        }
    };
}

export type FlexiblePlaceholderSettingsV1 = {
    version: 1;
    top: number;
    left: number;
    width: number;
    height: number;
};

export type FlexiblePlaceholderSettings = FlexiblePlaceholderSettingsV1;
