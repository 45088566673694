import type { SystemDataElement } from "./types/dataElement";
import { ProgramType } from "./types/program";
import { adsSystemDataElementsArr, cxSystemDataElementsArr, systemDataElementsArr } from "@sundaysky/smartvideo-hub-config";

export const SYSTEM_DATA_STORY_ID: string = "HubSystem:Story";

export const getDynamicSystemDataElements = (): SystemDataElement[] => {
    return (systemDataElementsArr as SystemDataElement[]).filter((sde) => sde.id === SYSTEM_DATA_STORY_ID);
};

/**
 * Gets the concatenated array of system data elements and dynamic system data elements
 * @param programType - the program type we want to get the data elements for
 * @param isEditorAccountLibraryProgram - the editor's Account Library program should include both ads and cx system data elements
 */
export const getAllSystemDataElements = (programType: string, isEditorAccountLibraryProgram: boolean = false): SystemDataElement[] => {
    let sysDEArray: SystemDataElement[] = systemDataElementsArr as SystemDataElement[];
    if (isEditorAccountLibraryProgram || programType?.toLowerCase() === ProgramType.Ads.toLocaleLowerCase()) {
        sysDEArray = sysDEArray.concat(adsSystemDataElementsArr as SystemDataElement[]);
    }
    if (isEditorAccountLibraryProgram || programType?.toLowerCase() === ProgramType.CRM.toLocaleLowerCase()) {
        sysDEArray = sysDEArray.concat(cxSystemDataElementsArr as unknown as SystemDataElement[]);
    }
    return sysDEArray;
};
