import { StudioMainCategoriesPaths } from "@sundaysky/smartvideo-hub-urls";

export type Permission = { name: string; message: string };
export type NoPermission = typeof NONE;
type RolesPermissions = Record<roles, Array<Permission["name"]>>;

/* !!!!! NEVER EVER CHANGE EXISTING ROLES (key or value) - they are used in dynamo in evil ways !!!!! */
export enum roles {
    // --- Account scope roles --- //
    admin = "admin",
    animator = "animator",
    builderEditor = "builder editor",
    builderOverview = "builder overview",
    builderViewer = "builder viewer",
    frameworkEditor = "framework editor",
    adsUser = "ads user",
    adsAdmin = "ads admin",
    adsSundayskyViewer = "ads SundaySky viewer",
    frameworkViewer = "framework viewer",
    reportingServer = "reporting server",
    builderReporting = "builder reporting",
    resolver = "resolver",
    bi = "bi",
    sharedFrameworkViewer = "shared framework viewer",
    productCreative = "product creative",
    authoringServer = "authoring server",
    platformEditor = "platform editor",
    platformViewer = "platform viewer",
    reportsByInteractionAdmin = "reports by interaction admin",
    shopifyServer = "shopify server",
    accountOwner = "account owner",
    performanceToolResultUpdate = "performance tool result updater",
    professionalServices = "professionalServices",
    reviewer = "reviewer",
    exportAccountData = "export account data",
    cxEtlRole = "cx etl role",

    // --- User scope roles --- //
    // Add during user manager upgrade (https://sundaysky.atlassian.net/browse/PPE-586)
    // These do not have specific dynamodb equivalent roles, but are deduced from other things
    allAccountsManager = "allAccountsManager",
    internal = "internal",
    external = "external",
    storyTemplatesEditor = "storyTemplatesEditor",
    promptTokenManager = "promptTokenManager",
    helpCenterEditor = "helpCenterEditor"
}
/* !!!!! NEVER EVER CHANGE EXISTING ROLES (key or value) - they are used in dynamo in evil ways !!!!! */

export const permissions = {
    getProjects: { name: "GET_PROJECTS", message: "You are not authorized to view projects" },
    getBuilderProjectsInfo: { name: "GET_BUILDER_PROJECTS_INFO", message: "You are not authorized to view projects" },
    editProjects: { name: "EDIT_PROJECTS", message: "You are not authorized to edit projects" },
    getProjectsAssets: { name: "GET_PROJECTS_ASSETS", message: "You are not authorized to view projects assets" },
    editProjectsAssets: { name: "EDIT_PROJECTS_ASSETS", message: "You are not authorized to edit projects assets" },
    dashboards: { name: "DASHBOARDS", message: "You are not authorized to view dashboards" },
    editUsers: { name: "EDIT_USERS", message: "You are not authorized to edit users" },
    getUsers: { name: "GET_USERS", message: "You are not authorized to view users" },
    editAccountUsers: { name: "EDIT_ACCOUNT_USERS", message: "You are not authorized to edit account users" },
    syncProjectAssets: { name: "SYNC_PROJECT_ASSETS", message: "You are not authorized to sync project assets" },
    editFeatureFlags: { name: "EDIT_FEATURE_FLAGS", message: "You are not authorized to edit feature flags" },
    canvas: { name: "CANVAS", message: "You are not authorized to edit Framework" },
    adsGetCampaignSettings: { name: "ADS_GET_CAMPAIGN_SETTINGS", message: "You are not authorized to view campaign settings" },
    adsEditCampaignSettings: { name: "ADS_EDIT_CAMPAIGN_SETTINGS", message: "You are not authorized to edit campaign settings" },
    getProjectSummaries: { name: "GET_PROJECTS_SUMMARIES", message: "You are not authorized to view projects summaries" },
    getFramework: { name: "GET_FRAMEWORK", message: "You are not authorized to view Framework" },
    createProgram: { name: "CREATE_PROGRAM", message: "You are not authorized to create programs" },
    deleteEntity: { name: "DELETE_ENTITY", message: "You are not authorized to delete" },
    updateEntity: { name: "UPDATE_ENTITY", message: "You are not authorized to update" },
    getSchedules: { name: "GET_SCHEDULES", message: "You are not authorized to get schedules" },
    editReports: { name: "EDIT_REPORTS", message: "You are not authorized to edit reports" },
    viewBuilderReports: { name: "VIEW_BUILDER_REPORTS", message: "You are not authorized to view builder reports" },
    viewEditorReports: { name: "VIEW_EDITOR_REPORTS", message: "You are not authorized to view editor reports" },
    viewReportsByInteraction: { name: "VIEW_REPORTS_BY_INTERACTION", message: "You are not authorized to view reports by interaction" },
    adminOperations: { name: "ADMIN_OPERATIONS", message: "You are not authorized to perform admin operations" },
    resolveQueries: { name: "RESOLVE_QUERIES", message: "You are not authorized to perform resolve queries" },
    biQueries: { name: "BI_QUERIES", message: "You are not authorized to perform BI queries" },
    raasRequests: { name: "RAAS_REQUESTS", message: "You are not authorized to perform RaaS requests" },
    getExperiments: { name: "GET_EXPERIMENTS", message: "You are not authorized to get experiments" },
    editExperiments: { name: "EDIT_EXPERIMENTS", message: "You are not authorized to edit experiments" },
    getInputs: { name: "GET_INPUTS", message: "You are not authorized to get inputs" },
    getCC: { name: "GET_CC", message: "You are not authorized to read configurable creatives" },
    editCC: { name: "EDIT_CC", message: "You are not authorized to edit configurable creatives" },
    editCCAnimatedWireframes: { name: "EDIT_CC_ANIMATED_WIREFRAMES", message: "You are not authorized to edit animated wireframes" },
    createEditorPrograms: { name: "CREATE_EDITOR_PROGRAMS", message: "You are not authorized to create editor programs" },
    getEditorPrograms: { name: "GET_EDITOR_PROGRAMS", message: "You are not authorized to view editor programs" },
    editEditorPrograms: { name: "EDIT_EDITOR_PROGRAMS", message: "You are not authorized to edit editor programs" },
    deleteEditorPrograms: { name: "DELETE_EDITOR_PROGRAMS", message: "You are not authorized to delete editor programs" },
    getBrandConfigurations: { name: "GET_BRAND_CONFIGURATIONS", message: "You are not authorized to view brand configurations" },
    editBrandConfigurations: { name: "EDIT_BRAND_CONFIGURATIONS", message: "You are not authorized to edit brand configurations" },
    createBrandConfigurations: { name: "CREATE_BRAND_CONFIGURATIONS", message: "You are not authorized to create brand configurations" },
    deleteBrandConfigurations: { name: "DELETE_BRAND_CONFIGURATIONS", message: "You are not authorized to delete brand configurations" },
    archiveEditorPrograms: { name: "ARCHIVE_EDITOR_PROGRAMS", message: "You are not authorized to archive editor programs" },
    uploadEditorAssetMedia: { name: "UPLOAD_EDITOR_ASSET_MEDIA", message: "You are not authorized to upload editor assets" },
    copyEditorProgram: { name: "COPY_EDITOR_PROGRAM", message: "You are not authorized to copy editor programs" },
    editViewerProfiles: { name: "EDIT_VIEWER_PROFILES", message: "You are not authorized to edit viewer profiles" },
    generateVideoLinks: { name: "GENERATE_VIDEO_LINKS", message: "You are not authorized to generate video links" },
    generateVideoLinksProd: { name: "GENERATE_VIDEO_LINKS_PROD", message: "You are not authorized to generate video links in prod" },
    editStoryTemplates: { name: "EDIT_STORY_TEMPLATES", message: "You are not authorized to edit story templates" },
    getStoryTemplatesData: { name: "GET_STORY_TEMPLATES_DATA", message: "You are not authorized to get story templates data" },
    editLandingPageBuilderApiGateway: { name: "EDIT_LANDING_PAGE_BUILDER_API_GATEWAY", message: "You are not authorized to edit landing page builder api gateway" },
    editStoryTemplatesGallery: { name: "EDIT_STORY_TEMPLATES_GALLERY", message: "You are not authorized to edit story templates gallery" },
    reviewEditorPrograms: { name: "REVIEW_EDITOR_PROGRAMS", message: "You are not authorized to review editor videos" },
    updatePerformanceToolResults: { name: "UPDATE_PERFORMANCE_TOOL_RESULTS", message: "You are not authorized to update performance tool results" },
    getProgramSettings: { name: "GET_PROGRAM_SETTINGS", message: "You are not authorized to get program settings" },
    editProgramSettings: { name: "EDIT_PROGRAM_SETTINGS", message: "You are not authorized to edit program settings" },
    copyDraftEditorProgramBetweenAccounts: { name: "COPY_DRAFT_EDITOR_PROGRAM_BETWEEN_ACCOUNTS", message: "You are not authorized to copy programs between accounts" },
    navigateToOldNavFromNewNav: { name: "NAVIGATE_TO_OLD_NAVIGATION_FROM_NEW_NAVIGATION", message: "You are not authorized to navigate to the older version" },
    editAllAccountUsers: { name: "EDIT_ALL_ACCOUNTS_USER", message: "You are not authorized to edit all account users" },
    getAllAccounts: { name: "GET_ALL_ACCOUNTS", message: "You are not authorized to view all accounts" },
    accountTierManagement: { name: "ACCOUNT_TIER_MANAGEMENT", message: "You are not authorized to manage account tiers" },
    createAuthThirdParty: { name: "CREAT_AUTH_THIRD_PARTY", message: "You are not authorized to create third part authentication" },
    // Assume role to s3
    assumeS3Role: { name: "ASSUME_S3_ROLE", message: "You are not authorized to assume role to s3" },
    editEditorCustomIntegrations: { name: "EDIT_EDITOR_CUSTOM_INTEGRATIONS", message: "You are not authorized to edit custom integrations" },
    removeAllAccountExternalUsers: { name: "REMOVE_ALL_ACCOUNT_EXTERNAL_USERS", message: "You are not authorized to remove all account external users" },
    createAdsEditorPrograms: { name: "CREATE_ADS_EDITOR_PROGRAMS", message: "You are not authorized to create ads programs" },
    updateUseRecordedVoice: { name: "UPDATE_USE_RECORDED_VOICE", message: "You are not authorized to update program use of recorded voice" },
    exportAccountData: { name: "EXPORT_ACCOUNT_DATA", message: "You are not authorized to export account data" },
    accountsSyncTrigger: { name: "ACCOUNTS_SYNC_TRIGGER", message: "You are not authorized to trigger accounts sync" },
    editUserControlledFeatures: { name: "EDIT_USER_CONTROLLED_FEATURES", message: "You are not authorized to edit user-controlled features" },
    managePromptTokens: { name: "MANAGE_PROMPT_TOKENS", message: "You are not authorized to manage prompt tokens" },
    editHelpCenter : { name: "EDIT_HELP_CENTER", message: "You are not authorized to edit help center" }
};
export type Permissions = typeof permissions;

export const allRoles: roles[] = Object.keys(roles).map((key) => roles[key]);
export const deprecatedRoles: roles[] = [
    roles.frameworkViewer,
    roles.frameworkEditor,
    roles.resolver,
    roles.reportsByInteractionAdmin,
    roles.sharedFrameworkViewer,
    roles.bi,
    roles.reportingServer,
    roles.authoringServer,
    roles.shopifyServer,
    roles.performanceToolResultUpdate
];

export const NONE: symbol = Symbol("NONE");

export const rolesPermissions: RolesPermissions = {
    [roles.admin]: [
        permissions.adminOperations.name,
        permissions.editFeatureFlags.name,
        permissions.editAllAccountUsers.name,
        // Needed for rest/users
        permissions.getUsers.name,
        // Needed for rest/projectSummaries
        permissions.editUsers.name,
        // Needed for rest/projectSummaries
        permissions.getProgramSettings.name,
        permissions.getProjects.name,
        permissions.editProgramSettings.name,
        permissions.exportAccountData.name,
        permissions.accountsSyncTrigger.name,
        permissions.editUserControlledFeatures.name
    ],
    [roles.builderEditor]: [
        permissions.createProgram.name,
        permissions.getBuilderProjectsInfo.name,
        permissions.getProjects.name,
        permissions.getProjectsAssets.name,
        permissions.editProjects.name,
        permissions.editProjectsAssets.name,
        permissions.syncProjectAssets.name,
        permissions.getProjectSummaries.name,
        permissions.deleteEntity.name,
        permissions.updateEntity.name,
        permissions.getExperiments.name,
        permissions.editExperiments.name,
        permissions.generateVideoLinks.name,
        permissions.generateVideoLinksProd.name,
        permissions.editReports.name,
        permissions.navigateToOldNavFromNewNav.name,
        permissions.createAuthThirdParty.name
    ],
    [roles.animator]: [
        permissions.getProjects.name,
        permissions.getProjectSummaries.name,
        permissions.getInputs.name,
        permissions.assumeS3Role.name
    ],
    [roles.builderOverview]: [
        permissions.getProjects.name,
        permissions.dashboards.name,
        permissions.getProjectSummaries.name,
        permissions.navigateToOldNavFromNewNav.name
    ],
    [roles.builderViewer]: [
        permissions.getBuilderProjectsInfo.name,
        permissions.getProjects.name,
        permissions.getProjectsAssets.name,
        permissions.syncProjectAssets.name,
        permissions.getProjectSummaries.name,
        permissions.navigateToOldNavFromNewNav.name
    ],
    [roles.frameworkEditor]: [
        permissions.createProgram.name,
        permissions.getProjects.name,
        permissions.getProjectsAssets.name,
        permissions.syncProjectAssets.name,
        permissions.canvas.name,
        permissions.getProjectSummaries.name,
        permissions.getFramework.name,
        permissions.deleteEntity.name,
        permissions.updateEntity.name,
        permissions.editReports.name,
        permissions.navigateToOldNavFromNewNav.name
    ],
    [roles.frameworkViewer]: [
        permissions.getProjects.name,
        permissions.getProjectsAssets.name,
        permissions.syncProjectAssets.name,
        permissions.getFramework.name,
        permissions.getProjectSummaries.name,
        permissions.navigateToOldNavFromNewNav.name
    ],
    [roles.adsUser]: [
        permissions.getBuilderProjectsInfo.name,
        permissions.getProjects.name,
        permissions.getProjectSummaries.name,
        permissions.getProjectsAssets.name,
        permissions.syncProjectAssets.name,
        permissions.adsGetCampaignSettings.name,
        permissions.navigateToOldNavFromNewNav.name
    ],
    [roles.adsAdmin]: [
        permissions.getBuilderProjectsInfo.name,
        permissions.getProjects.name,
        permissions.getProjectSummaries.name,
        permissions.getProjectsAssets.name,
        permissions.syncProjectAssets.name,
        permissions.adsGetCampaignSettings.name,
        permissions.adsEditCampaignSettings.name,
        permissions.navigateToOldNavFromNewNav.name
    ],
    [roles.adsSundayskyViewer]: [
        permissions.getBuilderProjectsInfo.name,
        permissions.getProjects.name,
        permissions.getProjectSummaries.name,
        permissions.getProjectsAssets.name,
        permissions.syncProjectAssets.name,
        permissions.adsGetCampaignSettings.name,
        permissions.navigateToOldNavFromNewNav.name
    ],
    [roles.builderReporting]: [
        permissions.getBuilderProjectsInfo.name,
        permissions.getProjects.name,
        permissions.getProjectsAssets.name,
        permissions.syncProjectAssets.name,
        permissions.getProjectSummaries.name,
        permissions.getFramework.name,
        permissions.dashboards.name,
        permissions.editReports.name,
        permissions.getExperiments.name,
        permissions.viewBuilderReports.name,
        permissions.viewEditorReports.name,
        permissions.navigateToOldNavFromNewNav.name
    ],
    [roles.reportingServer]: [
        permissions.getSchedules.name
    ],
    [roles.resolver]: [
        permissions.resolveQueries.name
    ],
    [roles.bi]: [
        permissions.biQueries.name
    ],
    [roles.sharedFrameworkViewer]: [
        permissions.getProjects.name,
        permissions.getProjectsAssets.name,
        permissions.syncProjectAssets.name,
        permissions.getFramework.name,
        permissions.getProjectSummaries.name
    ],
    [roles.productCreative]: [
        permissions.getProjects.name,
        permissions.getCC.name,
        permissions.editCC.name,
        permissions.editStoryTemplatesGallery.name,
        permissions.editReports.name,
        // todo: zoe - discuss with Daphna if we need to create a separate role for the copyDraftEditorProgramBetweenAccounts permission
        permissions.copyDraftEditorProgramBetweenAccounts.name
    ],
    [roles.authoringServer]: [
        permissions.getProjects.name,
        permissions.getCC.name,
        permissions.editCCAnimatedWireframes.name
    ],
    [roles.platformViewer]: [
        // region these permissions are required in order to show program summaries.
        permissions.getProjects.name,
        permissions.getProjectSummaries.name,
        // endregion

        permissions.getEditorPrograms.name,
        permissions.getCC.name,
        permissions.getBrandConfigurations.name,
        permissions.raasRequests.name,
        permissions.getStoryTemplatesData.name,
        permissions.reviewEditorPrograms.name
    ],
    [roles.platformEditor]: [
        // region these permissions are required in order to show program summaries.
        permissions.getProjects.name,
        permissions.getProjectSummaries.name,
        // endregion

        permissions.createEditorPrograms.name,
        permissions.getEditorPrograms.name,
        permissions.getCC.name,
        permissions.editEditorPrograms.name,
        permissions.deleteEditorPrograms.name,
        permissions.getBrandConfigurations.name,
        permissions.editBrandConfigurations.name,
        permissions.raasRequests.name,
        permissions.archiveEditorPrograms.name,
        permissions.uploadEditorAssetMedia.name,
        permissions.copyEditorProgram.name,
        permissions.editViewerProfiles.name,
        permissions.generateVideoLinks.name,
        permissions.generateVideoLinksProd.name,
        permissions.getStoryTemplatesData.name,
        permissions.editReports.name,
        permissions.viewEditorReports.name,
        permissions.reviewEditorPrograms.name,
        permissions.createAuthThirdParty.name
    ],
    [roles.professionalServices]: [
        // region these permissions are required in order to show program summaries.
        permissions.getProjects.name,
        permissions.getProjectSummaries.name,
        // endregion

        permissions.createEditorPrograms.name,
        permissions.getEditorPrograms.name,
        permissions.getCC.name,
        permissions.editEditorPrograms.name,
        permissions.deleteEditorPrograms.name,
        permissions.getBrandConfigurations.name,
        permissions.editBrandConfigurations.name,
        permissions.raasRequests.name,
        permissions.archiveEditorPrograms.name,
        permissions.uploadEditorAssetMedia.name,
        permissions.copyEditorProgram.name,
        permissions.editViewerProfiles.name,
        permissions.generateVideoLinks.name,
        permissions.generateVideoLinksProd.name,
        permissions.getStoryTemplatesData.name,
        permissions.editReports.name,
        permissions.viewEditorReports.name,
        permissions.editEditorCustomIntegrations.name,
        permissions.reviewEditorPrograms.name,
        permissions.editLandingPageBuilderApiGateway.name,
        permissions.createAdsEditorPrograms.name,
        permissions.createAuthThirdParty.name
    ],
    [roles.shopifyServer]: [
        permissions.getBuilderProjectsInfo.name,
        permissions.getProjects.name,
        permissions.getProjectsAssets.name,
        permissions.getProjectSummaries.name,
        permissions.getExperiments.name,
        permissions.canvas.name,
        permissions.getFramework.name,
        permissions.getEditorPrograms.name,
        permissions.getCC.name,
        permissions.getBrandConfigurations.name,
        permissions.raasRequests.name,
        permissions.dashboards.name,
        permissions.adsGetCampaignSettings.name,
        permissions.adsEditCampaignSettings.name,
        permissions.getStoryTemplatesData.name
    ],
    [roles.accountOwner]: [
        // region these permissions are required in order to show program summaries.
        permissions.getProjects.name,
        permissions.getProjectSummaries.name,
        // endregion

        permissions.getEditorPrograms.name,
        permissions.getCC.name,
        permissions.getBrandConfigurations.name,
        permissions.raasRequests.name,

        permissions.editAccountUsers.name,
        permissions.editUserControlledFeatures.name,
        permissions.getStoryTemplatesData.name,
        permissions.editReports.name,
        permissions.viewEditorReports.name,
        permissions.reviewEditorPrograms.name,
        permissions.createAuthThirdParty.name

    ],
    [roles.performanceToolResultUpdate]: [
        // This user (accessible via api-key only) is only allowed to update performance tool results
        permissions.updatePerformanceToolResults.name
    ],
    [roles.reportsByInteractionAdmin]: [
        permissions.editReports.name,
        permissions.viewReportsByInteraction.name
    ],
    [roles.allAccountsManager]: [
        permissions.editAccountUsers.name,
        permissions.removeAllAccountExternalUsers.name,
        permissions.accountTierManagement.name
    ],
    [roles.internal]: [
        permissions.getBuilderProjectsInfo.name,
        permissions.getProjects.name,
        permissions.getEditorPrograms.name,
        permissions.getCC.name,
        permissions.getAllAccounts.name,
        permissions.getBrandConfigurations.name,
        permissions.raasRequests.name,
        permissions.getProjectSummaries.name,
        permissions.navigateToOldNavFromNewNav.name,
        permissions.syncProjectAssets.name,
        permissions.dashboards.name,
        permissions.getFramework.name,
        permissions.updateUseRecordedVoice.name
    ],
    [roles.external]: [
    ],
    [roles.storyTemplatesEditor]: [
        permissions.editStoryTemplatesGallery.name,
        permissions.editStoryTemplates.name
    ],
    [roles.reviewer]: [
        permissions.reviewEditorPrograms.name
    ],
    [roles.exportAccountData]: [
        permissions.exportAccountData.name
    ],
    [roles.cxEtlRole]: [
        permissions.getProjects.name,
        permissions.getAllAccounts.name
    ],
    [roles.promptTokenManager]: [
        permissions.managePromptTokens.name
    ],
    [roles.helpCenterEditor]: [
        permissions.editHelpCenter.name
    ]
};

export const getNavigationItemsByUserRoles = (userRoles: roles[]): Array<StudioMainCategoriesPaths> => {
    let navigationItems = [];
    userRoles.forEach((userRole) => {
        navigationItems = navigationItems.concat(getNavigationItemsByUserRole(userRole));
    });
    navigationItems = Array.from(new Set(navigationItems));
    return navigationItems;
};

const getNavigationItemsByUserRole = (userRole: roles): Array<StudioMainCategoriesPaths> => {
    switch (userRole) {
        case roles.admin:
            return Object.values(StudioMainCategoriesPaths);
        case roles.internal:
            return Object.values(StudioMainCategoriesPaths);
        case roles.builderEditor:
            return [StudioMainCategoriesPaths.Builder, StudioMainCategoriesPaths.Creative, StudioMainCategoriesPaths.Settings];
        case roles.animator:
            return [];
        case roles.builderOverview:
            return [StudioMainCategoriesPaths.Overview];
        case roles.builderViewer:
            return [StudioMainCategoriesPaths.Builder, StudioMainCategoriesPaths.Creative, StudioMainCategoriesPaths.Settings];
        case roles.frameworkEditor:
            return [StudioMainCategoriesPaths.Framework];
        case roles.adsUser:
            return [StudioMainCategoriesPaths.Builder, StudioMainCategoriesPaths.Settings, StudioMainCategoriesPaths.CampaignSettings];
        case roles.adsSundayskyViewer:
            return [StudioMainCategoriesPaths.Builder, StudioMainCategoriesPaths.Settings, StudioMainCategoriesPaths.CampaignSettings];
        case roles.adsAdmin:
            return [StudioMainCategoriesPaths.Builder, StudioMainCategoriesPaths.Settings, StudioMainCategoriesPaths.CampaignSettings];
        case roles.frameworkViewer:
            return [StudioMainCategoriesPaths.Framework];
        case roles.sharedFrameworkViewer:
            return [StudioMainCategoriesPaths.Framework];
        case roles.builderReporting:
            return [
                StudioMainCategoriesPaths.Overview,
                StudioMainCategoriesPaths.Framework,
                StudioMainCategoriesPaths.Builder,
                StudioMainCategoriesPaths.Creative,
                StudioMainCategoriesPaths.Settings
            ];
        case roles.platformEditor:
        case roles.platformViewer:
        case roles.accountOwner:
            return [
                StudioMainCategoriesPaths.Overview,
                StudioMainCategoriesPaths.Editor2, // for redirecting to editor. without this users will get 404
                StudioMainCategoriesPaths.Editor
            ];
        default:
            return [];
    }
};

export const isPermission = (maybePermission: Permission | NoPermission): maybePermission is Permission =>
    !!maybePermission &&
    Object.keys(maybePermission).length === 2 &&
    Object.hasOwnProperty.call(maybePermission, "name") &&
    Object.hasOwnProperty.call(maybePermission, "message");

export const getPermission = (permissionName: string): Permission =>
    permissions[permissionName] || { name: "UNKNOWN", message: `Unknown permission ${permissionName}` };

export const isPermitted = (userRoles: string[], permission: Permission): boolean => {
    return Boolean(userRoles?.some((role) => rolesPermissions[role]?.includes(permission.name)));
};

export const isRole = (role: string): role is roles => !!Object.values(roles).find(value => value === role);


// Temporary (?) for translating dynamodb roles during transition
const dynamoDbRoles: Record<string, roles> = {
    "admin": roles.admin,
    "ads SundaySky viewer": roles.adsSundayskyViewer,
    "account owner": roles.accountOwner,
    "ads admin": roles.adsAdmin,
    "ads user": roles.adsUser,
    "animator": roles.animator,
    "bi": roles.bi,
    "authoring server": roles.authoringServer,
    "builder editor": roles.builderEditor,
    "builder overview": roles.builderOverview,
    "builder reporting": roles.builderReporting,
    "builder viewer": roles.builderViewer,
    "framework editor": roles.frameworkEditor,
    "framework viewer": roles.frameworkViewer,
    "performance tool result updater": roles.performanceToolResultUpdate,
    "platform editor": roles.platformEditor,
    "platform viewer": roles.platformViewer,
    "product creative": roles.productCreative,
    "reporting server": roles.reportingServer,
    "reports by interaction admin": roles.reportsByInteractionAdmin,
    "resolver": roles.resolver,
    "shared framework viewer": roles.sharedFrameworkViewer,
    "shopify server": roles.shopifyServer,
    "professionalServices": roles.professionalServices,
    "reviewer": roles.reviewer
};

const dynamoDbRolesRemoved = ["button reporting configurer", "creative toolkit user"];

export const ACCOUNT_LEVEL_ROLES: roles[] = [
    roles.animator,
    roles.builderEditor,
    roles.builderOverview,
    roles.builderViewer,
    roles.frameworkEditor,
    roles.frameworkViewer,
    roles.adsUser,
    roles.adsAdmin,
    roles.adsSundayskyViewer,
    roles.builderReporting,
    roles.sharedFrameworkViewer,
    roles.platformEditor,
    roles.platformViewer,
    roles.shopifyServer,
    roles.accountOwner,
    roles.storyTemplatesEditor,
    roles.professionalServices,
    roles.reviewer
];

export const USER_LEVEL_ROLES: roles[] = [
    roles.admin,
    roles.bi,
    roles.allAccountsManager,
    roles.internal,
    roles.external,
    roles.reportingServer,
    roles.reportsByInteractionAdmin,
    roles.performanceToolResultUpdate,
    roles.authoringServer,
    roles.productCreative,
    roles.resolver,
    roles.exportAccountData,
    roles.cxEtlRole,
    roles.promptTokenManager,
    roles.helpCenterEditor
];


/**
 * Translate a string which represents either an old (dynamodb) role, or a new role name/value
 * to a role enum.
 * @param role role string
 * @param validate if this is passed AND true, and there's no match for this role, an error will be thrown.
 * @returns matching role, or undefined if the input has no match (illegal input, dynamo was that was removed, etc.)
 *
 */
export function stringToRole(role: string, validate: boolean = false): roles | undefined {
    for (const k of Object.keys(roles)) {
        if (k === role || roles[k] === role) {
            return roles[k];
        }
    }

    if (dynamoDbRolesRemoved.includes(role)) {
        return undefined;
    }

    const matchingRole = dynamoDbRoles[role];
    if (matchingRole) {
        return matchingRole;
    }
    else if (validate) {
        throw new Error(`Cannot translate role input '${role}' to any existing role`);
    }
    else {
        return undefined;
    }

}
