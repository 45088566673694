import {
    buildAvatarAlignmentSetting,
    buildAvatarCropSetting,
    buildBreathingSetting,
    buildButtonSettings,
    buildColorSetting,
    buildDurationSetting,
    buildFontSizeSetting,
    buildHiddenSetting,
    buildMediaSettings,
    buildMediaSoundSetting,
    buildNarrationAlternativeVoiceSetting,
    buildOverrideLogoSetting,
    buildTextFitSetting,
    buildTextHorizontalAlignmentSetting,
    buildTextSettings,
    buildTextStyleSetting,
    buildTextVerticalAlignmentSetting,
    getDefaultButtonShowContentV7,
    getDefaultButtonShowContentV9,
    initiateButtonActionShow
} from "./editorUtils";
import type { VolumeSettings } from "./external/videoSpec";
import type {
    AdjustmentSetting,
    AlternativeVoiceSetting,
    AvatarCropSetting,
    AvatarPlaceholderContent,
    AvatarShowV1,
    BreathingSetting,
    ButtonActionShowV7,
    ButtonActionShowV9,
    ButtonActionType,
    ButtonPlaceholderContent,
    ButtonShow,
    ButtonShowContent,
    ButtonShowContentItem,
    ButtonShowContentV7,
    ButtonShowContentV9,
    ButtonShowV7,
    ButtonShowV8,
    ButtonShowV9,
    CaseType,
    ColorSetting,
    ContentsV3,
    ContentsWithV10Settings,
    ContentsWithV2Settings,
    ContentsWithV3Settings,
    ContentsWithV4Settings,
    ContentsWithV5Settings,
    ContentsWithV6Settings,
    ContentsWithV7Settings,
    ContentsWithV8Settings,
    ContentsWithV9Settings,
    Coordinate,
    DataElementDescriptor,
    DurationSetting,
    DynamicNarrationShow, DynamicRichTextAtom,
    DynamicRichTextAtoms,
    DynamicTextAtom,
    DynamicTextAtomContent,
    DynamicTextAtoms,
    DynamicTextShow,
    FontSizeSetting,
    FontSizeType,
    HiddenSetting,
    MediaAlignmentSetting,
    MediaPlaceholderContent,
    MediaSettings,
    MediaSettingsCollectionV3,
    MediaSettingsCollectionV4,
    MediaSettingsCollectionV5,
    MediaShow,
    MediaShowV6,
    MediaShowV8,
    NarrationNoRulesV10,
    NarrationPlaceholderContent,
    NarrationRulesV10,
    NarrationShow,
    OverrideLogoSetting,
    PlaceholderContent,
    PlaceholderContentVersion1,
    PlaceholderContentVersion10,
    PlaceholderContentVersion2,
    PlaceholderContentVersion3,
    PlaceholderContentVersion4,
    PlaceholderContentVersion5,
    PlaceholderContentVersion5Media,
    PlaceholderContentVersion6,
    PlaceholderContentVersion6Button,
    PlaceholderContentVersion7,
    PlaceholderContentVersion7Media,
    PlaceholderContentVersion8,
    PlaceholderContentVersion8Button,
    PlaceholderContentVersion8Narration,
    PlaceholderContentVersion8Text,
    PlaceholderContentVersion9,
    PlaceholderContentVersion9Narration,
    PlaceholderContentVersions,
    PlaceholderSettings,
    PlaceholderSettingsVersion1,
    PlaceholderSettingsVersion10,
    PlaceholderSettingsVersion11,
    PlaceholderSettingsVersion12,
    PlaceholderSettingsVersion13,
    PlaceholderSettingsVersion14,
    PlaceholderSettingsVersion1Media,
    PlaceholderSettingsVersion2,
    PlaceholderSettingsVersion3,
    PlaceholderSettingsVersion4,
    PlaceholderSettingsVersion5,
    PlaceholderSettingsVersion6,
    PlaceholderSettingsVersion7,
    PlaceholderSettingsVersion8,
    PlaceholderSettingsVersion9,
    PlaceholderSettingsVersions,
    PlayToEndSetting,
    RichTextAtom,
    SettingAtoms,
    SettingKey,
    SettingsCollectionsV3,
    SoundSetting,
    StaticButtonShowV9,
    StaticMediaShow,
    StaticMediaShowV8,
    StaticRichTextAtoms,
    StaticTextAtoms,
    StaticTextShow,
    TextFit,
    TextFitSetting,
    TextHorizontalAlignmentSetting,
    TextPlaceholderContent,
    TextSettings,
    TextSettingsCollectionV1,
    TextSettingsCollectionV2,
    TextSettingsCollectionV3,
    TextSettingsCollectionV4,
    TextStyleSetting,
    TextVerticalAlignmentSetting
} from "./types/editorPlaceholder";
import {
    AvatarCropPreset,
    AvatarSettingsKey,
    BreathingType,
    ButtonShowContentItemElement,
    CommonPlaceholderType,
    ContentType,
    DEFAULT_CONTENT,
    DurationType,
    GlobalSettingKey,
    MediaSettingKey,
    NarrationSettingsKey,
    PLACEHOLDER_CONTENT_VERSION,
    PLACEHOLDER_SETTINGS_VERSION,
    PlaceholderIntentName,
    PlaceholderShowType,
    PositionUnit,
    RichTextStyleSetting,
    TextSettingKey
} from "./types/editorPlaceholder";

import type { ObjectPosition, TextHorizontalAlign, TextVerticalAlign } from "./types/vlxTypes";
import { ObjectFit } from "./types/vlxTypes";
import { convertToObjectPosition, getTextStyleHorizontalAlign, getTextStyleVerticalAlign, mapAdjustmentToFit } from "./vlxUtils";

// region placeholder content
const updatePlaceholderContentV1toV2 = (placeholderContent: PlaceholderContentVersion1): PlaceholderContentVersion2 => {
    return {
        version: 2 as 2,
        useRules: false,
        noRules: {
            show: [placeholderContent.staticValue],
            staticFallback: ""
        },
        rules: {
            by: {
                localId: null
            },
            cases: [],
            staticFallback: ""
        }
    };
};

const updatePlaceholderContentV2toV3 = (placeholderContent: PlaceholderContentVersion2): PlaceholderContentVersion3 => {
    return {
        version: 3 as 3,
        useRules: false,
        noRules: {
            show: Array.isArray(placeholderContent.noRules.show) ? placeholderContent.noRules.show : [placeholderContent.noRules.show]
        },
        rules: {
            by: {
                localId: null
            },
            cases: []
        },
        staticFallback: placeholderContent.noRules.staticFallback
    };
};

const updatePlaceholderContentV3toV4 = (placeholderContent: PlaceholderContentVersion3, phType: CommonPlaceholderType): PlaceholderContentVersion4 => {

    let showValue: DynamicTextShow | MediaShow | DynamicNarrationShow;

    if (phType === CommonPlaceholderType.MEDIA) {
        showValue = {
            showType: PlaceholderShowType.Static,
            static: Array.isArray(placeholderContent.noRules.show) ?
                placeholderContent.noRules.show[0] as StaticMediaShow
                : placeholderContent.noRules.show as StaticMediaShow,
            dynamic: null
        };
    }
    else {
        showValue = placeholderContent.noRules.show as DynamicTextAtomContent[];
    }

    return {
        version: 4 as 4,
        useRules: placeholderContent.useRules,
        //@ts-ignore
        noRules: {
            show: showValue
        },
        rules: {
            ...placeholderContent.rules
        },
        staticFallback: placeholderContent.staticFallback
    };
};

const updatePlaceholderContentV4toV5 = (placeholderContent: PlaceholderContentVersion4, phType: CommonPlaceholderType): PlaceholderContentVersion5 => {

    if (placeholderContent.version !== 4) {
        throw new Error(`Cannot upgrade placeholder from version 4, received ${phType} placeholder input with version ${placeholderContent.version}: ${JSON.stringify(placeholderContent)}`);
    }

    switch (phType) {
        case CommonPlaceholderType.TEXT:
        case CommonPlaceholderType.MEDIA:
        case CommonPlaceholderType.NARRATION:
            return { ...placeholderContent, version: 5, placeholderType: phType } as PlaceholderContentVersion5;
        case CommonPlaceholderType.BUTTON: {
            // Button didn't exist in v4, but that didn't stop people from creating some placeholders in the editor, so we got stuck
            //  with db records. When dealing with those we'll return an empty button.
            const defaultShow: ButtonShow = {
                useAction: "OpenUrl",
                actionShow: initiateButtonActionShow<ButtonShowContentV7, ButtonActionShowV7>(getDefaultButtonShowContentV7(""))
            };
            return {
                placeholderType: CommonPlaceholderType.BUTTON,
                version: 5,
                staticFallback: defaultShow,
                useRules: false,
                rules: null,
                noRules: {
                    show: defaultShow
                }
            };
        }
        default:
            throw new Error(`Cannot update placeholder content type ${phType} from v4 to v5. Type did not exist in v4`);
    }
};

function mediaShowToV6(mediaShow: MediaShow): MediaShowV6 {
    if (mediaShow === undefined) {
        return undefined;
    }

    return {
        showType: mediaShow.showType,
        dynamic: mediaShow.dynamic,
        static: mediaShow.static !== undefined && {
            location: mediaShow.static
        }
    };
}

const updatePlaceholderContentV5toV6 = (placeholderContent: PlaceholderContentVersion5, phType: CommonPlaceholderType): PlaceholderContentVersion6 => {

    if (placeholderContent.version !== 5) {
        throw new Error(`Cannot upgrade placeholder from version 5, received ${phType} placeholder input with version ${placeholderContent.version}: ${JSON.stringify(placeholderContent)}`);
    }

    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.TEXT:
        case CommonPlaceholderType.BUTTON:
            return { ...placeholderContent, version: 6 } as PlaceholderContentVersion6;
        case CommonPlaceholderType.MEDIA: {
            const origMedia: PlaceholderContentVersion5Media = placeholderContent as PlaceholderContentVersion5Media;
            // Convert no rules block to V6 (update the "show" block)
            const newNoRules = origMedia.noRules && {
                show: mediaShowToV6(origMedia.noRules.show)
            };
            // Convert rules block to V6 (update all the inner "show" blocks for each case)
            // https://www.youtube.com/watch?v=k2qgadSvNyU
            const newRules = origMedia.rules && {
                by: origMedia.rules.by,
                cases: origMedia.rules.cases?.map(c => {
                    return {
                        valueIds: c.valueIds,
                        show: mediaShowToV6(c.show)
                    };
                })
            };

            // Build media v6
            return {
                version: 6,
                placeholderType: phType,
                useRules: origMedia.useRules,
                noRules: newNoRules,
                rules: newRules,
                staticFallback: origMedia.staticFallback !== undefined && {
                    location: origMedia.staticFallback
                }
            };
        }
        default:
            throw new Error(`Cannot update placeholder content type ${phType} from v5 to v6. Type did not exist in v5`);
    }
};

const buttonShowToV7 = (buttonShow: ButtonShow): ButtonShowV7 => {
    if (!buttonShow?.actionShow) {
        return null;
    }

    const newActionShow = {} as ButtonActionShowV7;

    Object.keys(buttonShow.actionShow).forEach((actionShowType: ButtonActionType) => {
        const oldActionShow = buttonShow.actionShow[actionShowType] as ButtonShowContent;
        const buttonTextContent = oldActionShow.find((actionShowElement: ButtonShowContentItem) => actionShowElement.name === "text")?.content;
        const buttonLabelContent = oldActionShow.find((actionShowElement: ButtonShowContentItem) => actionShowElement.name === "label")?.content;
        let buttonReportContent = oldActionShow.find((actionShowElement: ButtonShowContentItem) => actionShowElement.name === "report")?.content;
        const isReportContentSameAsButtonText = JSON.stringify(buttonTextContent) === JSON.stringify(buttonReportContent);
        const isLabelContentSameAsButtonText = JSON.stringify(buttonTextContent) === JSON.stringify(buttonLabelContent);

        newActionShow[actionShowType] = oldActionShow.map((actionShowElement: ButtonShowContentItem) => {
            switch (actionShowElement.name) {
                case "text":
                    return { ...actionShowElement, isContentSameAsButtonText: true };
                case "url":
                    return { ...actionShowElement, isContentSameAsButtonText: false };
                case "label":
                    return {
                        ...actionShowElement,
                        content: isLabelContentSameAsButtonText ? [""] : actionShowElement.content,
                        isContentSameAsButtonText: isLabelContentSameAsButtonText
                    };
                case "report":
                    return {
                        ...actionShowElement,
                        content: isReportContentSameAsButtonText ? [""] : actionShowElement.content,
                        isContentSameAsButtonText: isReportContentSameAsButtonText
                    };
                default:
                    throw new Error(`Cannot update button placeholder action show element ${actionShowElement.name}: Migration from V6 to V7 supports only text/url/label/report show elements`);
            }
        });
    });

    return {
        ...buttonShow,
        actionShow: newActionShow
    };
};

const updatePlaceholderContentV6toV7 = (placeholderContent: PlaceholderContentVersion6, phType: CommonPlaceholderType): PlaceholderContentVersion7 => {

    if (placeholderContent.version !== 6) {
        throw new Error(`Cannot upgrade placeholder from version 6, received ${phType} placeholder input with version ${placeholderContent.version}: ${JSON.stringify(placeholderContent)}`);
    }

    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.TEXT:
        case CommonPlaceholderType.MEDIA:
            return { ...placeholderContent, version: 7 } as PlaceholderContentVersion7;
        case CommonPlaceholderType.BUTTON: {
            const origButton: PlaceholderContentVersion6Button = placeholderContent as PlaceholderContentVersion6Button;

            const newNoRules = origButton.noRules && {
                show: buttonShowToV7(origButton.noRules.show)
            };

            const newRules = origButton.rules && {
                by: origButton.rules.by,
                cases: origButton.rules.cases?.map(c => {
                    return {
                        valueIds: c.valueIds,
                        show: buttonShowToV7(c.show)
                    };
                })
            };

            // Build button v7
            return {
                version: 7,
                placeholderType: phType,
                useRules: origButton.useRules,
                noRules: newNoRules,
                rules: newRules,
                staticFallback: origButton.staticFallback !== undefined && buttonShowToV7(origButton.staticFallback)
            };
        }
        default:
            throw new Error(`Cannot update placeholder content type ${phType} from v6 to v7. Type did not exist in v6`);
    }
};

function mediaShowToV8(mediaShow: MediaShowV6): MediaShowV8 {
    if (mediaShow === undefined) {
        return undefined;
    }

    if (mediaShow?.static?.location && !mediaShow.static.mediaId) {
        throw new Error(`Cannot convert static media to v8, it has a location (${mediaShow.static.location}, but no mediaId)`);
    }

    return {
        showType: mediaShow.showType,
        dynamic: mediaShow.dynamic,
        static: mediaShow.static ? { mediaId: mediaShow.static.mediaId } : null
    };
}

const updatePlaceholderContentV7toV8 = (placeholderContent: PlaceholderContentVersion7, phType: CommonPlaceholderType): PlaceholderContentVersion8 => {

    if (placeholderContent.version !== 7) {
        throw new Error(`Cannot upgrade placeholder from version 7, received ${phType} placeholder input with version ${placeholderContent.version}: ${JSON.stringify(placeholderContent)}`);
    }

    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.TEXT:
        case CommonPlaceholderType.BUTTON:
            return { ...placeholderContent, version: 8 } as PlaceholderContentVersion8;
        case CommonPlaceholderType.MEDIA: {
            const origMedia: PlaceholderContentVersion7Media = placeholderContent as PlaceholderContentVersion7Media;
            // Convert no rules block to V8 (update the "show" block's static part)
            const newNoRules = origMedia.noRules && {
                show: mediaShowToV8(origMedia.noRules.show)
            };
            // Convert rules block to V8 (update all the inner "show" blocks for each case)
            // https://www.youtube.com/watch?v=k2qgadSvNyU
            const newRules = origMedia.rules && {
                by: origMedia.rules.by,
                cases: origMedia.rules.cases?.map(c => {
                    return {
                        valueIds: c.valueIds,
                        show: mediaShowToV8(c.show)
                    };
                })
            };

            if (origMedia.staticFallback?.location && !origMedia.staticFallback.mediaId) {
                throw new Error(`Cannot convert placeholder to v8, its static fallback has a location (${origMedia.staticFallback.location}, but no mediaId`);
            }

            const staticFallback: StaticMediaShowV8 = origMedia.staticFallback && {
                mediaId: origMedia.staticFallback.mediaId
            } ;

            // Build media v8
            return {
                version: 8,
                placeholderType: phType,
                useRules: origMedia.useRules,
                noRules: newNoRules,
                rules: newRules,
                staticFallback
            };
        }
        default:
            throw new Error(`Cannot update placeholder content type ${phType} from v5 to v6. Type did not exist in v5`);
    }
};

function dynamicTextShowToDynamicRichTextAtoms(textShow: DynamicTextShow): DynamicRichTextAtoms {
    return textShow?.map((textAtom: DynamicTextAtomContent) => ({
        content: textAtom,
        style: {
            bold: RichTextStyleSetting.UNSET,
            italic: RichTextStyleSetting.UNSET
        }
    })) || [];
}

function staticTextShowToStaticRichTextAtoms(textShow: StaticTextShow): StaticRichTextAtoms {
    return [{
        content: textShow || "",
        style: {
            bold: RichTextStyleSetting.UNSET,
            italic: RichTextStyleSetting.UNSET
        }
    }];
}

function dynamicTextShowToDynamicTextAtoms(textShow: DynamicTextShow): DynamicTextAtoms {
    return textShow?.map((textAtom: DynamicTextAtomContent): DynamicTextAtom => ({
        content: textAtom
    })) || [];
}

function staticTextShowToStaticTextAtoms(textShow: StaticTextShow): StaticTextAtoms {
    return [{
        content: textShow || ""
    }];
}

function getButtonShowContentItemElementEnum(name: string): ButtonShowContentItemElement {
    switch (name) {
        case "text":
            return ButtonShowContentItemElement.Text;
        case "url":
            return ButtonShowContentItemElement.URL;
        case "report":
            return ButtonShowContentItemElement.Report;
        case "label":
            return null;
        default: {
            throw new Error(`Cannot upgrade placeholder from version 8, received button with ${name} property which can't be mapped`);
        }
    }
}

function dynamicButtonShowToV9(buttonShow: ButtonShowV8): ButtonShowV9<DynamicTextAtoms, DynamicRichTextAtoms> {
    return {
        ...buttonShow,
        actionShow: {
            ...Object.entries(buttonShow.actionShow).reduce((acc, [key, value]) => {
                const buttonShowContent = value.reduce((acc, e): ButtonShowContentV9<DynamicTextAtoms, DynamicRichTextAtoms> => {
                    const name = getButtonShowContentItemElementEnum(e.name);
                    if (name) {
                        if (!Array.isArray(e.content)) {
                            throw new Error(`Cannot upgrade placeholder from version 8, received button with invalid ${name} content: ${JSON.stringify(e.content)}`);
                        }
                        if (name === ButtonShowContentItemElement.Text) {
                            acc[ButtonShowContentItemElement.Text] = {
                                isContentSameAsButtonText: e.isContentSameAsButtonText,
                                content: dynamicTextShowToDynamicRichTextAtoms(e.content as DynamicTextShow)
                            };
                        }
                        else if (name === ButtonShowContentItemElement.Report) {
                            if (e.content.length !== 1) {
                                throw new Error(`Cannot upgrade placeholder from version 8, received button with invalid report content: ${JSON.stringify(e.content)}`);
                            }
                            acc[ButtonShowContentItemElement.Report] = {
                                isContentSameAsButtonText: e.isContentSameAsButtonText,
                                content: staticTextShowToStaticTextAtoms(e.content[0] as string)
                            };
                        }
                        else {
                            acc[name] = {
                                isContentSameAsButtonText: e.isContentSameAsButtonText,
                                content: dynamicTextShowToDynamicTextAtoms(e.content as DynamicTextShow)
                            };
                        }
                    }
                    return acc;
                }, {} as ButtonShowContentV9<DynamicTextAtoms, DynamicRichTextAtoms>);
                acc[key] = { ...getDefaultButtonShowContentV9<DynamicTextAtoms, DynamicRichTextAtoms>(""), ...buttonShowContent };
                return acc;
            }, {} as ButtonActionShowV9<DynamicTextAtoms, DynamicRichTextAtoms>)
        }
    };
}

function staticButtonShowToV9(buttonShow: ButtonShowV8): StaticButtonShowV9 {
    if (!buttonShow?.actionShow) {
        return {
            useAction: "OpenUrl",
            actionShow: initiateButtonActionShow<
                ButtonShowContentV9<StaticTextAtoms, StaticRichTextAtoms>,
                ButtonActionShowV9<StaticTextAtoms, StaticRichTextAtoms>
            >(getDefaultButtonShowContentV9<StaticTextAtoms, StaticRichTextAtoms>(""))
        };
    }
    return {
        ...buttonShow,
        actionShow: {
            ...Object.entries(buttonShow.actionShow).reduce((acc, [key, value]) => {
                acc[key] = value.reduce((acc, e): ButtonShowContentV9<StaticTextAtoms, StaticRichTextAtoms> => {
                    const name = getButtonShowContentItemElementEnum(e.name);
                    if (name) {
                        if (!Array.isArray(e.content) || e.content.length !== 1) {
                            throw new Error(`Cannot upgrade placeholder from version 8, received button with invalid ${name} content: ${JSON.stringify(e.content)}`);
                        }
                        if (name === ButtonShowContentItemElement.Text) {
                            acc[ButtonShowContentItemElement.Text] = {
                                isContentSameAsButtonText: e.isContentSameAsButtonText,
                                content: staticTextShowToStaticRichTextAtoms(e.content[0] as string)
                            };
                        }
                        else {
                            acc[name] = {
                                isContentSameAsButtonText: e.isContentSameAsButtonText,
                                content: staticTextShowToStaticTextAtoms(e.content[0] as string)
                            };
                        }
                    }
                    return acc;
                }, {} as ButtonShowContentV9<StaticTextAtoms, StaticRichTextAtoms>);
                return acc;
            }, {} as ButtonActionShowV9<StaticTextAtoms, StaticRichTextAtoms>)
        }
    };
}

export const updatePlaceholderContentV8toV9 = (placeholderContent: PlaceholderContentVersion8, phType: CommonPlaceholderType): PlaceholderContentVersion9 => {

    if (placeholderContent.version !== 8) {
        throw new Error(`Cannot upgrade placeholder from version 8, received ${phType} placeholder input with version ${placeholderContent.version}: ${JSON.stringify(placeholderContent)}`);
    }

    switch (phType) {
        case CommonPlaceholderType.MEDIA:
            return { ...placeholderContent, version: 9 } as PlaceholderContentVersion9;
        case CommonPlaceholderType.TEXT: {
            const origTextContent = placeholderContent as PlaceholderContentVersion8Text;
            // Convert no rules block to V9 (update the "show" block)
            const newNoRules = origTextContent.noRules && {
                show: dynamicTextShowToDynamicRichTextAtoms(origTextContent.noRules.show)
            };
            // Convert rules block to V9 (update all the inner "show" blocks for each case)
            // https://www.youtube.com/watch?v=k2qgadSvNyU
            const newRules = origTextContent.rules && {
                by: origTextContent.rules.by,
                cases: origTextContent.rules.cases?.map(c => {
                    return {
                        valueIds: c.valueIds,
                        show: dynamicTextShowToDynamicRichTextAtoms(c.show)
                    };
                })
            };

            const staticFallback = staticTextShowToStaticRichTextAtoms(origTextContent.staticFallback);

            // Build text v9
            return {
                version: 9,
                placeholderType: phType,
                useRules: origTextContent.useRules,
                noRules: newNoRules,
                rules: newRules,
                staticFallback
            };
        }
        case CommonPlaceholderType.NARRATION: {
            const origTextContent = placeholderContent as PlaceholderContentVersion8Narration;
            // Convert no rules block to V9 (update the "show" block)
            const newNoRules = origTextContent.noRules && {
                show: dynamicTextShowToDynamicTextAtoms(origTextContent.noRules.show)
            };
            // Convert rules block to V9 (update all the inner "show" blocks for each case)
            // https://www.youtube.com/watch?v=k2qgadSvNyU
            const newRules = origTextContent.rules && {
                by: origTextContent.rules.by,
                cases: origTextContent.rules.cases?.map(c => {
                    return {
                        valueIds: c.valueIds,
                        show: dynamicTextShowToDynamicTextAtoms(c.show)
                    };
                })
            };

            const staticFallback = staticTextShowToStaticTextAtoms(origTextContent.staticFallback);

            // Build narration v9
            return {
                version: 9,
                placeholderType: phType,
                useRules: origTextContent.useRules,
                noRules: newNoRules,
                rules: newRules,
                staticFallback
            };
        }
        case CommonPlaceholderType.BUTTON: {
            const origButtonContent = placeholderContent as PlaceholderContentVersion8Button;
            // Convert no rules block to V9 (update the "show" block)
            const newNoRules = origButtonContent.noRules && {
                show: dynamicButtonShowToV9(origButtonContent.noRules.show)
            };
            // Convert rules block to V9 (update all the inner "show" blocks for each case)
            // https://www.youtube.com/watch?v=k2qgadSvNyU
            const newRules = origButtonContent.rules && {
                by: origButtonContent.rules.by,
                cases: origButtonContent.rules.cases?.map(c => {
                    return {
                        valueIds: c.valueIds,
                        show: dynamicButtonShowToV9(c.show)
                    };
                })
            };

            const staticFallback = staticButtonShowToV9(origButtonContent.staticFallback);

            // Build button v9
            return {
                version: 9,
                placeholderType: phType,
                useRules: origButtonContent.useRules,
                noRules: newNoRules,
                rules: newRules,
                staticFallback
            };
        }
        default:
            throw new Error(`Cannot update placeholder content type ${phType} from v5 to v6. Type did not exist in v5`);
    }
};

export const updatePlaceholderContentV9toV10 = (placeholderContent: PlaceholderContentVersion9, phType: CommonPlaceholderType): PlaceholderContentVersion10 => {
    if (placeholderContent.version !== 9) {
        throw new Error(`Cannot upgrade placeholder from version 9, received ${phType} placeholder input with version ${placeholderContent.version}: ${JSON.stringify(placeholderContent)}`);
    }

    switch (phType) {
        case CommonPlaceholderType.MEDIA:
        case CommonPlaceholderType.TEXT:
        case CommonPlaceholderType.AVATAR:
        case CommonPlaceholderType.BUTTON:
            return { ...placeholderContent, version: 10 } as PlaceholderContentVersion10;
        case CommonPlaceholderType.NARRATION: {
            const origTextContent = placeholderContent as PlaceholderContentVersion9Narration;
            const newNoRules: NarrationNoRulesV10 = {
                show: {
                    text: origTextContent.noRules.show,
                    speed: 1
                }
            };
            const newRules: NarrationRulesV10 = {
                by: origTextContent.rules.by,
                cases: origTextContent.rules.cases.map(ruleCase => ({
                    valueIds: ruleCase.valueIds,
                    show: {
                        text: ruleCase.show,
                        speed: 1
                    }
                }))
            };
            const newStaticFallback: NarrationShow<StaticTextAtoms> = {
                text: origTextContent.staticFallback,
                speed: 1
            };


            return {
                version: 10,
                placeholderType: phType,
                useRules: origTextContent.useRules,
                noRules: newNoRules,
                rules: newRules,
                staticFallback: newStaticFallback
            };
        }
        default:
            throw new Error(`Cannot update placeholder content type ${phType} from v9 to v10. Type did not exist in v9`);
    }

};

// endregion

// region placeholder settings
type UpdatePlaceholderSettingsOneVersionUp = (placeholderContent: PlaceholderSettingsVersions, phType?: CommonPlaceholderType, phIntentType?: PlaceholderIntentName) => PlaceholderSettingsVersions;

type UpdatePlaceholderContentOneVersionUp = (placeholderContent: PlaceholderContentVersions, phType?: CommonPlaceholderType) => PlaceholderContentVersions;

function updateOneVersionUpIfNeeded(placeholderContent: PlaceholderContentVersions, phType: CommonPlaceholderType,
    updater: UpdatePlaceholderContentOneVersionUp, version: number) {
    if (placeholderContent.version === version) {
        return updater(placeholderContent, phType);
    }
    return placeholderContent;
}

function updateOneVersionSettingsUpIfNeeded(placeholderSettings: PlaceholderSettingsVersions, phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName, updater: UpdatePlaceholderSettingsOneVersionUp, version: number) {
    if (placeholderSettings.version === version) {
        return updater(placeholderSettings, phType, phIntentType);
    }
    return placeholderSettings;
}

const VERSION_UPDATE_MANAGERS: Array<{updater: UpdatePlaceholderContentOneVersionUp, version: number}> = [
    { version: 1, updater: updatePlaceholderContentV1toV2 },
    { version: 2, updater: updatePlaceholderContentV2toV3 },
    { version: 3, updater: updatePlaceholderContentV3toV4 },
    { version: 4, updater: updatePlaceholderContentV4toV5 },
    { version: 5, updater: updatePlaceholderContentV5toV6 },
    { version: 6, updater: updatePlaceholderContentV6toV7 },
    { version: 7, updater: updatePlaceholderContentV7toV8 },
    { version: 8, updater: updatePlaceholderContentV8toV9 },
    { version: 9, updater: updatePlaceholderContentV9toV10 },
    { version: 10, updater: (content: PlaceholderContentVersions) => content }
];


const updatePlaceholderSettingV1toV2IfNeeded = (placeholderSettings: PlaceholderSettingsVersion1, phType: CommonPlaceholderType): PlaceholderSettingsVersion2 => {
    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
            return {
                version: 2,
                contents: {}
            };
        case CommonPlaceholderType.MEDIA:
            return {
                version: 2,
                contents: {
                    [DEFAULT_CONTENT]: {
                        type: ContentType.Media,
                        settings: {
                            [GlobalSettingKey.IsHidden]: buildHiddenSetting(),
                            [MediaSettingKey.Adjustment]: {
                                useValue: true,
                                value: { mediaAdjustment: (placeholderSettings as PlaceholderSettingsVersion1Media).mediaAdjustment }
                            },
                            [MediaSettingKey.Alignment]: {
                                useValue: (placeholderSettings as PlaceholderSettingsVersion1Media).overrideDefaultAlignment,
                                value: { mediaAlignment: (placeholderSettings as PlaceholderSettingsVersion1Media).mediaAlignment }
                            },
                            [MediaSettingKey.Color]: buildColorSetting()
                        }
                    }
                }
            };
        case CommonPlaceholderType.TEXT: {
            return {
                version: 2,
                contents: {
                    [DEFAULT_CONTENT]: {
                        type: ContentType.Text,
                        settings: {
                            [TextSettingKey.Color]: buildColorSetting(),
                            [TextSettingKey.FontSize]: buildFontSizeSetting()
                        }
                    }
                }
            };

        }
        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v1 to v2.`);
    }
};

export const getEmptySettings = (): PlaceholderSettings => {
    return {
        version: PLACEHOLDER_SETTINGS_VERSION,
        contents: {}
    };
};

export const getDefaultBreathingValue = (intent: PlaceholderIntentName): BreathingSetting => {
    return [PlaceholderIntentName.Logo, PlaceholderIntentName.Icon].includes(intent)
        ? buildBreathingSetting({ breathing: BreathingType.None })
        : buildBreathingSetting(null);
};


export const getDefaultPlayToEndValue = (intent: PlaceholderIntentName): PlayToEndSetting => {
    return {
        useValue: false,
        value: null
    };
};

export const getDefaultDurationValue = (): DurationSetting => {
    return buildDurationSetting(false, DurationType.TruncateContentAtSceneEnd);
};

export const getDefaultSoundValue = (): SoundSetting => {
    return buildMediaSoundSetting();
};

export const getDefaultOverrideLogoValue = (): OverrideLogoSetting => {
    return buildOverrideLogoSetting();
};

export const getDefaultBackgroundColorValue = (): ColorSetting => {
    return buildColorSetting();
};

const updatePlaceholderSettingV2toV3IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion2,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion3 => {
    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
            return {
                version: 3,
                contents: {}
            };
        case CommonPlaceholderType.TEXT:
            return {
                version: 3,
                contents: {
                    [DEFAULT_CONTENT]: placeholderSettings.contents[DEFAULT_CONTENT] as SettingsCollectionsV3
                }
            };
        case CommonPlaceholderType.MEDIA: {
            const upgradedContents: ContentsV3 = placeholderMediaSettingsContentsV2toV3(placeholderSettings.contents, phIntentType);
            return {
                version: 3,
                contents: upgradedContents
            };
        }
        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v2 to v3.`);
    }
};

const updatePlaceholderSettingV3toV4IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion3,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion4 => {
    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
        case CommonPlaceholderType.TEXT:
            return {
                ...placeholderSettings,
                version: 4
            };
        case CommonPlaceholderType.MEDIA: {
            const upgradedContents: ContentsWithV4Settings = placeholderMediaSettingsContentsV3toV4(placeholderSettings.contents[DEFAULT_CONTENT].settings);
            return {
                version: 4,
                contents: upgradedContents
            };
        }
        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v3 to v4.`);
    }
};

const updatePlaceholderSettingV4toV5IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion4,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion5 => {
    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
        case CommonPlaceholderType.MEDIA:
            return {
                ...placeholderSettings,
                version: 5
            };
        case CommonPlaceholderType.TEXT: {
            const upgradedContents: ContentsWithV5Settings = placeholderTextSettingsContentsV1toV2(placeholderSettings.contents[DEFAULT_CONTENT].settings);
            return {
                version: 5,
                contents: upgradedContents
            };
        }

        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v4 to v5.`);
    }
};

const updatePlaceholderSettingV5toV6IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion5,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion6 => {
    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
        case CommonPlaceholderType.MEDIA:
            return {
                ...placeholderSettings,
                version: 6
            };
        case CommonPlaceholderType.TEXT: {
            const upgradedContents: ContentsWithV6Settings = placeholderTextSettingsContentsV2toV3(placeholderSettings.contents[DEFAULT_CONTENT].settings);
            return {
                version: 6,
                contents: upgradedContents
            };
        }

        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v5 to v6.`);
    }
};

const updatePlaceholderSettingV6toV7IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion6,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion7 => {
    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
        case CommonPlaceholderType.TEXT:
            return {
                ...placeholderSettings,
                version: 7
            };
        case CommonPlaceholderType.MEDIA: {
            const upgradedContents: ContentsWithV7Settings = placeholderMediaSettingsContentsV4toV5(placeholderSettings.contents[DEFAULT_CONTENT].settings);
            return {
                version: 7,
                contents: upgradedContents
            };
        }

        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v6 to v7.`);
    }
};

const updatePlaceholderSettingV7toV8IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion7,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion8 => {
    switch (phType) {
        case CommonPlaceholderType.MEDIA:
        case CommonPlaceholderType.NARRATION:
            return {
                ...placeholderSettings,
                version: 8
            };
        case CommonPlaceholderType.BUTTON:
        {
            return {
                version: 8,
                contents:  {
                    [DEFAULT_CONTENT]: {
                        type: ContentType.Button,
                        settings: {
                            [GlobalSettingKey.IsHidden]: buildHiddenSetting()
                        }
                    }
                }
            };
        }
        case CommonPlaceholderType.TEXT: {
            const upgradedContents: ContentsWithV8Settings = placeholderTextSettingsContentsV3toV4(placeholderSettings.contents[DEFAULT_CONTENT].settings);
            return {
                version: 8,
                contents: upgradedContents
            };
        }

        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v7 to v8.`);
    }
};


const updatePlaceholderSettingV8toV9IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion8,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion9 => {
    switch (phType) {
        case CommonPlaceholderType.MEDIA:
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
            return {
                ...placeholderSettings,
                version: 9
            };
        case CommonPlaceholderType.TEXT: {
            const upgradedContents: ContentsWithV9Settings = placeholderTextSettingsContentsV4toV5(placeholderSettings.contents[DEFAULT_CONTENT].settings);
            return {
                version: 9,
                contents: upgradedContents
            };
        }

        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v8 to v9.`);
    }
};

const updatePlaceholderSettingV9toV10IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion9,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion10 => {
    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
        case CommonPlaceholderType.TEXT:
            return {
                ...placeholderSettings,
                version: 10
            };
        case CommonPlaceholderType.MEDIA: {
            const upgradedContents: ContentsWithV10Settings = placeholderMediaSettingsContentsV5toV6(placeholderSettings.contents[DEFAULT_CONTENT].settings);
            return {
                version: 10,
                contents: upgradedContents
            };
        }

        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v9 to v10.`);
    }
};

const updatePlaceholderSettingV10toV11IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion10,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion11 => {
    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
        case CommonPlaceholderType.TEXT:
        case CommonPlaceholderType.MEDIA:
            return {
                ...placeholderSettings,
                version: 11
            };
        case CommonPlaceholderType.AVATAR: {
            return {
                version: 11,
                contents: {
                    [DEFAULT_CONTENT]: {
                        type: ContentType.Avatar,
                        settings: {
                            [AvatarSettingsKey.Crop]: buildAvatarCropSetting()
                        }
                    }
                }
            };
        }
        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v10 to v11.`);
    }
};

const updatePlaceholderSettingV11toV12IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion11,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion12 => {
    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
        case CommonPlaceholderType.TEXT:
        case CommonPlaceholderType.MEDIA:
            return {
                ...placeholderSettings,
                version: 12
            };
        case CommonPlaceholderType.AVATAR: {
            return {
                version: 12,
                contents: {
                    [DEFAULT_CONTENT]: {
                        type: ContentType.Avatar,
                        settings: {
                            ...placeholderSettings.contents[DEFAULT_CONTENT].settings,
                            [AvatarSettingsKey.DeprecatedAlignment]: buildAvatarAlignmentSetting()
                        }
                    }
                }
            };
        }
        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v11 to v12.`);
    }
};

const updatePlaceholderSettingV12toV13IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion12,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion13 => {
    switch (phType) {
        case CommonPlaceholderType.AVATAR:
        case CommonPlaceholderType.BUTTON:
        case CommonPlaceholderType.TEXT:
        case CommonPlaceholderType.MEDIA:
            return {
                ...placeholderSettings,
                version: 13
            };
        case CommonPlaceholderType.NARRATION: {
            return {
                version: 13,
                contents: {
                    [DEFAULT_CONTENT]: {
                        type: ContentType.Narration,
                        settings: {
                            ...placeholderSettings.contents[DEFAULT_CONTENT]?.settings,
                            [NarrationSettingsKey.AlternativeVoice]: buildNarrationAlternativeVoiceSetting()
                        }
                    }
                }
            };
        }
        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v12 to v13.`);
    }
};

const updatePlaceholderSettingV13toV14IfNeeded = (
    placeholderSettings: PlaceholderSettingsVersion13,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettingsVersion14 => {
    switch (phType) {
        case CommonPlaceholderType.NARRATION:
        case CommonPlaceholderType.BUTTON:
        case CommonPlaceholderType.TEXT:
        case CommonPlaceholderType.MEDIA:
            return {
                ...placeholderSettings,
                version: 14
            };
        case CommonPlaceholderType.AVATAR: {
            const settings = placeholderSettings.contents[DEFAULT_CONTENT]?.settings ?? {};
            delete settings[AvatarSettingsKey.DeprecatedAlignment];
            return {
                version: 14,
                contents: {
                    [DEFAULT_CONTENT]: {
                        type: ContentType.Avatar,
                        settings
                    }
                }
            };
        }
        default:
            throw new Error(`Cannot update placeholder setting type ${phType} from v12 to v13.`);
    }
};

export const __testing = {
    updatePlaceholderSettingV1toV2IfNeeded,
    updatePlaceholderSettingV2toV3IfNeeded,
    updatePlaceholderSettingV3toV4IfNeeded,
    updatePlaceholderSettingV4toV5IfNeeded,
    updatePlaceholderSettingV5toV6IfNeeded,
    updatePlaceholderSettingV6toV7IfNeeded,
    updatePlaceholderSettingV7toV8IfNeeded,
    updatePlaceholderSettingV8toV9IfNeeded,
    updatePlaceholderSettingV9toV10IfNeeded,
    updatePlaceholderSettingV10toV11IfNeeded,
    updatePlaceholderSettingV11toV12IfNeeded,
    updatePlaceholderSettingV12toV13IfNeeded,
    updatePlaceholderSettingV13toV14IfNeeded
};

const placeholderMediaSettingsContentsV2toV3 = (
    mediaSettingsContentV2: ContentsWithV2Settings,
    phIntentType: PlaceholderIntentName
): ContentsWithV3Settings => {
    const v3Settings = (Object.keys(mediaSettingsContentV2[DEFAULT_CONTENT].settings) as Array<MediaSettingKey>)
        .reduce((acc, key) => {
            acc[key] = mediaSettingsContentV2[DEFAULT_CONTENT].settings[key];
            return acc;
        }, {});

    v3Settings[MediaSettingKey.Breathing] = getDefaultBreathingValue(phIntentType);
    v3Settings[MediaSettingKey.PlayToEnd] = getDefaultPlayToEndValue(phIntentType);

    const settingsCollections: SettingsCollectionsV3 = {
        type: ContentType.Media,
        settings: v3Settings as MediaSettingsCollectionV3
    };

    return {
        [DEFAULT_CONTENT]: settingsCollections
    };
};

const placeholderMediaSettingsContentsV3toV4 = (
    mediaSettingsCollectionV3: MediaSettingsCollectionV3
): ContentsWithV4Settings => {
    return {
        [DEFAULT_CONTENT]: {
            type: ContentType.Media,
            settings: {
                ...mediaSettingsCollectionV3,
                [MediaSettingKey.Sound]: getDefaultSoundValue()
            }
        }
    };
};

const placeholderMediaSettingsContentsV4toV5 = (
    mediaSettingsCollectionV4: MediaSettingsCollectionV4
): ContentsWithV7Settings => {
    return {
        [DEFAULT_CONTENT]: {
            type: ContentType.Media,
            settings: {
                ...mediaSettingsCollectionV4,
                [MediaSettingKey.OverrideLogo]: getDefaultOverrideLogoValue()
            }
        }
    };
};

const placeholderMediaSettingsContentsV5toV6 = (
    mediaSettingsCollectionV5: MediaSettingsCollectionV5
): ContentsWithV10Settings => {
    const { [MediaSettingKey.PlayToEnd]: playToEndSetting, ...mediaSettingsCollectionV5WithoutPlayToEnd } = mediaSettingsCollectionV5;
    return {
        [DEFAULT_CONTENT]: {
            type: ContentType.Media,
            settings: {
                ...mediaSettingsCollectionV5WithoutPlayToEnd,
                [MediaSettingKey.Duration]:
                    playToEndSetting?.useValue ?
                        buildDurationSetting(true, DurationType.PlayToEndOfContent) :
                        buildDurationSetting(false, DurationType.TruncateContentAtSceneEnd)
            }
        }
    };
};

const placeholderTextSettingsContentsV1toV2 = (
    textSettingsCollectionV1: TextSettingsCollectionV1
): ContentsWithV5Settings => {
    return {
        [DEFAULT_CONTENT]: {
            type: ContentType.Text,
            settings: {
                ...textSettingsCollectionV1,
                [TextSettingKey.BackgroundColor]: getDefaultBackgroundColorValue()
            }
        }
    };
};

const placeholderTextSettingsContentsV2toV3 = (
    textSettingsCollectionV2: TextSettingsCollectionV2
): ContentsWithV6Settings => {
    return {
        [DEFAULT_CONTENT]: {
            type: ContentType.Text,
            settings: {
                ...textSettingsCollectionV2,
                [TextSettingKey.HorizontalAlignment]: buildTextHorizontalAlignmentSetting(),
                [TextSettingKey.VerticalAlignment]: buildTextVerticalAlignmentSetting()
            }
        }
    };
};

const placeholderTextSettingsContentsV3toV4 = (
    textSettingsCollectionV3: TextSettingsCollectionV3
): ContentsWithV8Settings => {
    return {
        [DEFAULT_CONTENT]: {
            type: ContentType.Text,
            settings: {
                ...textSettingsCollectionV3,
                [GlobalSettingKey.IsHidden]: buildHiddenSetting()
            }
        }
    };
};

const placeholderTextSettingsContentsV4toV5 = (
    textSettingsCollectionV4: TextSettingsCollectionV4
): ContentsWithV9Settings => {
    return {
        [DEFAULT_CONTENT]: {
            type: ContentType.Text,
            settings: {
                ...textSettingsCollectionV4,
                [TextSettingKey.Style]: buildTextStyleSetting(),
                [TextSettingKey.Fit]: buildTextFitSetting()
            }
        }
    };
};

const VERSION_UPDATE_SETTING_MANAGERS: Array<{updater: UpdatePlaceholderSettingsOneVersionUp, version: number}> = [
    { version: 1, updater: updatePlaceholderSettingV1toV2IfNeeded },
    { version: 2, updater: updatePlaceholderSettingV2toV3IfNeeded },
    { version: 3, updater: updatePlaceholderSettingV3toV4IfNeeded },
    { version: 4, updater: updatePlaceholderSettingV4toV5IfNeeded },
    { version: 5, updater: updatePlaceholderSettingV5toV6IfNeeded },
    { version: 6, updater: updatePlaceholderSettingV6toV7IfNeeded },
    { version: 7, updater: updatePlaceholderSettingV7toV8IfNeeded },
    { version: 8, updater: updatePlaceholderSettingV8toV9IfNeeded },
    { version: 9, updater: updatePlaceholderSettingV9toV10IfNeeded },
    { version: 10, updater: updatePlaceholderSettingV10toV11IfNeeded },
    { version: 11, updater: updatePlaceholderSettingV11toV12IfNeeded },
    { version: 12, updater: updatePlaceholderSettingV12toV13IfNeeded },
    { version: 13, updater: updatePlaceholderSettingV13toV14IfNeeded },
    { version: 14, updater: (content: PlaceholderSettingsVersions) => content }
];

export const updatePlaceholderContentVersionOnly = (placeholderContent: PlaceholderContentVersions, phType: CommonPlaceholderType): PlaceholderContent => {
    let convertedPlaceholder: PlaceholderContentVersions = placeholderContent;

    for (let updateManagers of VERSION_UPDATE_MANAGERS) {
        convertedPlaceholder = updateOneVersionUpIfNeeded(convertedPlaceholder, phType, updateManagers.updater, updateManagers.version);
    }

    if (convertedPlaceholder.version === VERSION_UPDATE_MANAGERS[VERSION_UPDATE_MANAGERS.length - 1].version) {
        return convertedPlaceholder as PlaceholderContent;
    }

    throw new Error(`Placeholder Content version not supported (received version ${placeholderContent.version} and got it up to ${convertedPlaceholder.version})`);
};

export const updatePlaceholderContentToSpecificVersionOnly = (placeholderContent: PlaceholderContentVersions, phType: CommonPlaceholderType, targetVersion: number): PlaceholderContentVersions => {
    let convertedPlaceholder: PlaceholderContentVersions = placeholderContent;

    for (let updateManagers of VERSION_UPDATE_MANAGERS) {
        if (updateManagers.version < targetVersion) {
            convertedPlaceholder = updateOneVersionUpIfNeeded(convertedPlaceholder, phType, updateManagers.updater, updateManagers.version);
        }
    }

    if (convertedPlaceholder.version === targetVersion) {
        return convertedPlaceholder as PlaceholderContent;
    }

    throw new Error(`Cannot update placeholder content to version ${targetVersion} (received version ${placeholderContent.version} and got it up to ${convertedPlaceholder.version})`);
};


export const updatePlaceholderSettingsVersionOnly = (
    placeholderSettings: PlaceholderSettingsVersions,
    phType: CommonPlaceholderType,
    phIntentType: PlaceholderIntentName
): PlaceholderSettings => {
    let convertedPlaceholderSettings: PlaceholderSettingsVersions = placeholderSettings;

    for (let updateManagers of VERSION_UPDATE_SETTING_MANAGERS) {
        convertedPlaceholderSettings = updateOneVersionSettingsUpIfNeeded(convertedPlaceholderSettings, phType, phIntentType, updateManagers.updater, updateManagers.version);
    }

    if (convertedPlaceholderSettings.version !== VERSION_UPDATE_SETTING_MANAGERS[VERSION_UPDATE_SETTING_MANAGERS.length - 1].version) {
        throw new Error(`Placeholder settings version not supported (received version ${placeholderSettings.version} and got it up to ${convertedPlaceholderSettings.version})`);
    }

    return convertedPlaceholderSettings as PlaceholderSettings;

};
// endregion

type SingleElementIds = {
    id: string;
    valueSetIds: string[];
}
export type ElementsData = SingleElementIds[];
type ElementsDataById = { [key: string]: SingleElementIds };

/**
 * Merges a list of SingleElementIds into an ElementsDataById
 *
 * @param target target ElementsDataById
 * @param sources array of SingleElementIds
 */
const mergeElementData = (target: ElementsDataById, ...sources: ElementsData): void => {
    sources.forEach((source: SingleElementIds) => {
        const { id, valueSetIds } = source;
        if (!target[id]) {
            target[id] = source;
        }
        else {
            target[id].valueSetIds = Array.from(new Set<string>([...target[id].valueSetIds, ...valueSetIds]));
        }
    });
};

const buildSingleElementIds = (id: string, valueSetIds?: string[]): SingleElementIds => ({ id, valueSetIds: valueSetIds ?? [] });

/**
 * Returns data element ids from a given placeholderContent
 *
 * @param placeholderContent - Given placeholderContent
 * @param includeDisabledPaths - If this is true, elements will also be collected from paths that are currently disabled (for example, if
 *      useRules=false, we will still check the rules object). This should usually be true for draft programs, and false for snapshot programs
 *         (whose disabled paths will remain disabled)
 */
export function getDataElementsFromPlaceholderContent(placeholderContent: PlaceholderContent, includeDisabledPaths: boolean) : Array<string> {
    return getDataElementsAndValuesFromPlaceholderContent(placeholderContent, includeDisabledPaths).map(element => element.id);
}

/**
 * Returns element data (id+values ids) from a given placeholderContent
 *
 * @param placeholderContent - Given placeholderContent
 * @param includeDisabledPaths - If this is true, elements will also be collected from paths that are currently disabled (for example, if
 *      useRules=false, we will still check the rules object). This should usually be true for draft programs, and false for snapshot programs
 *         (whose disabled paths will remain disabled)
 */
export const getDataElementsAndValuesFromPlaceholderContent = (placeholderContent: PlaceholderContent, includeDisabledPaths: boolean): ElementsData => {
    const dataElementsById: ElementsDataById = {};
    switch (placeholderContent.placeholderType) {
        case CommonPlaceholderType.TEXT:
            mergeElementData(dataElementsById, ...getTextOrNarrationDataElementIds(placeholderContent as TextPlaceholderContent, includeDisabledPaths));
            break;
        case CommonPlaceholderType.MEDIA:
            mergeElementData(dataElementsById, ...getMediaDataElementIds(placeholderContent as MediaPlaceholderContent, includeDisabledPaths));
            break;
        case CommonPlaceholderType.NARRATION:
            mergeElementData(dataElementsById, ...getTextOrNarrationDataElementIds(placeholderContent as NarrationPlaceholderContent, includeDisabledPaths));
            break;
        case CommonPlaceholderType.BUTTON:
            mergeElementData(dataElementsById, ...getButtonDataElementIds(placeholderContent as ButtonPlaceholderContent, includeDisabledPaths));
            break;
        case CommonPlaceholderType.AVATAR:
            mergeElementData(dataElementsById, ...getAvatarDataElementIds(placeholderContent as AvatarPlaceholderContent, includeDisabledPaths));
            break;
    }

    return Object.values(dataElementsById);
};

export const getTextOrNarrationText = (
    placeholderType: CommonPlaceholderType, 
    placeholderContentShow: NarrationShow<DynamicTextAtoms | StaticTextAtoms> | DynamicRichTextAtoms | StaticRichTextAtoms
): (DynamicTextAtoms | DynamicRichTextAtoms | StaticTextAtoms | StaticRichTextAtoms) => {
    if (placeholderType === CommonPlaceholderType.NARRATION) {
        return (placeholderContentShow as NarrationShow<DynamicTextAtoms | StaticTextAtoms>)?.text;
    }
    return placeholderContentShow as (DynamicRichTextAtoms | StaticTextAtoms);
};

function getTextOrNarrationDataElementIds(placeholderContent: TextPlaceholderContent | NarrationPlaceholderContent, includeDisabledPaths: boolean): ElementsData {
    const dataElementsDataByIds: ElementsDataById = {};

    if ((includeDisabledPaths || placeholderContent.useRules) && placeholderContent.rules?.by?.localId) {
        // switch by data element
        mergeElementData(dataElementsDataByIds, buildSingleElementIds(placeholderContent.rules.by.localId));

        if (placeholderContent.rules?.cases) {
            for (const ruleCase of placeholderContent.rules.cases) {
                mergeElementData(dataElementsDataByIds, ...getTextOrNarrationShowElementIds(getTextOrNarrationText(placeholderContent.placeholderType, ruleCase.show)));
                mergeElementData(dataElementsDataByIds, buildSingleElementIds(placeholderContent.rules.by.localId, ruleCase.valueIds));
            }
        }
    }
    if ((includeDisabledPaths || !placeholderContent.useRules) && placeholderContent.noRules?.show) {
        if (placeholderContent.noRules.show) {
            mergeElementData(dataElementsDataByIds, 
                ...getTextOrNarrationShowElementIds(getTextOrNarrationText(placeholderContent.placeholderType, placeholderContent.noRules.show)));
        }
    }
    return Object.values(dataElementsDataByIds);
}

function getMediaDataElementIds(placeholderContent: MediaPlaceholderContent, includeDisabledPaths: boolean): ElementsData {
    const dataElementsDataByIds: ElementsDataById = {};

    if ((includeDisabledPaths || placeholderContent.useRules) && placeholderContent.rules?.by?.localId) {
        // switch by data element
        mergeElementData(dataElementsDataByIds, buildSingleElementIds(placeholderContent.rules.by.localId));

        for (const ruleCase of placeholderContent.rules.cases) {
            mergeElementData(dataElementsDataByIds, ...getMediaShowV6Or8ElementIds(ruleCase?.show, includeDisabledPaths));
            mergeElementData(dataElementsDataByIds, buildSingleElementIds(placeholderContent.rules.by.localId, ruleCase.valueIds));
        }
    }

    if (includeDisabledPaths || !placeholderContent.useRules) {
        mergeElementData(dataElementsDataByIds, ...getMediaShowV6Or8ElementIds(placeholderContent.noRules?.show, includeDisabledPaths));
    }

    return Object.values(dataElementsDataByIds);
}

function getAvatarDataElementIds(placeholderContent: AvatarPlaceholderContent, includeDisabledPaths: boolean): ElementsData {
    const dataElementsDataByIds: ElementsDataById = {};

    if ((includeDisabledPaths || placeholderContent.useRules) && placeholderContent.rules?.by?.localId) {
        // switch by data element
        mergeElementData(dataElementsDataByIds, buildSingleElementIds(placeholderContent.rules.by.localId));

        for (const ruleCase of placeholderContent.rules.cases) {
            mergeElementData(dataElementsDataByIds, ...getAvatarShowElementIds(ruleCase?.show, includeDisabledPaths));
            mergeElementData(dataElementsDataByIds, buildSingleElementIds(placeholderContent.rules.by.localId, ruleCase.valueIds));
        }
    }

    if (includeDisabledPaths || !placeholderContent.useRules) {
        mergeElementData(dataElementsDataByIds, ...getAvatarShowElementIds(placeholderContent.noRules?.show, includeDisabledPaths));
    }

    return Object.values(dataElementsDataByIds);
}

function getButtonDataElementIds(placeholderContent: ButtonPlaceholderContent, includeDisabledPaths: boolean): ElementsData {
    const dataElementsDataByIds: ElementsDataById = {};
    if ((includeDisabledPaths || placeholderContent.useRules) && placeholderContent.rules?.by?.localId) {
        // switch by data element
        mergeElementData(dataElementsDataByIds, buildSingleElementIds(placeholderContent.rules.by.localId));

        for (const ruleCase of placeholderContent.rules.cases) {
            mergeElementData(dataElementsDataByIds, ...getButtonShowElementIds(ruleCase.show, includeDisabledPaths));
            mergeElementData(dataElementsDataByIds, buildSingleElementIds(placeholderContent.rules.by.localId, ruleCase.valueIds));
        }
    }

    if (includeDisabledPaths || !placeholderContent.useRules) {
        mergeElementData(dataElementsDataByIds, ...getButtonShowElementIds(placeholderContent.noRules?.show, includeDisabledPaths));
    }
    return Object.values(dataElementsDataByIds);
}

/**
 * Add used elements IDs from a button show element
 * @param show button show element
 * @param includeDisabledPaths whether to include elements from paths that are currently disabled (but may be enabled by user action)
 */
export function getButtonShowElementIds(show: ButtonShowV9<DynamicTextAtoms, DynamicRichTextAtoms>, includeDisabledPaths: boolean): ElementsData {
    if (!show || !show.actionShow) {
        return [];
    }

    const dataElementsDataByIds: ElementsDataById = {};

    for (const [actionType, buttonShowContent] of Object.entries(show.actionShow)) {
        // If this is the chosen action, or we need to include all paths, check it
        if (buttonShowContent && (includeDisabledPaths || actionType === show.useAction)) {
            mergeElementData(dataElementsDataByIds, ...getTextOrNarrationShowElementIds(buttonShowContent.text.content));
            mergeElementData(dataElementsDataByIds, ...getTextOrNarrationShowElementIds(buttonShowContent.url.content));
        }
    }

    return Object.values(dataElementsDataByIds);
}

/**
 * Add used elements IDs from a media show element
 * @param show media show element
 * @param includeDisabledPaths whether to include elements from paths that are currently disabled (but may be enabled by user action)
 */
function getMediaShowV6Or8ElementIds(show: MediaShowV6 | MediaShowV8, includeDisabledPaths: boolean): ElementsData {
    if (!show) {
        return [];
    }

    const dataElementsDataByIds: ElementsDataById = {};
    if (includeDisabledPaths || show?.showType === PlaceholderShowType.Dynamic) {
        const localId = show?.dynamic?.localId;
        if (localId) {
            mergeElementData(dataElementsDataByIds, buildSingleElementIds(localId));
        }
    }

    return Object.values(dataElementsDataByIds);
}

/**
 * Add used elements IDs from an avatar show element
 * @param show avatar show element
 * @param includeDisabledPaths whether to include elements from paths that are currently disabled (but may be enabled by user action)
 */
function getAvatarShowElementIds(show: AvatarShowV1, includeDisabledPaths: boolean): ElementsData {
    if (!show) {
        return [];
    }

    const dataElementsDataByIds: ElementsDataById = {};
    if (includeDisabledPaths || show?.showType === PlaceholderShowType.Dynamic) {
        const localId = show?.dynamic?.localId;
        if (localId) {
            mergeElementData(dataElementsDataByIds, buildSingleElementIds(localId));
        }
    }

    return Object.values(dataElementsDataByIds);
}

/**
 * Add used elements IDs from a text/narration show element
 * @param show text/narration show element
 */
export function getTextOrNarrationShowElementIds(show: DynamicRichTextAtoms | DynamicTextAtoms): ElementsData {
    const dataElementIds: ElementsDataById = {};
    if (show && Array.isArray(show)) {
        for (const dynamicTextAtom of show) {
            if ((dynamicTextAtom?.content as DataElementDescriptor)?.localId) {
                mergeElementData(dataElementIds, buildSingleElementIds((dynamicTextAtom.content as DataElementDescriptor).localId));
            }
        }
    }
    return Object.values(dataElementIds);
}

function getValueFromPlaceholderSettingsFactory(content: string) {
    return function settingGetterFactory<SA extends SettingAtoms>(settingKey: SettingKey) {
        return function settingGetter(phSettings: PlaceholderSettings): SA {
            return phSettings.contents?.[content]?.settings?.[settingKey];
        };
    };
}

// default content
const getDefaultSettingAtom = getValueFromPlaceholderSettingsFactory(DEFAULT_CONTENT);

// text
export const getDefaultFontSizeSetting = getDefaultSettingAtom<FontSizeSetting>(TextSettingKey.FontSize);
export const getDefaultColorSetting = getDefaultSettingAtom<ColorSetting>(TextSettingKey.Color);
export const getDefaultTextBackgroundColorSetting = getDefaultSettingAtom<ColorSetting>(TextSettingKey.BackgroundColor);
export const getDefaultTextHorizontalAlignmentSetting = getDefaultSettingAtom<TextHorizontalAlignmentSetting>(TextSettingKey.HorizontalAlignment);
export const getDefaultTextVerticalAlignmentSetting = getDefaultSettingAtom<TextVerticalAlignmentSetting>(TextSettingKey.VerticalAlignment);
export const getDefaultTextFitSetting = getDefaultSettingAtom<TextFitSetting>(TextSettingKey.Fit);
export const getDefaultTextStyleSetting = getDefaultSettingAtom<TextStyleSetting>(TextSettingKey.Style);

// media
export const getAdjustmentSetting = getDefaultSettingAtom<AdjustmentSetting>(MediaSettingKey.Adjustment);
export const getMediaAlignmentSetting = getDefaultSettingAtom<MediaAlignmentSetting>(MediaSettingKey.Alignment);
export const getBreathingSetting = getDefaultSettingAtom<BreathingSetting>(MediaSettingKey.Breathing);
export const getSoundSetting = getDefaultSettingAtom<SoundSetting>(MediaSettingKey.Sound);
export const getLogoOverrideSetting = getDefaultSettingAtom<OverrideLogoSetting>(MediaSettingKey.OverrideLogo);
export const getDurationSetting = getDefaultSettingAtom<DurationSetting>(MediaSettingKey.Duration);

// button text
const getButtonTextSettingAtom = getValueFromPlaceholderSettingsFactory("text");
export const getButtonTextColorSetting = getButtonTextSettingAtom<ColorSetting>(TextSettingKey.Color);

// avatar
export const getAvatarCropSetting = getDefaultSettingAtom<AvatarCropSetting>(AvatarSettingsKey.Crop);

//narration
export const getNarrationAlternativeVoiceSetting = getDefaultSettingAtom<AlternativeVoiceSetting>(NarrationSettingsKey.AlternativeVoice);

// global settings
export const getHiddenSetting = getDefaultSettingAtom<HiddenSetting>(GlobalSettingKey.IsHidden);

// type guards
export const isTextSettingsCollection = (content: any): content is TextSettings => content?.type === ContentType.Text;
export const isMediaSettingsCollection = (content: any): content is MediaSettings => content?.type === ContentType.Media;

// endregion
/**
 * get the color value from colorSetting - whether it's custom color, cc or should be null.
 * @parm colorSetting - a ColorSetting object
 * @parm getColorByCcDofLocalId - a function that given cc dof local Id can return the color value
 * @returns color in hex format or null
 * */
export const resolveColorValue = (colorSetting: ColorSetting, getColorByCcDofLocalId: (localId: string) => string | null): string | null => {
    if (colorSetting?.useValue && colorSetting?.value) {
        if (colorSetting.value.ccDofLocalId) {
            return getColorByCcDofLocalId(colorSetting.value.ccDofLocalId);
        }
        else if (colorSetting.value.customColor) {
            return colorSetting.value.customColor;
        }
    }
    return null;
};

export const resolveBackgroundColorValue = (backgroundColorSetting: ColorSetting, getColorByCcDofLocalId: (localId: string) => string | null): string | null => {
    if (backgroundColorSetting?.useValue && backgroundColorSetting?.value) {
        if (backgroundColorSetting.value.ccDofLocalId) {
            return getColorByCcDofLocalId(backgroundColorSetting.value.ccDofLocalId);
        }
        else if (backgroundColorSetting.value.customColor) {
            return backgroundColorSetting.value.customColor;
        }
    }
    return null;
};

export const resolveVolumeValue = (placeholderSettings: PlaceholderSettings): VolumeSettings => {
    const soundSetting = getSoundSetting(placeholderSettings);
    if (!soundSetting.useValue) {
        return {
            value: 0,
            unit: "%"
        };
    }
    return soundSetting.value;
};

export const resolveFontSizeValue = (fontSizeSetting: FontSizeSetting): FontSizeType | null => {
    if (fontSizeSetting?.useValue && fontSizeSetting?.value) {
        const fontSizeValue : FontSizeType = {
            value: fontSizeSetting.value.fontSize.value / 100.0,
            unit: fontSizeSetting.value.fontSize.unit
        };

        return fontSizeValue;
    }
    return null;
};

export const resolveTextHorizontalAlignmentValue = (horizontalAlignmentSetting: TextHorizontalAlignmentSetting): TextHorizontalAlign | null => {
    if (horizontalAlignmentSetting?.useValue && horizontalAlignmentSetting?.value?.horizontalAlignment) {
        return getTextStyleHorizontalAlign(horizontalAlignmentSetting.value.horizontalAlignment);
    }
    return null;
};

export const resolveTextFitValue = (textFitSetting: TextFitSetting): TextFit | null => {
    if (textFitSetting?.useValue && textFitSetting?.value?.fit) {
        return textFitSetting?.value?.fit;
    }
    return null;
};

export const resolveTextVerticalAlignmentValue = (verticalAlignmentSetting: TextVerticalAlignmentSetting): TextVerticalAlign | null => {
    if (verticalAlignmentSetting?.useValue && verticalAlignmentSetting?.value?.verticalAlignment) {
        return getTextStyleVerticalAlign(verticalAlignmentSetting.value.verticalAlignment);
    }
    return null;
};

export const resolveAdjustmentValue = (placeholderSettings: PlaceholderSettings): ObjectFit | null => {
    const adjustmentSetting = getAdjustmentSetting(placeholderSettings);
    return mapAdjustmentToFit(adjustmentSetting?.useValue ? adjustmentSetting.value.mediaAdjustment : null);
};

export const resolveMediaAlignmentValue = (placeholderSettings: PlaceholderSettings): ObjectPosition | null => {
    const alignmentSetting = getMediaAlignmentSetting(placeholderSettings);
    if (alignmentSetting?.useValue && alignmentSetting?.value) {
        if (alignmentSetting.value.mediaAlignment) {
            return convertToObjectPosition(alignmentSetting.value.mediaAlignment);
        }
    }
    return null;
};

type resolveSettingFunction = (...args) => FontSizeType | string | null;
export const MediaSettingsMapping: Partial<Record<MediaSettingKey, { resolveFn: resolveSettingFunction, elementName: string } >> =
    {
        [MediaSettingKey.Adjustment]: { resolveFn: resolveAdjustmentValue, elementName: "objectFit" },
        [MediaSettingKey.Alignment]: { resolveFn: resolveMediaAlignmentValue, elementName: "objectPosition" }
    };

export const isMediaShowEmpty = (mediaShow: StaticMediaShowV8): boolean => {
    return !mediaShow?.mediaId;
};

export const updateMediaFallbackIfNeeded = (phContent): void => {
    if (isMediaShowEmpty(phContent.staticFallback) && !isMediaShowEmpty(phContent?.noRules?.show?.static)) {
        phContent.staticFallback = { ...phContent.noRules.show.static };
    }
};

export const isPlaceholderHidden = (placeholderSettings: PlaceholderSettings): boolean => {
    if (placeholderSettings) {
        const hiddenSetting = getHiddenSetting(placeholderSettings);
        return hiddenSetting?.useValue && hiddenSetting?.value.isHidden;
    }
    return false;
};

export const isPlaceholderOverrideLogo = (placeholderSettings: PlaceholderSettings): boolean => {
    if (placeholderSettings) {
        const overrideSetting = getLogoOverrideSetting(placeholderSettings);
        return overrideSetting?.useValue;
    }
    return false;
};

export const resolveBreathingValue = (placeholderSettings: PlaceholderSettings, themeBreathingValue: BreathingType | null): BreathingType | null => {
    const breathingSetting = getBreathingSetting(placeholderSettings);

    if (breathingSetting?.useValue) {
        return breathingSetting.value?.breathing ?? null;
    }
    else if (themeBreathingValue) {
        return themeBreathingValue;
    }

    return null;
};

export type CreatePlaceholderDefaultContentAndSettingsParams = {
    phType: CommonPlaceholderType;
    defaultValue: string; // id
    intentName: PlaceholderIntentName;
    defaultShowValue?: PlaceholderContent["noRules"]["show"]
};

export type PlaceholderDefaultContentAndSettings = {
    defaultContent: PlaceholderContent;
    defaultSettings: PlaceholderSettings
};

export const getEmptyRichTextAtoms = (): StaticRichTextAtoms => [{
    content: "",
    style: {
        bold: RichTextStyleSetting.UNSET,
        italic: RichTextStyleSetting.UNSET
    }
}];

export const createPlaceholderDefaultContentAndSettings = (params: CreatePlaceholderDefaultContentAndSettingsParams): PlaceholderDefaultContentAndSettings => {
    const { defaultValue, intentName, phType, defaultShowValue } = params;

    const mediaId = defaultValue?.startsWith("cc-entities") ? null : defaultValue;

    if (phType == CommonPlaceholderType.MEDIA) {
        const mediaPlaceholderContent: MediaPlaceholderContent = {
            version: PLACEHOLDER_CONTENT_VERSION,
            placeholderType: phType,
            useRules: false,
            noRules:  {
                show : (defaultShowValue as MediaPlaceholderContent["noRules"]["show"]) ?? {
                    showType: PlaceholderShowType.Static,
                    static: { mediaId },
                    dynamic: null
                }
            },
            rules : {
                by: null,
                cases: [{
                    valueIds: [],
                    show: {
                        showType: PlaceholderShowType.Static,
                        static: null,
                        dynamic: null
                    }
                }]
            },
            staticFallback: null
        };
        const defaultSettingsMedia: PlaceholderSettings = {
            version: PLACEHOLDER_SETTINGS_VERSION,
            contents: {
                [DEFAULT_CONTENT]: buildMediaSettings(intentName)
            }
        };

        return { defaultContent: mediaPlaceholderContent, defaultSettings: defaultSettingsMedia };
    }
    else if (phType === CommonPlaceholderType.BUTTON) {
        const buttonPlaceholderContent: ButtonPlaceholderContent = {
            version: PLACEHOLDER_CONTENT_VERSION,
            placeholderType: phType,
            useRules: false,
            staticFallback: {
                useAction: "OpenUrl",
                actionShow: initiateButtonActionShow<
                    ButtonShowContentV9<StaticTextAtoms, StaticRichTextAtoms>,
                    ButtonActionShowV9<StaticTextAtoms, StaticRichTextAtoms>
                >(getDefaultButtonShowContentV9<StaticTextAtoms, StaticRichTextAtoms>(""))
            },
            rules: {
                by: null,
                cases: [{
                    valueIds: [],
                    show: {
                        useAction: "OpenUrl",
                        actionShow: initiateButtonActionShow<
                            ButtonShowContentV9<DynamicTextAtoms, DynamicRichTextAtoms>,
                            ButtonActionShowV9<DynamicTextAtoms, DynamicRichTextAtoms>
                        >(getDefaultButtonShowContentV9<DynamicTextAtoms, DynamicRichTextAtoms>(""))
                    }
                }]
            },
            noRules: {
                show: (defaultShowValue as ButtonPlaceholderContent["noRules"]["show"]) ?? {
                    useAction: "OpenUrl",
                    actionShow: initiateButtonActionShow<
                        ButtonShowContentV9<DynamicTextAtoms, DynamicRichTextAtoms>,
                        ButtonActionShowV9<DynamicTextAtoms, DynamicRichTextAtoms>
                    >(getDefaultButtonShowContentV9<DynamicTextAtoms, DynamicRichTextAtoms>(defaultValue))
                }
            }
        };
        const defaultSettingsButton: PlaceholderSettings = {
            version: PLACEHOLDER_SETTINGS_VERSION,
            contents: {
                [DEFAULT_CONTENT]: buildButtonSettings()
            }
        };
        return { defaultContent: buttonPlaceholderContent, defaultSettings: defaultSettingsButton };
    }
    else if (phType === CommonPlaceholderType.TEXT) {
        const defaultContentPh: TextPlaceholderContent = {
            version: PLACEHOLDER_CONTENT_VERSION,
            placeholderType: phType,
            useRules: false,
            noRules:  {
                show : (defaultShowValue as TextPlaceholderContent["noRules"]["show"]) ?? [{
                    content: defaultValue,
                    style: {
                        bold: RichTextStyleSetting.UNSET,
                        italic: RichTextStyleSetting.UNSET
                    }
                }]
            },
            rules : {
                by: null,
                cases: [{
                    valueIds: [],
                    show: getEmptyRichTextAtoms()
                }]
            },
            staticFallback: getEmptyRichTextAtoms()
        };

        const defaultSettingsText: PlaceholderSettings = {
            version: PLACEHOLDER_SETTINGS_VERSION,
            contents: {
                [DEFAULT_CONTENT]: buildTextSettings()
            }
        };

        return { defaultContent: defaultContentPh, defaultSettings: defaultSettingsText };
    }
    if (phType == CommonPlaceholderType.AVATAR) {
        const avatarPlaceholderContent: AvatarPlaceholderContent = {
            version: PLACEHOLDER_CONTENT_VERSION,
            placeholderType: phType,
            useRules: false,
            noRules:  {
                show : (defaultShowValue as AvatarPlaceholderContent["noRules"]["show"]) ?? {
                    showType: PlaceholderShowType.Static,
                    static: null,
                    dynamic: null
                }
            },
            rules : {
                by: null,
                cases: [{
                    valueIds: [],
                    show: {
                        showType: PlaceholderShowType.Static,
                        static: null,
                        dynamic: null
                    }
                }]
            },
            staticFallback: null
        };
        const defaultSettingsAvatar: PlaceholderSettings = {
            version: PLACEHOLDER_SETTINGS_VERSION,
            contents: {
                [DEFAULT_CONTENT]: {
                    type: ContentType.Avatar,
                    settings: {
                        [AvatarSettingsKey.Crop]: buildAvatarCropSetting()
                    }
                }
            }
        };

        return { defaultContent: avatarPlaceholderContent, defaultSettings: defaultSettingsAvatar };
    }
    else if (phType == CommonPlaceholderType.NARRATION) {
        const narrationPlaceholderContent: NarrationPlaceholderContent = {
            version: PLACEHOLDER_CONTENT_VERSION,
            placeholderType: phType,
            useRules: false,
            noRules:  {
                show : (defaultShowValue as NarrationPlaceholderContent["noRules"]["show"]) ?? {
                    text: [{ content: defaultValue }],
                    speed: 1
                }
            },
            rules : {
                by: null,
                cases: [{
                    valueIds: [],
                    show: {
                        text: [{ content: "" }],
                        speed: 1
                    }
                }]
            },
            staticFallback: {
                text: [{ content: "" }],
                speed: 1
            }
        };
        const defaultSettingsNarration: PlaceholderSettings = {
            version: PLACEHOLDER_SETTINGS_VERSION,
            contents: {
                [DEFAULT_CONTENT]: {
                    type: ContentType.Narration,
                    settings: {
                        [NarrationSettingsKey.AlternativeVoice]: buildNarrationAlternativeVoiceSetting()
                    }
                }
            }
        };

        return { defaultContent: narrationPlaceholderContent, defaultSettings: defaultSettingsNarration };
    }
};

export const getVoiceOverText = (placeholders: NarrationPlaceholderContent[]): string => {
    let text = "";
    placeholders.forEach(ph => {
        if (ph.useRules) {
            text += ph.staticFallback.text;
        }
        else {
            if (ph.noRules) {
                if (ph.noRules.show.text.length > 1 && ph.staticFallback.text) {
                    text += ph.staticFallback.text;
                }
                else if (ph.noRules.show.text.length > 1) {
                    text += ph.noRules.show.text.join(" ");
                }
                else if (ph.noRules.show.text.length == 1) {
                    text += ph.noRules.show.text[0];
                }
            }
        }
        text += " ";
    });
    return text;
};

export const getNewCase = (placeholderContent: PlaceholderContent): CaseType => {
    switch (placeholderContent.placeholderType) {
        case CommonPlaceholderType.TEXT:
            return {
                valueIds: [],
                show: getEmptyRichTextAtoms()
            };
        case CommonPlaceholderType.NARRATION:
            return {
                valueIds: [],
                show: {
                    text: [{ content: "" }],
                    speed: 1
                }
            };
        case CommonPlaceholderType.MEDIA:
            return {
                valueIds: [],
                show: {
                    showType: PlaceholderShowType.Static,
                    static: null,
                    dynamic: null
                }
            };
        case CommonPlaceholderType.AVATAR:
            return {
                valueIds: [],
                show: {
                    showType: PlaceholderShowType.Static,
                    static: null,
                    dynamic: null
                }
            };
        case CommonPlaceholderType.BUTTON:
            return {
                valueIds: [],
                show: {
                    useAction: "OpenUrl",
                    actionShow: initiateButtonActionShow<
                        ButtonShowContentV9<DynamicTextAtoms, DynamicRichTextAtoms>,
                        ButtonActionShowV9<DynamicTextAtoms, DynamicRichTextAtoms>
                    >(getDefaultButtonShowContentV9<DynamicTextAtoms, DynamicRichTextAtoms>(""))
                }
            };
    }
};

/*
The function checks if the placeholder content is dynamic and has actual dynamic content in it.
We consider placeholder content to be dynamic if:
Using rules:
    1) It has 'message by' set.
    2) It has at least one rule case that has at least one audience assigned to it.
Not using rules:
    1) Is media.
        a) The media show type is dynamic.
        b) The media data field is not null
    2) Not media:
        a) The placeholder has a show item that is dynamic (DataElementDescriptor) with localId
* */
export type DynamicPlaceholderContentInfo = {
    isPersonalized: boolean,
    byAudienceDataField?: { localId: string },
    byAudienceVariationNum?: number,
    dynamicDataField?: { localId: string }
}
export const getPlaceholderContentDynamicInfo = (placeholderContent: PlaceholderContent, placeholderType: CommonPlaceholderType): DynamicPlaceholderContentInfo => {
    if (placeholderContent?.useRules) {
        const byAudienceDataField = placeholderContent?.rules?.by;
        if (byAudienceDataField == null) {
            return { isPersonalized: false };
        }

        const byAudienceVariationNum = countNonEmptyPlaceholderContentDynamicRules(placeholderContent);
        return { isPersonalized: byAudienceVariationNum > 0, byAudienceDataField, byAudienceVariationNum };
    }

    switch (placeholderType) {
        case CommonPlaceholderType.AVATAR:
        case CommonPlaceholderType.MEDIA: {
            const mediaPlaceholderContent = placeholderContent as MediaPlaceholderContent;
            const dynamicDataField = mediaPlaceholderContent?.noRules?.show.dynamic;
            return {
                isPersonalized: mediaPlaceholderContent?.noRules?.show.showType === PlaceholderShowType.Dynamic && dynamicDataField != null,
                dynamicDataField
            };
        }
        case CommonPlaceholderType.BUTTON: {
            // at the moment buttons does not use isPlaceholderDynamic in meaningful ways
            return { isPersonalized: false };
        }
        default: { // Text or Narration
            const textOrNarrationPlaceholderContent = placeholderContent as TextPlaceholderContent | NarrationPlaceholderContent;
            return {
                isPersonalized: isPlaceholderContentNoRulesWithPersonalization(textOrNarrationPlaceholderContent),
                dynamicDataField: getTextOrNarrationText(placeholderType, textOrNarrationPlaceholderContent?.noRules?.show)
                    ?.find((atom: DynamicTextAtom | DynamicRichTextAtom) => (atom?.content as DataElementDescriptor)?.localId)?.content as DataElementDescriptor
            };
        }
    }
};

function countNonEmptyPlaceholderContentDynamicRules(placeholderContent: PlaceholderContent) {
    const validRuleCases = (placeholderContent?.rules.cases as CaseType[])
        .filter(ruleCase => ruleCase.valueIds.length > 0);

    return validRuleCases.length;
}

export function isPlaceholderContentNoRulesWithPersonalization(placeholderContent: TextPlaceholderContent | NarrationPlaceholderContent): boolean {
    const showText = getTextOrNarrationText(placeholderContent?.placeholderType, placeholderContent?.noRules?.show) as (DynamicTextAtoms | DynamicRichTextAtoms);
    return Boolean(showText?.some(atom => !!(atom.content as DataElementDescriptor)?.localId));
}

const isRichTextFormattingApplied = <T>(richTextAtom: RichTextAtom<T>): boolean => {
    return richTextAtom.style.bold !== RichTextStyleSetting.UNSET || richTextAtom.style.italic !== RichTextStyleSetting.UNSET;
};

export const someAtomsRichTextFormattingApplied = <T>(richTextAtoms: RichTextAtom<T>[]): boolean => {
    return (richTextAtoms || []).some(isRichTextFormattingApplied);
};

export const isPlaceholderValueNotEmpty = (value: StaticTextAtoms | StaticRichTextAtoms | DynamicTextAtoms | DynamicRichTextAtoms) => {
    return (value || []).some(atom => !!atom.content);
};

export type ObjectViewBoxWithoutUnits = {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export type ObjectViewBox = {
    top: Coordinate;
    right: Coordinate;
    bottom: Coordinate;
    left: Coordinate;
}

export function getAvatarObjectViewBox(
    placeholder: { placeholderSettings?: PlaceholderSettings },
    avatar: {
        fullBodyCrop: ObjectViewBoxWithoutUnits;
        closeUpCrop: ObjectViewBoxWithoutUnits;
    }
): ObjectViewBox | null {
    if (!placeholder.placeholderSettings) return null;
    const cropSetting = getAvatarCropSetting(placeholder.placeholderSettings);

    const selectedPreset = cropSetting.value.crop;
    switch (selectedPreset) {
        case AvatarCropPreset.FULL_BODY:
            return {
                top: {
                    value: avatar.fullBodyCrop.top,
                    unit: PositionUnit.Pixel
                },
                right: {
                    value: avatar.fullBodyCrop.right,
                    unit: PositionUnit.Pixel
                },
                bottom: {
                    value: avatar.fullBodyCrop.bottom,
                    unit: PositionUnit.Pixel
                },
                left: {
                    value: avatar.fullBodyCrop.left,
                    unit: PositionUnit.Pixel
                }
            };
        case AvatarCropPreset.CLOSE_UP:
            return {
                top: {
                    value: avatar.closeUpCrop.top,
                    unit: PositionUnit.Pixel
                },
                right: {
                    value: avatar.closeUpCrop.right,
                    unit: PositionUnit.Pixel
                },
                bottom: {
                    value: avatar.closeUpCrop.bottom,
                    unit: PositionUnit.Pixel
                },
                left: {
                    value: avatar.closeUpCrop.left,
                    unit: PositionUnit.Pixel
                }
            };
        default:
            return null;
    }
}

export const AVATAR_OBJECT_FIT = ObjectFit.Contain;
export const AVATAR_OBJECT_POSITION = "center center";
type AvatarRawCropDefinition = {
    close_up_crop_bottom_px: number,
    close_up_crop_left_px: number,
    close_up_crop_right_px: number,
    close_up_crop_top_px: number,
    full_body_crop_bottom_px: number,
    full_body_crop_left_px: number,
    full_body_crop_right_px: number,
    full_body_crop_top_px: number
}
export type AvatarCropDefinitions = {
    fullBodyCrop: ObjectViewBoxWithoutUnits;
    closeUpCrop: ObjectViewBoxWithoutUnits;
}
export const groupAvatarCropDefinitions = (avatar: AvatarRawCropDefinition): AvatarCropDefinitions => {
    return {
        fullBodyCrop:  {
            top: avatar.full_body_crop_top_px,
            right: avatar.full_body_crop_right_px,
            bottom: avatar.full_body_crop_bottom_px,
            left: avatar.full_body_crop_left_px
        },
        closeUpCrop: {
            top: avatar.close_up_crop_top_px,
            right: avatar.close_up_crop_right_px,
            bottom: avatar.close_up_crop_bottom_px,
            left: avatar.close_up_crop_left_px
        }
    };
};
