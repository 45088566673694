export function isArrayOfObjects(value): boolean {
    return value && Array.isArray(value) && value.every((item) => typeof item === "object" && item !== null);
}

export function removeDuplicatesFromArrayByPropName(inputArr, prop): any[] {
    return inputArr.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
}

export type FilteredAndRemoved = {
    filteredArray: any[];
    remainingArray: any[];
};

/**
 * Takes in an array and splits to two arrays using toFilter and optional ident function
 * Returns an array of items that meet the filter criteria, and an array of the ones that don't.
 * @param origArray
 * @param toFilter
 * @param ident
 * @returns {
 *     filteredArray: array of the filtered items
 *     remainingArray: array of the remaining items (unfiltered)
 * }
 */
export const getFilteredAndRemainingItems = (origArray: any[], toFilter: any[], ident: (any) => any = (x) => x): FilteredAndRemoved => {
    let remainingArray: any[] = [];
    const filteredArray: any[] = origArray.filter((item: any) => {
        if (toFilter.includes(ident(item))) {
            return true;
        }
        else {
            remainingArray.push(item);
            return false;
        }
    });

    return {
        remainingArray,
        filteredArray
    };
};

export function convertArrayOfObjectsToObject<T>(arr: Array<T>, representativeField: keyof T): { [key: string]: T } {
    if (arr === undefined) return;
    return arr.reduce((acc, item: T) => {
        acc[item[representativeField as string]] = item;
        return acc;
    }, {});
}

export function convertArrayOfObjectsToObjectWithKeys<T>(arr: Array<T>, representativeFields: (keyof T)[], keyGenerator: (...fieldValues) => string): { [key: string]: T } {
    if (arr === undefined) return;
    return arr.reduce((acc, item: T) => {
        const values: any[] = representativeFields.map(fieldName => item[fieldName]);
        const key: string = keyGenerator(...values);
        acc[key] = item;
        return acc;
    }, {});
}

export function convertArrayOfObjectsToObjectWithKeysAndDuplicates<T>(arr: Array<T>, representativeFields: (keyof T)[], keyGenerator: (...fieldValues) => string): { [key: string]: Array<T> } {
    if (arr === undefined) return;
    return arr.reduce((acc, item: T) => {
        const values: any[] = representativeFields.map(fieldName => item[fieldName]);
        const key: string = keyGenerator(...values);
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});
}

/**
 * Split an array into chunks
 *
 * @param arr array
 * @param size chunk size
 */
export const chunk = <T>(arr: T[], size: number): T[][] => {
    return [...Array(Math.ceil(arr.length / size))].map<T[]>((_, i) => arr.slice(size * i, size + size * i)
    );
};

/**
 * Compare arrays (must have the exact same items in the exact same place) using <code>===</code> to compare items
 * @param a array 1
 * @param b array 2
 */
export const compareArrays = <T>(a: T[], b: T[]) => {
    if (a.length != b.length) {
        return false;
    }

    for (let i = 0 ; i < a.length ; i++) {
        if (a[i] != b[i]) {
            return false;
        }
    }

    return true;
};

/**
 * The method creates a shallow copy of a portion of a given array
 * An array that contains unique items based on a given key, will be returned.
 * @param arr of item of type T
 * @param key key of type T
 *
 */
export const removeDuplicatesByKey = <T>(arr: Array<T>, key: keyof T) => {
    const visited = new Set();
    return arr.filter((item) => {
        if (visited.has(item[key])) return false;
        visited.add(item[key]);
        return true;
    });
};
