import type { VlxRuleValueRepo } from "./logic";
import type { HorizontalAlignment } from "./editorPlaceholder";
import type { VideoAspectRatio, VideoQualityProfile, VolumeSettings } from "../external/videoSpec";
import type { NarrationProps } from "@sundaysky/vlx-types";


export enum VLXInputLogicType {
    TEXT = "text",
    MEDIA = "media",
    NARRATION = "narration",
    ANIMATION = "animation",
    COLOR = "color",
    COMPOUND = "compound",
    BOOLEAN = "boolean",
    NUMBER = "number"
}

export enum VLXAssetRepositories {
    CURATED = "curated",
    ANIMATION = "animation"
}

export enum VLXButtonShapeValues {
    DEFAULT = "default",
    RECTANGULAR = "rectangular",
    ROUNDED = "rounded",
    PILL = "pill"
}

export enum MinimumContentEndBase {
    ClipBegin = "clipBegin",
    ClipEnd = "clipEnd",
}

export type MinimumContentEnd = {
    base: MinimumContentEndBase;
    value: number; // seconds
};

export type PlayAt = {
    action: "play" | "narrate";
    offset: number; // seconds
}

type VLXStyle = {
    ["@styleProps"]: any;
}

export type VLXMediaStyle = VLXStyle & {
    minContentEnd?: MinimumContentEnd;
    playAt?: PlayAt;
}

export interface VLXInputLogic {
    type: VLXInputLogicType;
    rules: any[]; // vlx rule
    cardinality?: string;
    ["@style"]?: VLXStyle;
}

export interface VLXInputMediaLogic extends VLXInputLogic {
    fallback?: VLXInputMediaLogic;
    transcript?: VLXInputLogic;
    fallbackTranscript?: string;
    ["@style"]?: VLXMediaStyle;
}

export interface VLXValueInputLogic {
    type: string;
    value: any; // vlx value
}

export interface VLXVariable {
    name: string;
    value: VLXInputLogic;
}

export interface VLXProgram {
    storyName: VLXInputLogic;
    variables?: any[];
}

export interface VLXLineupScene {
    animation: any;
    id: string;
    name: string;
    data: VLXLineupSceneData;
    valid: VLXInputLogic | boolean;
    variables?: Array<{ name: string; value: VLXInputLogic }>;
}
export interface VLXLineupElement {
    name: string;
    scene: VLXLineupScene;
}

export type VLXLineupSceneData = Record<string, VLXInputLogic> & {
    "descriptive-transcript": VLXInputLogic;
    Narrations: Record<string, VLXLineupSceneNarration>;
    isFirstScene: "true" | "false";
    isLastScene: "true" | "false";
    hasMediaBackground: "true" | "false";
    buttonShape: VLXButtonShapeValues;
    buttonAlignment: HorizontalAlignment;
    minDuration?: number;
} & SceneTransition;

export type VLXLineupSceneNarration = {
    type: VLXInputLogicType.NARRATION;
    narrationId: string;
    transcript: VLXInputLogic;
    ["@style"]?: {
        ["@styleProps"]: any;
    };
    isRealtimeNarration?: boolean;
    fallbackTranscript?: string;
    ["@narrationProps"]?: Omit<NarrationProps, "rate"> & { rate: VLXInputLogic };
};

export type SceneTransition = {
    hasTransition?: "true" | "false";
    hasEntry?: "true" | "false";
    noExit?: "true" | "false";
    transitionType?: TransitionType;
};

export enum TransitionType {
    LINEAR_WIPE_V1 = "LinearWipe_v1",
    LINEAR_WIPE_V2 = "LinearWipe_v2",
    DIAGONAL_V1 = "Diagonal_v1",
    DIAGONAL_V2 = "Diagonal_v2",
    BARN_DOORS_V1 = "BarnDoors_v1",
    BARN_DOORS_V2 = "BarnDoors_v2",
    RADIAL_WIPE_V1 = "RadialWipe_v1",
    RADIAL_WIPE_V2 = "RadialWipe_v2"
}

export enum ObjectFit {
    Cover = "cover",
    Contain = "contain",
    Fill = "fill"
}

export type ObjectPosition =
    | "left top"
    | "left center"
    | "left bottom"
    | "center top"
    | "center center"
    | "center bottom"
    | "right top"
    | "right center"
    | "right bottom";

// todo: expand as we go...
export enum MediaAnimationName {
    BreathingZoomIn = "--ssky-breathing-zoom-in"
}

// source: https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/1754791990/fontVariantCaps
export enum FontVariantCaps {
    Normal = "normal",
    SmallCaps = "small-caps",
    AllSmallCaps = "all-small-caps"
}

// source: https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/1748992028/textTransform
export enum TextTransform {
    None = "none",
    Uppercase = "uppercase",
    Lowercase = "lowercase",
    Capitalize = "capitalize",
    CapitalizeFirstLetter = "capitalize-first-letter"
}

// source: https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/1691025519/textAlign
export enum TextHorizontalAlign {
    Left = "left",
    Center = "center",
    Right = "right",
}

// source: https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/1691025536/verticalAlign
export enum TextVerticalAlign {
    Top = "top",
    Middle = "middle",
    Bottom = "bottom",
}

export type VLXForegroundMediaContent = {
    rules: Array<{
        value: string | VlxRuleValueRepo;
    }>;
    type: "media" | "audio"
};

export type VLXForeground = {
    content: {
        "@type": "media";
        source: VLXForegroundMediaContent;
        ["@styleProps"]: any;
    };
    audioFadeOutDuration?: number;
};

export type VLXBackgroundMediaContent = {
    rules: Array<{
        value: string | VlxRuleValueRepo;
    }>;
    type: "media" | "audio"
};

export type VLXBackground = {
    content: {
        "@type": "media";
        source: VLXBackgroundMediaContent;
        ["@styleProps"]: any;
    };
    audioFadeOutDuration?: number;
};

// source https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/2500788332/filter
export type FloodFilter = {
    name: "--ssky-flood";
    floodColor: string; // 6-digit hexadecimal string
    floodOpacity?: number; // 0-1, clamped, default 1
}

export type Filter =
/* add more as they come */
    | FloodFilter
    ;

export type Filters = Array<Filter>;

export type VLXSettings = {
    projectid: string;
    analytics: {
        story: string;
        storyid: string;
        videoName?: string;
    };
    output: {
        formats: {
            video: {
                format: "m3u8";
            };
        };
        audioMixing?: {
            sfxVolume: VolumeSettings;
        };
        videoAspectRatio: VideoAspectRatio;
        videoQualityProfile: VideoQualityProfile;
    }
    settings?: {
        enableChaptering?: boolean;
    };
    foregrounds?: VLXForeground[];
    //legacy background
    background?: VLXBackgroundMediaContent;
    //new backgrounds - support for style props and array of backgrounds (rendered on top of each other, first to last)
    backgrounds?: VLXBackground[];
};
