import type { SnapshotAsset } from "./snapshot";

export enum MediaTypes {
    Video = "video",
    Image = "image",
    Audio = "audio",
    Text = "text",
    Application = "application"
}

export enum AssetTypes {
    animation = "animation",
    curated = "curated",
    decisionTable = "decision_table",
    inlineStory = "inlineStory",
    mappingTable = "mapping_table",
    narrator = "narrator",
    program = "program",
    recording = "recording",
    story = "story",
    scene = "scene",
    wireframesUI = "wireframesUI"
}

export enum RecordingOrigin {
    Builder = "builder",
    Creative = "creative"
}

export enum NarrationRecordingErrorReason {
    // similar to  GqlClientNarrationRecordingErrorReason, but with spaces
    BADINTONATION = "Bad Intonation",
    CORRUPTED = "Corrupted",
    TEXTDOESNOTMATCH = "Text does not match",
    OTHER = "Other"
}

export interface Asset {
    activeVersion: number;
    archived?: boolean;
    inUse: boolean;
    assetId: string;
    createdTime: number;
    deleted?: boolean;
    description?: string;
    downloadSelf?: string;
    format?: string;
    height?: number;
    location?: string;
    mediaType: MediaTypes;
    mediaSubType?: string;
    name: string;
    projectId?: string;
    stageAssetItem?: SnapshotAsset;
    stageVersion?: number;
    status?: string;
    size?: number;
    title?: string;
    thumbnailLocation?: string;
    type: AssetTypes;
    updatedTime: number;
    usedInScenes?: string[];
    uploadedBy?: string;
    version: number;
    width?: number;
    history?: { Items: Partial<AssetHistoryItem>[] };
    activeVersionItem?: any;
    origin?: RecordingOrigin;
    isPostgresAsset?: boolean; // temp. to let the client know where to get the preview/thumbnail/download URL from
}

export interface ConvertedGqlAsset extends Omit<CuratedAsset, "location" | "thumbnailLocation" | "downloadSelf" | "mediaSubType" | "uploadedBy"> {
    id: string;
    url: string;
    thumbnailUrl: string;
}

export interface Narrator extends Asset {
    type: AssetTypes.narrator;
    graphQLId?: string;
    decisionTablesLoadTaskId: string;
    decisionTablesLocation: string;
}

export type ConvertedGqlNarrator = Omit<Narrator, "version" | "activeVersion" | "inUse" | "deleted" | "mediaType" | "filename"
    | "thumbnailLocation" | "downloadSelf" | "mediaSubType" | "uploadedBy">;


export interface ConvertedGqlAssetHistoryItem extends Omit<AssetHistoryItem,
    "downloadSelf" | "lastUsedInSnapshotName" | "lastUsedInSnapshotNumber" | "mediaType" | "name" | "projectAssetId" | "recordingErrorReason" | "thumbnailLocation" | "type"> {
    url?: string,
    thumbnailUrl?: string,
}

export interface RecordingAsset extends Asset {
    type: AssetTypes.recording,
    mediaType: MediaTypes.Audio,
    RFR: number;
    recordingStatus?: string;
    creativeRFR?: number;
    graphQLId?: string;
    graphQLUpdated?: string;
    recordingErrorReason?: NarrationRecordingErrorReason;
    narrationMetadata: {
        text: string;
        sceneName: string;
        narrationPart: string;
        contentSet?: string;
    };
    filename: string;
    history?: { Items: AssetHistoryItem[] };
    updated?: string; // preserve updated from postgres
    activeVersionId?: string;
    activeVersionUpdated?: string;
    url?: string;
}

export interface ConvertedGqlRecordingAsset extends RecordingAsset {
    narrationId: string,
}

export interface CuratedAsset extends Asset {
    type: AssetTypes.curated;
    filename: string;
}

export interface AnimationAsset {
    type: AssetTypes.animation;
}

export type AssetHistoryItem = {
    id: string;
    filename?: string;
    format?: string;
    height?: number;
    lastUsedInSnapshotName?: string;
    lastUsedInSnapshotNumber?: number;
    location?: string;
    mediaSubType?: string;
    mediaType: MediaTypes;
    name?: string;
    projectAssetId?: string;
    size?: number;
    thumbnailLocation?: string;
    type: AssetTypes;
    updateTime: number;
    uploadedBy: string;
    version: number;
    width?: number;
    recordingStatus?: string;
    downloadSelf: string;
    recordingErrorReason: NarrationRecordingErrorReason;
    isPostgresAsset?: boolean; // temp. to let the client know where to get the preview/thumbnail/download URL from
};

export type RecordingDocItem = {
    id: string;
    createdTime: number | string; // timestamp from dynamo / ISOString from graphql
    createdBy: string;
    totalRecordingFiles: number;
    audioFileName: string;
    scriptFileName: string;
    folderLocation: string;
    projectId?: string; // recording docs from dynamo
    scriptFileDownloadSelf?: string; // recording docs from dynamo
    audioFileDownloadSelf?: string; // recording docs from dynamo
    audioFileUrl?: string; // recording docs from graphql
    scriptFileUrl?: string; // recording docs from graphql
    isPostgresAsset?: boolean; // temp. to let the client know where to get the download URL from
};

export type AnimationItem = {
    type: AssetTypes.animation
    title: string
    activeVersion: number
    assetId: string
    name: string
    description?: string
    updatedTime: number
    projectId: string
    history?: { Items: AnimationHistoryItem[] }
}

export type AnimationHistoryItem = {
    type: AssetTypes.animation
    version: number
    updateTime: number
    uploadedBy: string
    comment?: string
    aeFileName?: string
}

export type MediaDimensions = {
    width: number
    height: number
}

export type MediaRotation = 0 | 90 | -90 | 180 | -180 | 270;

export type EditorMediaMetadata = {
    version: number;
    size: number;
    format: string;
    mediaDimensions: MediaDimensions;
    mediaType: EditorLibraryMediaType;
    duration?:number;
    rotation?: MediaRotation;
    eTag?: string;
    contentType?: string;
}

export enum EditorLibraryMediaType {
    Video = "video",
    Image = "image"
}

export type OriginalEditInformation = {
    version: number;
    framesLocation: string;
    framesRows: number;
    framesCols: number;
    framesNumber: number;
};

export type FramesImageData = {
    framesLocation: string;
    framesNumber: number;
    framesCols: number;
    framesRows: number;
};

export type ProcessingVideoInfo = {
    version: number;
    interval: { start: number; end: number };
    croppedArea: {
        x: number;
        y: number;
        width: number;
        height: number;
    }
    flippedHorizontally: boolean;
    flippedVertically: boolean;
};

export type ProcessingImageInfo = {
    version: number;
    croppedArea: {
        x: number;
        y: number;
        width: number;
        height: number;
    }
    flippedHorizontally: boolean;
    flippedVertically: boolean;
};

export enum GettySortOrder {
    BestMatch = "best_match",
    MostPopular = "most_popular",
    Newest = "newest",
    Oldest = "oldest",
    Random = "random"
}

export enum GettyOrientation {
    Horizontal = "horizontal",
    Vertical = "vertical",
}
