function is(x, y): boolean {
    if (x === y) {
        return x !== 0 || y !== 0 || 1 / x === 1 / y;
    }
    else {
        return x !== x && y !== y;
    }
}

// Copied from react-redux utils
export function shallowEqual(objA, objB): boolean {
    if (is(objA, objB)) return true;

    if (typeof objA !== "object" || objA === null || typeof objB !== "object" || objB === null) {
        return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) return false;

    for (let i = 0; i < keysA.length; i++) {
        if (!Object.hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
            return false;
        }
    }

    return true;
}

function simpleIsEqual(a, b): boolean {
    return a === b;
}

export function equalArraysRegardlessOrder(array1, array2, isEqual = simpleIsEqual): boolean {
    if (array1 === array2) return true;
    if (array1 === null || array2 === null) return false;
    if (array1.length !== array2.length) return false;

    let copy1 = [...array1].sort();
    let copy2 = [...array2].sort();

    for (let i = 0; i < copy1.length; ++i) {
        if (!isEqual(copy1[i], copy2[i])) return false;
    }

    return true;
}

export function XOR(a: any, b: any): boolean {
    return Boolean(Number(Boolean(a)) ^ Number(Boolean(b)));
}


export function sameArrays(array1: ReadonlyArray<any>, array2: ReadonlyArray<any>, equals?: (a: any, b: any) => boolean) {

    if (!array1 || !array2) {
        return false;
    }

    const defaultEquals = (a, b) => a === b;
    const equalsFn = equals || defaultEquals;

    return array1.length === array2.length && array1.reduce((acc, item, index) => acc && equalsFn(item, array2[index]), true);
}
