export function mapNumToLetter(int: number): string {
    // 0 => A, 1 => B, etc...
    return String.fromCharCode("A".charCodeAt(0) + int);
}

export function mapLetterToNum(c: string): number {
    // A => 0, B => 1, etc...
    return c && c.charCodeAt(0) - "A".charCodeAt(0);
}

export function toSentenceCase(string: string): string {
    return typeof string === "string" ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : string;
}

export function isUuid(string): boolean {
    if (typeof string !== "string") return false;
    const uuidRegex = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/i;
    return uuidRegex.test(string);
}

export function excludeKeysFromObject(keysToExclude, object): object {
    let objectKeys = Object.keys(object);
    if (objectKeys) {
        let filteredObjectKeys = objectKeys.filter((ok) => !keysToExclude.includes(ok));
        return filteredObjectKeys.reduce((acc, newObjectKeys) => {
            return {
                ...acc,
                [newObjectKeys]: object[newObjectKeys]
            };
        }, {});
    }
}

export function mapRange(x: number, in_min: number, in_max: number, out_min: number, out_max: number): number {
    return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}


export const convertMapToPlainObject = <K extends string | number, V>(map: Map<K, V>): Record<K, V> => {
    return Object.assign({}, ...[...map.entries()].map(([k, v]) => ({ [k]: v })));
};

export const convertPlainObjectToMap = (object: any): Map<any, any> => {
    return new Map(Object.entries(object));
};

export const removeEmptyEntriesFromObject = (obj: object, strictMode: boolean = false): object => {
    if (obj === undefined) return;
    return JSON.parse(JSON.stringify(obj), (_, value) => {
        return value === null || value === undefined || (!strictMode && value === "") || (!strictMode && Array.isArray(value) && value.length === 0) ? undefined : value;
    });
};

export const unionSets = (...iterables): Set<any> => {
    const set = new Set();
    for (let iterable of iterables) {
        for (let item of iterable) {
            set.add(item);
        }
    }
    return set;
};

const reAlpha = /[^a-zA-Z]+/g;
const reNumeric = /[^0-9]+/g;
export function sortAlphaNum(a: string, b: string): number {
    const aA = a.replace(reAlpha, "-").toLocaleLowerCase();
    const bA = b.replace(reAlpha, "-").toLocaleLowerCase();
    if (aA === bA) {
        const aN = parseInt(a.replace(reNumeric, ""), 10);
        const bN = parseInt(b.replace(reNumeric, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
    }
    else {
        return aA > bA ? 1 : -1;
    }
}

export const isObject = (value: any): boolean => {
    return value && typeof value === "object" && !Array.isArray(value);
};

export const isAnEmptyObject = (value: any): boolean => {
    return isObject(value) && Object.entries(value).length === 0;
};

/*
Replace a value in an immutable object
 */
export function immutablyReplaceValue(obj: object | undefined, path: string[], value: any, merge?: boolean): any {
    obj = (obj && typeof obj === "object") ? obj : undefined;

    if (path.length === 0) {
        if (merge && typeof value === "object") {
            return { ...obj, ...value };
        }
        return value;
    }
    const part = path.shift(); // opposite of pop()

    return {
        ...obj,
        [part]: immutablyReplaceValue(obj && obj[part], path, value, merge)
    };
}

export const regexForUuid: string = "[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}";


export const clearObj = <T extends object>(obj: T): Partial<T> => {
    return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] !== undefined) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});

};

export const replaceSpecialChar = (value: string, charToReplaceWith: string): string => {
    return value?.replace(/[^A-Za-z0-9]+/g, charToReplaceWith) ?? "";
};

export const escapeRegExp = (str :string) : string => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const getUniqueNameWithRunningSuffix = (newName: string, allNames: string[]) => {
    const suffixes: number[] = allNames
        .map(name => {
            const matches = name.toLowerCase().match(new RegExp(escapeRegExp(newName.toLowerCase()) + "(\\d*)$"));
            const suffix = Number(matches?.[1]);
            return !Number.isNaN(suffix) ? suffix : null;
        })
        .filter(suffix => suffix != null);

    return suffixes.length > 0
        ? newName + (Math.max(...suffixes) + 1).toString()
        : newName;
};

const getUniqueNameWithRunningCounter = (requestedName: string, otherNames: string[], isFileName: boolean): string => {
    const loweredCaseRequestedName: string = requestedName.toLowerCase();
    const indexOfIdenticalName: number = otherNames
        .map(item => item.toLowerCase())
        .indexOf(loweredCaseRequestedName);

    if (indexOfIdenticalName >= 0) {
        const { name, suffix } = isFileName ? getFileNameAndSuffix(requestedName) : { name: requestedName, suffix: "" };
        const filesWithSameNameRegex: RegExp = new RegExp(`^${escapeRegExp(name)}( )\\((\\d+)\\)(${suffix})$`, "i");
        const highestFileIndex: number = otherNames
            .map(name => {
                const nameMatch = name.match(filesWithSameNameRegex);
                return nameMatch !== null ? Number(nameMatch[2]) : null;
            })
            .filter(item => item !== null)
            .sort(function(a, b) {
                return a - b;
            })
            .pop() || 0;
        return `${name} (${highestFileIndex + 1})${suffix}`;
    }
    else {
        return requestedName;
    }
};

export const getUniqueFilenameWithRunningCounter = (requestedName: string, otherNames: string[]): string => {
    return getUniqueNameWithRunningCounter(requestedName, otherNames, true);
};

export const getUniqueStringWithRunningCounter = (requestedName: string, otherNames: string[]): string => {
    return getUniqueNameWithRunningCounter(requestedName, otherNames, false);
};

export type FileNameAndSuffix = {
    name: string,
    suffix: string,
    separatorIndex: number
};

export const getFileNameAndSuffix = (filename: string): FileNameAndSuffix => {
    const separatorIndex: number = filename.lastIndexOf(".");
    const name = separatorIndex > -1 ? filename.substr(0, separatorIndex) : filename;
    const suffix = separatorIndex > -1 ? filename.substring(separatorIndex, filename.length) : "";

    return {
        name,
        suffix,
        separatorIndex
    };
};

export const areAllObjectPropertiesEmpty = (obj: object): boolean => {

    if (!obj) return true;
    return !Object.keys(obj).some(key => {
        if (obj[key]) {
            return true;
        }
    });
};

export const isNumber = (value: any): boolean => {
    return Number.isFinite(value);
};

export const stableSort = <T>(array: T[], compareFn: (a: T, b: T) => number): T[] => {
    // Create an array of objects that contain the element and its original index
    const indexedArray = array.map((value, index) => ({ value, index }));

    // Sort the indexed array, using the comparison function and original index
    indexedArray.sort((a, b) => {
        const cmpResult = compareFn(a.value, b.value);
        return cmpResult === 0 ? a.index - b.index : cmpResult;
    });

    // Extract the sorted values
    return indexedArray.map(item => item.value);
};
export const getFirstWord = (input: string): string => {
    if (!input.trim()) return ""; // Handle empty or whitespace-only input

    // Find the index of the first delimiter (space, comma, or hyphen)
    const delimiterIndex = input.search(/[\s,-]/);

    // If no delimiter is found, return the whole string
    return delimiterIndex === -1 ? input.trim() : input.substring(0, delimiterIndex).trim();
};
