/**
 * Values are close to YouTube's upload recommendation:
 *
 * https://support.google.com/youtube/answer/1722171?hl=en#zippy=%2Cbitrate
 *
 */

const BPP_FOR_4K = 0.1; // equivalent to 2,592,000 bytes per second for a 25fps 4K video
const BPP_FOR_1080p = 0.15; // equivalent to 981,000 bytes per second for a 25fps 1080p video
const BPP_FOR_720p = 0.2; // equivalent to 576,000 bytes per second for a 25fps 720p video

const HD_PIXEL_COUNT = 1920 * 1080;
const SD_PIXEL_COUNT = 1280 * 720;

export const calculateRecommendedVideoBitrate = (width: number, height: number, framerate: number): number => {
    if (!width || !height || !framerate) {
        return undefined;
    }

    const pixelCount = width * height;
    const pixelPerSecond = pixelCount * framerate;
    if (pixelCount >= HD_PIXEL_COUNT) {
        return BPP_FOR_4K * pixelPerSecond;
    }
    else if (pixelCount >= SD_PIXEL_COUNT) {
        return BPP_FOR_1080p * pixelPerSecond;
    }
    return BPP_FOR_720p * pixelPerSecond;
};
