import type { DataElement } from "./types/dataElement";
import { CompatibilityVersions, DataElementContentTypes, DataElementOrigins, DataElementStatuses } from "./types/dataElement";

/**
 * This is the studio API version.
 * If you want to force client update, advance this number
 */
export const studioApiVersionHeaderName = "studio-api-version";
export const studioApiVersion: number = 50.0;

export const studioClientVersionHeaderName = "studio-client-version";

// internal Server-timing headers
export const X_SSKY_SERVER_TIMING_HEADER = "x-ssky-server-timing";
export const X_SSKY_PAYLOAD_SIZE_HEADER = "x-ssky-payload-size";

// Instance-id header
export const X_SSKY_INSTANCE_ID_HEADER = "x-ssky-instance-id";

const fileTypesWhiteListDef = [
    { type: "audio", ext: "mp3", mime: "audio/mpeg" },
    { type: "audio", ext: "wav", mime: "audio/vnd.wave" },
    { type: "image", ext: "jpeg", mime: "image/jpeg" },
    { type: "image", ext: "jpg", mime: "image/jpeg" },
    /* exif/jfif extensions fix a bug: https://sundaysky.atlassian.net/browse/SD-3690
    ToDo: delete exif/jfif after refactoring the `isImage` function in `MediaContainer.tsx`. It should use the `metadata` field to distinguish between image and video PHs */
    { type: "image", ext: "exif", mime: "image/jpeg" },
    { type: "image", ext: "jfif", mime: "image/jpeg" },
    { type: "image", ext: "png", mime: "image/png" },
    { type: "image", ext: "gif", mime: "image/gif" },
    { type: "video", ext: "mov", mime: "video/quicktime" },
    { type: "video", ext: "flv", mime: "video/x-flv" },
    { type: "video", ext: "mp4", mime: "video/mp4" },
    { type: "video", ext: "webm", mime: "video/webm" },
    { type: "video", ext: "m4v", mime: "video/x-m4v" },
    { type: "text", ext: "txt", mime: "text/plain" },
    { type: "text", ext: "json", mime: "application/json" },
    { type: "zip", ext: "gz", mime: "application/gzip" },
    { type: "font", ext: "ttf", mime: "font/ttf" },
    { type: "font", ext: "otf", mime: "font/otf" },
    { type: "font", ext: "woff", mime: "font/woff" },
    { type: "email", ext: "msg", mime: "application/vnd.ms-outlook" },
    { type: "email", ext: "eml", mime: "message/rfc822" },
    { type: "slides", ext: "pptx", mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
    { type: "text", ext: "pdf", mime: "application/pdf" }
];

export const fileTypesWhitelist = fileTypesWhiteListDef.map((def) => def.mime);

export const fileTypesExcludeFromParse = fileTypesWhiteListDef.filter((def) => def.type === "text").map((def) => def.mime);

export const mimeTypesByMediaType = fileTypesWhiteListDef.reduce((acc, typeDef) => {
    if (acc[typeDef.type]) {
        acc[typeDef.type].push(typeDef.mime);
    }
    else {
        acc[typeDef.type] = [typeDef.mime];
    }
    return acc;
}, {});

const audioExt = fileTypesWhiteListDef.filter((def) => def.type === "audio").map((def) => def.ext);
const imageExt = fileTypesWhiteListDef.filter((def) => def.type === "image").map((def) => def.ext);
const videoExt = fileTypesWhiteListDef.filter((def) => def.type === "video").map((def) => def.ext);

export const supportedExtensions = fileTypesWhiteListDef.map(def => def.ext);

const prependDot = (ext: string): string => `.${ext}`;

export const fileExtByMediaType = {
    audio: audioExt.map(prependDot),
    image: imageExt.map(prependDot),
    video: videoExt.map(prependDot),
    csv: [".csv"],
    email: [".eml", ".msg"],
    slides: [".pptx"],
    json: [".json"]
};

const rawRecordingMaxUploadSizeMB = 100;

const audioMaxUploadSizeMB = 25;

const imageMaxUploadSizeMB = 10;

const videoMaxUploadSizeMB = 100;

const csvMaxUploadSizeMB = 100;

const customAvatarInputMaxUploadSizeMB = 500;

export const bulkUploadFilesLength = 100;

export const rawRecordingMaxUploadSize = rawRecordingMaxUploadSizeMB * 1024 * 1024;

export const audioMaxUploadSize = audioMaxUploadSizeMB * 1024 * 1024;

export const imageMaxUploadSize = imageMaxUploadSizeMB * 1024 * 1024;

export const videoMaxUploadSize = videoMaxUploadSizeMB * 1024 * 1024;

export const csvMaxUploadSize = csvMaxUploadSizeMB * 1024 * 1024;

export const customAvatarInputMaxUploadSize = customAvatarInputMaxUploadSizeMB * 1024 * 1024;

export const maxUploadFileSize: number = Math.max(audioMaxUploadSize, imageMaxUploadSize, videoMaxUploadSize, rawRecordingMaxUploadSize, customAvatarInputMaxUploadSize);

export const narrationPartVariationsLimit = 15000;

export const defaultNarratorId = "default_narrator"; // todo - this is until we support multiple narrators

export const urlPlaceHolderTypes = ["image", "video"];

export const adsDataElements: DataElement[] = [
    {
        name: "audience",
        displayName: "audience",
        type: DataElementContentTypes.String,
        description: "The audience to which the user belongs",
        origin: DataElementOrigins.Feed,
        compatibilityVersion: CompatibilityVersions.IdSupport,
        valueSet: [],
        useValueSet: true,
        status: DataElementStatuses.Pending
    }
];

export const wireframesUIAssetName = "draft";

export const sceneAssetType = "scene";

export const storyAssetType = "story";

export const storyInlineAssetType = "inlineStory";

export const wireFramesAssetType = "wireframesUI";

export const recordingAssetType = "recording";

export const placeholderType = {
    onscreen: "placeholders",
    narration: "NarrationParts",
    parameter: "parameters"
};

export const onscreenTypes = {
    Image: "image",
    Video: "video",
    Text: "text",
    Hotspot: "hotspot"
};

export const entityType = {
    placeholders: "placeholders",
    groups: "groups",
    prioritizedLists: "prioritizedLists"
};

export const parameterType = {
    parameter: "parameter",
    color: "color"
};

export enum LogicContainers {
    Master= "master",
    Analytics = "analytics",
    Derived = "derived",
    DecisionPoint = "decisionPoint",
    Story = "story",
    Soundtrack = "soundtrack",
    BackgroundAsset = "backgroundAsset",
    Program = "program",
    StorySelection = "storySelection",
    VideoSettings = "videoSettings"
}

export const programTypes = { CRM: "CRM", ADS: "Ads" };

export const legacyIllegalFileFormatMsg = `Illegal file format was uploaded. You may upload plain text, video (${videoExt.join(", ")}), audio (${audioExt.join(", ")}) or image (${imageExt.join(
    ", "
)}) files`;

export const illegalFileFormatMsg = `Illegal file format was uploaded. You may upload video (${videoExt.join(", ")}), audio (${audioExt.join(", ")}) or image (${imageExt.join(
    ", "
)}) files`;

export const fileTypeCheckRequiredBufferSize = 4100;

export const rawRecordingSizeExceededMsg = `Recording size limit exceeded. You may upload recording files up to ${rawRecordingMaxUploadSizeMB}MB`;

export const audioSizeExceededMsg = `Audio size limit exceeded. You may upload audio files up to ${audioMaxUploadSizeMB}MB`;

export const imageSizeExceededMsg = `Image size limit exceeded. You may upload image files up to ${imageMaxUploadSizeMB}MB`;

export const videoSizeExceededMsg = `Video size limit exceeded. You may upload video files up to ${videoMaxUploadSizeMB}MB`;

export const createVersionExcludedAssetTypes = [storyInlineAssetType, storyAssetType, sceneAssetType];

export const programDefaultDynamoMigrationStatusFields: { [key: string]: "notMigrated" | "testing" | "done" } = {
    most_entities: "done",
    assets: "done",
    recording_docs: "done",
    animations: "done",
    narrators: "done",
    lifecycle_history: "done",
    data_tables: "done"
};

export const CC_LIBRARY_ID = "ffffffff-ffff-ffff-ffff-ffffffff0000";

export const EDITOR_STORY_NAME = "story1";

export const X_SSKY_ACCOUNT_DATA_VERSION_HEADER = "x-ssky-account-data-version";

export enum EditorProjectIdsPrefix {
    LivePreview = "editor-live-preview",
    Preview = "editor-preview"
}

export const ACCOUNT_LIBRARY_PROGRAM_NAME = "Account Library";

export const EDITOR_BRAND_DEFAULT_NAME = "Untitled";

export const EDITOR_ACCOUNT_ID = "12345678901Editor";
export const CREATIVE_INFRA_ACCOUNT_ID = "12345678901CreativeInfra";

/*
List of accounts that will:
1. See animated wireframes at "test" lifecycle stage.
*/
export const CC_TEST_ACCOUNT_IDS = new Set([
    EDITOR_ACCOUNT_ID,
    CREATIVE_INFRA_ACCOUNT_ID
]);


/*
List of accounts that will:
1. Use latest, not yet deployed cc library version.
2. Use "draft" animated wireframe versions.
*/
export const CC_STAGING_ACCOUNT_IDS = [
    EDITOR_ACCOUNT_ID,
    CREATIVE_INFRA_ACCOUNT_ID
];

export const CC_LATEST_LIBRARY_VERSION_ID = "eeeeeeee-eeee-eeee-eeee-eeeeeeeeeeee";
export const CC_ACTIVE_LIBRARY_VERSION_ID = "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa";

export const DEMO_LANDING_PAGE_URL = "https://platform-demo.sundaysky.com/video/index.html";

export const BUTTON_BORDER_COLOR_NAME = "buttonBorder_color";
export const BUTTON_TEXT_COLOR_NAME = "buttonText_color";
export const BUTTON_FILL_COLOR_NAME = "button_color";
/*
NPS Survey unique ID
 */
export const NpsSurveyId = "4775168";

export const ANALYTICS_DEMO_ACCOUNTS = new Set (
    [
        "0015a000037l1cjAAA", "0015a000037l1bqAAA", "0015a000037l1atAAA", "0015a000037l1aYAAQ", "0015a000037l1asAAA", "0015a000037l1bCAAQ", "0015a000037l1a5AAA", "0015a000037l1brAAA",
        "0015a000037l1aKAAQ", "0015a000037l1aJAAQ", "0015a000037l1bEAAQ", "0015a000037l1c5AAA", "0015a000037l1bsAAA", "0015a000037l1bDAAQ", "0015a000037l1bbAAA", "0015a000037l1cPAAQ",
        "0015a000031AWmKAAW", "0015a000037l1ZfAAI", "0015a000037kL9OAAU", "0015a000037l1a6AAA", "0015a000037kKyQAAU", "0015a000037l1a9AAA", "0015a000037kKykAAE", "0015a000037kAcHAAU",
        "0015a000037kL99AAE", "0015a0000344BmIAAU", "0015a0000343ht4AAA", "0015a000037l1ZVAAY", "0015a000037kL8kAAE", "0015a000037l1ToAAI", "0015a000037kKz4AAE", "0015a000037kL8BAAU",
        "0015a000037kLBeAAM", "0015a000037kL8VAAU"
    ]);

export const M2_Logo_Aspect_Ratio_Value = { aspectRatio: 3, objectFit: "contain" };

export const MEDIA_LIBRARY_MIN_VIDEO_DURATION = 0;
export const MEDIA_LIBRARY_MAX_VIDEO_DURATION = 600;

export const GETTY_PAGE_SIZE = 50;
export const ACQUIA_PAGE_SIZE = GETTY_PAGE_SIZE;

export const DEFAULT_NARRATOR_NAME = "Jessica";

export const MEDIA_OVERLAY_UNSUPPORTED_LAYOUT_NAME = "X4_Layout7_M2";

// silence duration in sec added to the beginning and the end of the avatar soundtrack
export const AVATAR_SILENCE_DURATION = 0.2;

export const STATIC_DEFAULT_PROFILE_NAME: string = "Untitled";
