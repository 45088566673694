// see dynamoDBAccess getProjectSnapshot
export type SnapshotAsset = {
    name: string,
    type: string,
    location?: string,
    version: number,
    url?: string, // for curated assets, data tables and narration recording assets
    thumbnailUrl?: string, // for curated assets of type image/video from gql
    decisionTablesLocation?: string, // for data tables and narrators
    downloadSelf?: string, // only for dynamo/REST flows
    RFR?: number, // only for narration recording
    filename?: string, // only for narration recording
    recordingStatus?: string // only for narration recording
};

export type Snapshot = {
    assets?: SnapshotAsset[];
    createdBy: string;
    createdOn: number;
    snapshotComment: string;
    snapshotName: string;
    snapshotNumber: number;
    validationData: any;
    graphQLId?: string;
    snapshotSource?: SnapshotSource;
    graphQLParentProgramVersionId?: string;
}
export enum SnapshotSource {
    DYNAMO = "Dynamo",
    POSTGRES = "Postgres"
}