import type { AuthServiceType } from "@sundaysky/smartvideo-hub-auth";
import type { User, UserData } from "./userData";
import type { STS } from "aws-sdk";
import type { PageConfiguration } from "@sundaysky/landing-page-skeleton-types";
import type { SskyErrorCode } from "../errors";


export enum AnalyticsOperation {
    Create = "create",
    CreateOrUpdate = "createOrUpdate",
    Delete = "delete",
    Query = "query",
    Update = "update"
}

export type ResponseStatusType = {
    analyticsErr?: any;
    hasErrorInAnalytics?: boolean;
    analyticsOperation?: AnalyticsOperation
};

/**
 * GET "/conf"
 */
export type CognitoAuthServiceConfig = {
    clientId: string
    userPoolId: string
    identityPoolId: string
}
export type OktaAuthServiceConfig = {
    clientId: string
    issuer: string
}
export type Auth0ServiceConfig = {
    clientId: string;
    issuer: string;
}
export type GetConfResponseAuthServices = {
    cognito: CognitoAuthServiceConfig;
    okta?: OktaAuthServiceConfig;
    auth0?: Auth0ServiceConfig;
}
export type GetConfResponse = {
    authServices: GetConfResponseAuthServices,
    gaTrackingId: string,
}

/**
 * GET /auth
 */
export type GetUserAuthServiceQuery = {
    username: string
}
export type GetUserAuthServiceResponse = {
    service: AuthServiceType,
    isFirstLogin: boolean,
    isUserDeactivated: boolean,
    lastLogin: string | null
}

//region admin page
/**
 * GET /admin/users
 */
export type GetAllUsersResponse = string[];

/**
 * GET /admin/roles
 */
export type GetRolesResponse = string[];

/**
 * GET /admin/user
 */
export type GetUserResponse = User | string;
export type GetUserQuery = { id: string };

/**
 * PUT\POST /admin/user
 */

export type AddOrUpdateUserResponse = {
    data: User,
    status?: ResponseStatusType
};
export type AddOrUpdateUserBody = Pick<
    UserData,
    "email"
    | "firstName"
    | "lastName"
    | "businessRole"
    | "authService"
    | "userRoles"
    | "accounts"
> & { dbuiAccess: boolean };

/**
 * DELETE /admin/user
 */
export type DeleteUserResponse = {
    data: string,
    status? : ResponseStatusType
};
export type DeleteUserQuery = { id: string };

/**
 * POST /admin/user/reset-password
 */
export type UserResetPasswordResponse = {
    password: string
};
export type UserResetPasswordBody = { id: string };

/**
 * GET /admin/allProjects
 */
export type GetAllProjectsResponse = string[] | string;

export type ProgramSettingItem = {
    programId: string,
    sensitivePrintExpiration: number
}

/**
 * GET /admin/allProgramsSettings
 */
export type GetAllProgramsSettingsResponse = Array<ProgramSettingItem>

/**
 * GET /admin/programSettings
 */
export type GetProgramSettingsResponseParams = { programId: string };
export type GetProgramSettingsResponse = ProgramSettingItem

/**
 * PUT /rest/admin/programSettings/:programId/enableSensitivePrint
 */
export type EnableSensitivePrintParams = { programId: string };
export type EnableSensitivePrintBody = {
    duration: number
}
export type EnableSensitivePrintResponse = ProgramSettingItem

/**
 * PUT /rest/admin/programSettings/:programId/disableSensitivePrint
 */
export type DisableSensitivePrintParams = { programId: string };
export type DisableSensitivePrintResponse = ProgramSettingItem

//endregion

//region user rest api
/**
 * POST /users/initiateForgotPassword
 */
export type InitiateForgotPasswordBody = {
    user: string
};

/**
 * POST /users/confirmForgotPassword
 */
export type ConfirmForgotPasswordBody = {
    user: string
    verificationCode: string
    newPassword: string
};
//endregion

//region landing page preview rest api
/**
 * POST /rest/:accountId/programs/:programId/landingPage/preview
 */
export type LandingPagePreviewParams = {
    accountId: string
    programId: string
}
export type LandingPagePreviewBody = PreviewVideoBody & {
    configuration: PageConfiguration;
    fsUrl?: string;
    formattedDate?: string;
    renderAvatars?: boolean;
    selectedSceneNumbers?: number[];
}
export type LandingPagePreviewResponse = {
    previewUrl: string
}


//region video preview rest api
/**
 * POST /rest/:accountId/programs/:programId/preview/prepare
 */
export type PreviewPrepareParams = {
    accountId: string
    programId: string
}
export enum PreviewPrepareResult {
    Created = "Created",
    Exists = "Exists"
}
export type PreviewPrepareResponse = {
    result: PreviewPrepareResult
}
export type PreviewPrepareBody = {
    programVersionId: string
    previewM2Mode: boolean
}

/**
 * POST /rest/:accountId/programs/:programId/preview/video
 */
export type PreviewVideoParams = { accountId: string, programId: string };
export type OverrideBcCollectionData = {
    colors: {
        primary_color: string
        secondary_color: string
        accent1_color: string
        accent2_color: string
        button_color: string
        buttonText_color: string
    }
    logo: string
}
export type VideoOverrides = {
    removeInteractivity?: boolean;
    addRaaSCache?: boolean;
    ignoreSkipScenesMode?: boolean;
    enableChaptering?: boolean;
}
export type FormatSpec = {
    format: string,
    outputId: string
}
export enum PreviewContext {
    //These contexts represent the context in which we generate a video
    //They are a combination of:
    //- The type of video (e.g., DRAFT, PUBLISHED)
    //- The purpose (e.g., PREVIEW, DOWNLOAD, REVIEWLINK, POST)

    DRAFT_PREVIEW = "DRAFT_PREVIEW",
    DRAFT_DOWNLOAD = "DRAFT_DOWNLOAD",
    DRAFT_REVIEWLINK = "DRAFT_REVIEWLINK",
    PUBLISHED_PREVIEW = "PUBLISHED_PREVIEW",
    PUBLISHED_DOWNLOAD = "PUBLISHED_DOWNLOAD",
    PUBLISHED_POST = "PUBLISHED_POST"
}
export type PreviewVideoOverridingFields = {
    context?: PreviewContext,
    format?: string,
    formats?: FormatSpec[],
    overrideBcCollectionData?: OverrideBcCollectionData
    videoSpecOverrides?: VideoOverrides;
}

export enum VideoSessionRetention {
    HALFHOUR = "30-min",
    DAY = "1-day",
    WEEK = "1-week",
    MONTH = "1-month",
    PERSISTED = "persisted",
    YEAR = "1-year",
    QA = "qa"
}

export type PreviewVideoBody = {
    programVersionId: string;
    presets: Record<string, any>;
    sceneOrder?: string[];
    overridingFields?: PreviewVideoOverridingFields;
    retention?: VideoSessionRetention;
    skipNarrations?: boolean;
    ignoreTracking?: boolean;
    previewVideoErrorCode?: SskyErrorCode;
    previewM2Mode?: boolean;

}
/**
 * POST /rest/:accountId/programs/:programId/export/description
 */
export type ExportVideoBody = PreviewVideoBody & {
    viewerProfileName?: string
    programName: string
    exportDate: string
}
/**
 * POST /rest/:accountId/programs/:programId/preview/spec
 */
export type PreviewSpecParams = {
    accountId: string
    programId: string
}
export type PreviewSpecResponse = any
export type PreviewSpecBody = {
    programVersionId: string;
    presets: Record<string, any>;
    sceneOrder?: string[];
    overridingFields?: PreviewVideoOverridingFields;
    skipNarrations?: boolean;
    previewM2Mode?: boolean;
}

/**
 * POST /rest/preview/narration
 */

export type PreviewNarrationResponse = {
    url: string;
    text: string;
    narrator: string;
}
export type PreviewNarrationBody = {
    narrator: string
    service: string
    text: string
    rate?: number
    volume?: number
}
//endregion video preview rest api


/**
 * POST - /rest/raas
 * POST - /rest/:accountId/programs/:programId/preview/video
 */
export type HandleResponseInput<T> = {
    statusCode: number,
    body?: T,
    headers?: Record<string, string | string[] | undefined>,
    error?: any
}

/**
 * GET /rest/getS3Credentials
 */
export type GetS3CredentialsResponse = STS.Credentials & {
    s3Prefix: string
};

/**
 * POST- /rest/testSftpConnection
 */
export enum SftpConnectionType {
    USER_PASSWORD = "ftp",
    SSH_KEYS = "sftp"
}
type PasswordConnectionDetails = {
    password: string,
    isPasswordEncrypted: boolean
}
export type TestSftpConnectionBody = {
    type: SftpConnectionType,
    url: string,
    userName: string,
    passwordDetails?: PasswordConnectionDetails
}
export type TestSftpConnectionResponse = {
    statusCode: number,
    body: string
}

/**
 * Put /rest/analytics/users
 */
export type TableauUserRegistrationStatus = {
    errId: string,
    tableauStatus : number,
    message: string
}

export type CordClientAuthTokenResponse = {
    token: string
}

export type Account = {
    accountId: string;
    accountSskyId?: number;
    name: string;
    displayName: string;
    type?: string;
}

export type UserDataResponse = {
    userName: string,
    userRoles: string[],
    userId: string,
    accounts: Account[],
    firstName?: string,
    lastName?: string,
    resolverEndPoint: string,
    dtaasEndPoint: string,
    narrationServerEndpoint: string,
    mockNarrationServerEndpoint: string,
    convergenceServerEndpoint?: string,
    convergenceToken?: string,
    tableauBaseURL?: string,
    tableauWorkbookName?: string;
    lastAccessedAccountId?: string;
    pendingAccounts: Account[];
    acceptedAiTerms?: boolean;
}

/**
 * POST /rest/promptToVide
 */

export const getPromptToVideoChatEndpoint = (accountId: string): string => {
    return `/rest/${accountId}/promptToVideoChat`;
};

/**
 * Get /rest/:accountId/oauth2/authuri
 */

export type GetAuthUriResponse = string;
