export enum ParameterTypes {
    parameter = "parameter",
    color = "color"
}
export type Parameter = {
    name: string;
    type: ParameterTypes;
    input?: any;
    mockValue?: string;
    id?: string;
    new?: boolean;
    graphQLId?: string;
    graphQLUpdated?: string;
};

export enum PlaceholderType {
    HOTSPOT = "hotspot",
    TEXT = "text",
    IMAGE = "image",
    VIDEO = "video"
}

export type Placeholder = {
    name: string;
    type: PlaceholderType;
    height: number;
    width: number;
    x: number;
    y: number;
    input?: any;
    mockValue?: string;
    graphQLId?: string;
    graphQLUpdated?: string;
};

export type NarrationPart = {

    id?: string;
    lastUsedRecordingFileName?: number;
    key?: string;
    graphQLId?: string;
    graphQLUpdated?: string;

    name?: string;
    type?: string;
    input?: string;
    mockValue?: string;
    participatingDataElements?: string[]
};

export type Group = {
    id: string;
    graphQLId?: string;
    graphQLUpdated?: string;
    name: string;
    placeholders?: Placeholder[];
};

export type PrioritizedList = {
    slots: Placeholder[] | Group[];
    id: string;
    name: string;
    graphQLId?: string;
    graphQLUpdated?: string;
};

export interface ScenePart {
    name?: string;
    scenePart: string;
    postgresScenePartId?: string;
    graphQLId?: string;
    graphQLUpdated?: string;
    groups?: Group[];
    placeholders: Placeholder[];
    prioritizedLists?: PrioritizedList[];
    NarrationParts: NarrationPart[];
    parameters: Parameter[];
    storyboardFrameThumbnailLocation?: string;
}

export interface Scene {
    id: string;
    name: string;
    backgroundImage?: string;
    creationTime?: string;
    sceneParts: ScenePart[];
    invalid?: boolean;
    skipped?: boolean;
    graphQLId?: string;
    graphQLUpdated?: string;
}
