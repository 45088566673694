import { VideoAspectRatio } from "./external/videoSpec";

export type CommonEnumAspectRatio = "ar_14_9" | "ar_16_9" | "ar_191_100" | "ar_1_1" | "ar_4_5" | "ar_9_16";
export type PlayerAspectRatio = "horizontal" | "vertical" | "square" | "large-square" | "vertical-4-5";

export function getAspectRatio(aspectRatioEnum: CommonEnumAspectRatio): AspectRatio {
    return RATIOS[aspectRatioEnum];
}

/**
 * This is an immutable class designed to represent an aspect ratio.
 */
export class AspectRatio {
    readonly #width: number;
    readonly #height: number;
    readonly #displayName: string;
    readonly #icon: string;
    readonly #playerFormat: PlayerAspectRatio;
    readonly #videoRatio: VideoAspectRatio;

    constructor(width: number, height: number, displayName: string, icon: string, playerFormat: PlayerAspectRatio, videoRatio: VideoAspectRatio) {
        this.#width = width;
        this.#height = height;
        this.#displayName = displayName;
        this.#icon = icon;
        this.#playerFormat = playerFormat;
        this.#videoRatio = videoRatio;
    }

    displayName(): string {
        return this.#displayName;
    }

    ratio(): number {
        return this.#width / this.#height;
    }

    icon(): string {
        return this.#icon;
    }

    width(): number {
        return this.#width;
    }

    height(): number {
        return this.#height;
    }

    playerFormat(): PlayerAspectRatio {
        return this.#playerFormat;
    }
    
    videoRatio(): VideoAspectRatio {
        return this.#videoRatio;
    }
}


const RATIOS: Record<CommonEnumAspectRatio, AspectRatio> = {
    ar_14_9: new AspectRatio(14, 9, "Horizontal (14:9)", "rectangle-wide", "horizontal", VideoAspectRatio.FULL_LANDSCAPE),
    ar_16_9: new AspectRatio(16, 9, "Horizontal (16:9)", "rectangle-wide", "horizontal", VideoAspectRatio.FULL_LANDSCAPE),
    ar_191_100: new AspectRatio(1.91, 1, "Horizontal (1.91:1)", "rectangle-wide", "horizontal", VideoAspectRatio.FULL_LANDSCAPE),
    ar_1_1: new AspectRatio(1, 1, "Square (1:1)", "square", "square", VideoAspectRatio.SQUARE),
    ar_4_5: new AspectRatio(4, 5, "Vertical (4:5)", "rectangle-portrait", "vertical-4-5", VideoAspectRatio.PORTRAIT),
    ar_9_16: new AspectRatio(9, 16, "Vertical (9:16)", "rectangle-portrait", "vertical", VideoAspectRatio.FULL_PORTRAIT)
};
