import type { ReadonlyValueSet } from "./logic";

export enum CompatibilityVersions {
    Legacy = 0,
    DisplayName = 0.1,
    RowForDataElement = 0.2,
    IdSupport = 0.3
}

export enum DataElementContentTypes {
    String = "string",
    Number = "number",
    Date = "datetime",
    Boolean = "boolean",
    Url = "url"
}

export enum CreativeDataElementContentTypes {
    Image = "image",
    Video = "video",
    Audio = "audio",
    Color = "color",
    Narration = "narration"
}

export type DataElementContentType = DataElementContentTypes[keyof DataElementContentTypes];

export type CreativeDataElementContentType = DataElementContentTypes[keyof DataElementContentTypes] | CreativeDataElementContentTypes[keyof DataElementContentTypes];

export enum DataElementOrigins {
    Feed = "feed",
    Derived = "derived",
    System = "system",
    Creative = "creative"
}

export enum DataElementStatuses {
    Approved = "approved",
    Pending = "pending",
    Missing = "missing"
}

export enum TagNames {
    PII = "PII"
}

export type DataElementTypes = DataElementContentTypes | CreativeDataElementContentTypes;

export type BaseDataElement = {
    id?: string;
    graphQLId?: string;
    graphQLUpdated?: string;
    name?: string;
    type: DataElementTypes;
    origin: DataElementOrigins;
    displayName: string;
    compatibilityVersion: CompatibilityVersions;
    description?: string;
    usedInScenes?: string;
    updatedTime?: number;
    tags?: TagNames[];
    getValueSet?: () => ReadonlyValueSet;
    _objectType?: symbol;
};

export interface CreativeDataElement extends BaseDataElement {
    origin: DataElementOrigins.Creative;
    valueSet: ReadonlyValueSet;
    useValueSet: boolean;
    assignedToAllStories: boolean;
    assignedStories: string[]; //stories ids array, relevant for creative data elements
}

export interface FeedDataElement extends BaseDataElement {
    origin: DataElementOrigins.Feed;
    status: DataElementStatuses;
    valueSet: ReadonlyValueSet;
    useValueSet: boolean;
}

export interface DerivedDataElement extends BaseDataElement {
    origin: DataElementOrigins.Derived;
}

export interface StudioDataElement extends DerivedDataElement {
    logic: any;
}

export interface SystemDataElement extends BaseDataElement {
    origin: DataElementOrigins.System;
    valueSet: ReadonlyValueSet;
    useValueSet: boolean;
}

export type DataElement = FeedDataElement | CreativeDataElement | DerivedDataElement | SystemDataElement;

/**
 * Type that contains the data elements in an editor program as we use them across program preview/snapshot/publish steps
 */
export type ProgramDataElements = {
    feedElements: Array<FeedDataElement>,
    studioElements: Array<StudioDataElement>,
    systemDataElements: Array<SystemDataElement>,
};

export type UsedInInfo = {
    programName: string,
    sceneName: string
}

export type UsedIn = {
    dataElementId: string
    dataElementName: string
    usedInScenes: UsedInInfo[]
}