import type { AlignmentPair, Coordinate } from "./types/editorPlaceholder";
import { PositionUnit, Adjustment, HorizontalAlignment, VerticalAlignment } from "./types/editorPlaceholder";
import type { MinimumContentEnd, ObjectPosition, PlayAt } from "./types/vlxTypes";
import { FontVariantCaps, MinimumContentEndBase, ObjectFit, TextHorizontalAlign, TextTransform, TextVerticalAlign } from "./types/vlxTypes";
import { replaceSpecialChar } from "./generalUtils";
import { AVATAR_SILENCE_DURATION } from "./commonConst";

export const generateVlxStorySettingProjectId = (accountName: string, programName: string) => {
    const name = `${accountName}-${programName}`;
    return replaceSpecialChar(name, "-");
};

export const mapAdjustmentToFit = (adjustment : Adjustment): ObjectFit => {
    switch (adjustment) {
        case Adjustment.Stretch: {
            return ObjectFit.Fill;
        }
        case Adjustment.ScaleToFit: {
            return ObjectFit.Contain;
        }
        case Adjustment.ScaleToFill: {
            return ObjectFit.Cover;
        }
        default:
            return ObjectFit.Cover;
    }
};

export const convertToObjectPosition = (alignment: AlignmentPair): ObjectPosition => {
    if (alignment != null) {
        return `${alignment.horizontal} ${alignment.vertical}`;
    }

    return `${HorizontalAlignment.Center} ${VerticalAlignment.Center}`;
};

export const cleanSceneName = (sceneName: string): string => {
    return sceneName.replace(/\s/g, "__");
};

export const originalSceneName = (sceneName: string): string => {
    return sceneName.replace(/__/g, " ");
};

export enum BrandConfigurationTextStyleLetterCase {
    NONE = "none",
    UPPERCASE = "uppercase",
    LOWERCASE = "lowercase",
    CAPFIRSTWORD = "capFirstWord",
    CAPEACHWORD = "capEachWord",
    SMALLCAPS = "smallCaps"
}

export type BrandConfigurationTextStyle = {
    letterCase: BrandConfigurationTextStyleLetterCase,
    letterSpacing: number,
    lineSpacing: number,
    fontUrl: string
};

export function brandConfigurationTextStyleToVideoSpecStyleProps(bcTextStyle: BrandConfigurationTextStyle) {
    return {
        _sskyFontUrl: bcTextStyle.fontUrl,
        letterSpacing: bcTextStyle.letterSpacing / 1000,
        fontVariantCaps: getTextStyleFontVariantCaps(bcTextStyle.letterCase),
        textTransform: getTextStyleTransform(bcTextStyle.letterCase),
        lineHeight: bcTextStyle.lineSpacing / 100
    };
}

function getTextStyleFontVariantCaps(letterCase: BrandConfigurationTextStyleLetterCase): FontVariantCaps {
    if (letterCase === BrandConfigurationTextStyleLetterCase.SMALLCAPS) {
        return FontVariantCaps.SmallCaps;
    }
    else {
        return FontVariantCaps.Normal;
    }
}

function getTextStyleTransform(letterCase: BrandConfigurationTextStyleLetterCase): TextTransform {
    switch (letterCase) {
        case BrandConfigurationTextStyleLetterCase.LOWERCASE:
            return TextTransform.Lowercase;
        case BrandConfigurationTextStyleLetterCase.UPPERCASE:
            return TextTransform.Uppercase;
        case BrandConfigurationTextStyleLetterCase.CAPEACHWORD:
            return TextTransform.Capitalize;
        case BrandConfigurationTextStyleLetterCase.CAPFIRSTWORD:
            return TextTransform.CapitalizeFirstLetter;
        default:
            return TextTransform.None;
    }
}

export function getTextStyleHorizontalAlign(horizontalAlignment: HorizontalAlignment): TextHorizontalAlign {
    switch (horizontalAlignment) {
        case HorizontalAlignment.Left:
            return TextHorizontalAlign.Left;
        case HorizontalAlignment.Right:
            return TextHorizontalAlign.Right;
        case HorizontalAlignment.Center:
        default:
            return TextHorizontalAlign.Center;
    }
}

export function getTextStyleVerticalAlign(verticalAlignment: VerticalAlignment): TextVerticalAlign {
    switch (verticalAlignment) {
        case VerticalAlignment.Top:
            return TextVerticalAlign.Top;
        case VerticalAlignment.Bottom:
            return TextVerticalAlign.Bottom;
        case VerticalAlignment.Center:
        default:
            return TextVerticalAlign.Middle;
    }
}

export const MinContentEndPlayToEndOfContentIcon: MinimumContentEnd = {
    base: MinimumContentEndBase.ClipEnd,
    value: 0.0
};

export const MinContentEndPlayToEndOfContentNonIcon: MinimumContentEnd = {
    base: MinimumContentEndBase.ClipEnd,
    value: -1.0
};

export const MinContentEndTruncateAtSceneEnd: MinimumContentEnd = {
    base: MinimumContentEndBase.ClipBegin,
    value: 0.0
};

export const MinContentEndPlayToEndOfContentAvatar: MinimumContentEnd = {
    base: MinimumContentEndBase.ClipEnd,
    value: -AVATAR_SILENCE_DURATION
};

export const PlayAtAvatar: PlayAt = {
    action: "narrate",
    offset: -AVATAR_SILENCE_DURATION
};

export function getCoordinatePixelValue(
    value: Coordinate,
    containerSize: number
): number {
    switch (value.unit) {
        case PositionUnit.Pixel:
            return value.value;
        case PositionUnit.Percent:
            return containerSize * value.value / 100;
        default:
            throw new Error(`Unsupported unit: ${value.unit}`);
    }
}
