/**
 * this file describes the shape of VideoSpec Resource (AKA status.json)
 * https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/164200459/Smart-Video+API#Smart-VideoAPI-Response
 */

import type { EditorAssetActionInfo, ResourceRecord } from "../types/trackingResource";

export enum Status {
    NotStarted = "not_started",
    EncodingStart = "encoding_start",
    Finished = "finished",
    Error = "error"
}

export enum MediaType {
    MpegUrl = "application/x-mpegURL",
    Mp4 = "video/mp4"
}

type URI = string;

type Href = {
    href: URI;
};

export type Dimension = {
    width: number;
    height: number;
};

export type VideoStream = {
    href: URI;
    id: string;
    zip?: URI;
    "media-type": MediaType;
};

export type Links = {
    metadata: Href;
    transcript: Href;
    log?: Href; // zip file
    altLog?: Href; // json format
    self: Href;
    //TODO - alona fix "video-stream" tye to VideoStream | VideoStream[]
    "video-stream": VideoStream; // URL to the rendered video/frame
    captions: Href;
    prefData: Href;
    "descriptive-transcript-text"?: Href;
    "descriptive-transcript"?: Href;
};

export type VideoSpecResponse = {
    accountid?: string;
    projectid: string;
    requestId: string;
    playerToken: string; // base64
    dimensions: Dimension;
    status: Status;
    _links: Links;
    persist?: string;
};

export interface PreviewVideoResponse extends VideoSpecResponse {
    isNarrationsPartial: boolean;

    resourceTrackingData: Record<string, ResourceRecord<EditorAssetActionInfo>[]>;
}

type Viewport = {
    width: number;
    height: number;
}

export type MetadataResponse = {
    jobId: string
    viewport: Viewport
    videoDuration: string //can be cast to number
    scenes: { start: number, end: number, name: string, UUID: string }[]
    enableChaptering: boolean
}
