export type fieldValidationType = {
    isValid: boolean;
    helperText: string;
};

export enum ReportEventType {
    Error = "studio-error",
    Info = "studio-info",
    NonTransactionalError = "studio-non-transactional-error",
    ImglyFailedToOpen = "imgly-failed-to-open",
    ImglyActionError = "imgly-action-error",
    ImglyLogMessage = "imgly-log-message",
    RecorderError = "recorder-error",
}

export type LogReportContent = {
    msg: string,
    metadata: BasicLogData & any,
    eventType: ReportEventType
};

export type BasicLogData = {
    accountId: string;
    accountName: string;
    projectName: string;
    stage: string;
    version: string;
    fsUrl: string;
    fsSession: string;
};

export type AccountDefinition = {
    accountId: string,
    name: string
}
