export enum Stages {
    dev = "dev",
    UAT = "uat",
    prod = "prod"
}

export type SnapshotNumberOrStage = number | "draft" | Stages;

export type SnapshotData = {
    stage: Stages,
    snapshotNumber: number
    promotedDate: number
    promotedBy: string
    graphQLId?: string;
}

export type StageLifeHistory = {
    name: Stages
    snapshots: SnapshotData[]
}

export type LifecycleHistory = StageLifeHistory[]
