export const removeFileExtension = (filename: string): string => {
    const lastIndexOfDot = filename.lastIndexOf(".");
    return lastIndexOfDot >= 0 ? filename.substr(0, lastIndexOfDot) : filename;
};

export type SplitFilename = { filename: string, extension: string };

export const splitFilename = (filename: string): SplitFilename => {
    const lastIndexOfDot = filename.lastIndexOf(".");
    return lastIndexOfDot >= 0 ? { filename: filename.substr(0, lastIndexOfDot), extension: filename.substr(lastIndexOfDot + 1) } : { filename, extension: "" };
};

export const cleanAssetName = (filename: string): string => {
    return (
        filename &&
        filename
            .trim()
            .toLowerCase()
            .replace(/[^\w-~.]/gi, "-")
    );
};

/**
 * Encodes asset key to valid URI
 *
 * @param assetKey to encode
 */
export const encodeAssetKey = (assetKey: string): string => {
    return assetKey.split("/").map(part => encodeURIComponent(part)).join("/");
};

export const getEncodedAssetUrl = (base: string, key: string): string => {
    return base + encodeAssetKey(key);
};
