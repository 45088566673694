import type { ID } from "aws-sdk/clients/s3";

export enum ProgramType {
    CRM = "CRM",
    Ads = "Ads"
}

export interface Program {
    accountId: string;
    assets?: string[]; // asset ids
    graphQLId?: string;
    graphQLBuilderDraftId?: string;
    graphQLUpdated?: string;
    projectName: string;
    displayName: string;
    description: string;
    programType: ProgramType;
    programId?: string; // SF-id
    pii?: boolean;
    isLegacy?: boolean;
    thumbnailLocation?: string;
    narrationsVersion?: number;
    storiesVersion?: number;
    creativeVersion?: number;
    RFR?: number;
    RFRUpdateTimestamp?: number;
    creativeDDE: string;
    creativeNameLabel: string;
    customAnalyticsOrder?: string[];
    transition?: any;
    editorDataConnectorEnabled?: boolean;
    editorDataConnectorType?: string;
    chapteringEnabled?: boolean;
    publishTarget?: string;
}

export enum TypesOfProjectSummary {
    builders = "builders",
    dashboards = "dashboards",
    canvas = "canvas"
}

export enum ReasonsCopyToAnotherAccountIsForbidden {
    NOT_EDITOR_PROGRAM = "Not an editor program",
    ACCOUNT_LEVEL_DATA = "Program uses account-level data"
}

export enum NotificationCopyToAnotherAccount {
    None = "None",
    ACCOUNT_LEVEL_FONTS = "Account-level fonts",
    ACCOUNT_LEVEL_MUSIC = "Account-level music tracks",
    ACCOUNT_LEVEL_MUSIC_AND_FONTS = "Account-level music and fonts",
}

export interface ProgramSummary {
    id: ID;
    displayName: string;
    isLegacy: boolean;
    programType: ProgramType
    showCreative: boolean;
    campaignSettings: boolean;
    showReports: boolean;
    showExperiments: boolean;
    isArchive: boolean;
    isEditor: boolean;
    postgresName?: string; // TODO remove when summary is using postgres name and not dynamo name. rename program from builder should also write to postgres
    projectIds: {
        builders: string;
        dashboards: string;
        canvas: string;
        sfId: string;
        builderDraftVersionId: string;
    };
    allowApplications?: boolean;
    reasonsCopyToOtherAccountIsForbidden: ReasonsCopyToAnotherAccountIsForbidden[];
    notificationCopyToAnotherAccount: NotificationCopyToAnotherAccount;
}

export interface AccountsDBProgram {
    programId: string;
    name: string;
    displayName: string;
    programSskyId: string;
    accountsDBName: string;
}
