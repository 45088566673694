import type { ObjectViewBox } from "./placeholderUtils";
import { AvatarCropPreset } from "./types/editorPlaceholder";
import { getCoordinatePixelValue } from "./vlxUtils";

export function calculateCroppedDimensions(
    originalWidth: number,
    originalHeight: number,
    objectViewBox: ObjectViewBox
): {
    width: number;
    height: number;
} {
    const topPixels = getCoordinatePixelValue(objectViewBox.top, originalHeight);
    const rightPixels = getCoordinatePixelValue(objectViewBox.right, originalWidth);
    const bottomPixels = getCoordinatePixelValue(objectViewBox.bottom, originalHeight);
    const leftPixels = getCoordinatePixelValue(objectViewBox.left, originalWidth);
    return {
        width: originalWidth - leftPixels - rightPixels,
        height: originalHeight - topPixels - bottomPixels
    };
}

export type LayerPosition = {
    top: number;
    left: number;
    width: number;
    height: number;
}

export function adjustAvatarLayerPosition(
    layerPosition: LayerPosition,
    avatarAspectRatio: number,
    videoAspectRatio: number,
): LayerPosition {
    let newTop = layerPosition.top;
    let newHeight = layerPosition.height;

    let newWidth = layerPosition.height * avatarAspectRatio / videoAspectRatio;
    if (newWidth > 100) {
        newWidth = 100;
        newHeight = newWidth * videoAspectRatio / avatarAspectRatio;
        if (newTop + newHeight > 100) {
            newTop = 100 - newHeight;
        }
    }

    let newLeft = layerPosition.left - (newWidth - layerPosition.width) / 2;
    if (newLeft < 0) {
        newLeft = 0;
    }
    if (newLeft + newWidth > 100) {
        newLeft = 100 - newWidth;
    }

    return {
        left: newLeft,
        top: newTop,
        width: newWidth,
        height: newHeight
    };
}

export const DISABLED_AVATAR_CROP_MODES_BY_AVATAR_ID: Record<string, AvatarCropPreset[]> = {
    "d2408a7f-27cf-4a1b-bfd6-1a9bbb9677a1": [AvatarCropPreset.FULL_BODY] // Stock avatar "Victoria"
};

export function isCropModeDisabledForAvatarId(avatarId: string, cropMode: AvatarCropPreset): boolean {
    return !!DISABLED_AVATAR_CROP_MODES_BY_AVATAR_ID[avatarId]?.includes(cropMode);
}

export function replaceDisabledCropMode(disabledCropMode: AvatarCropPreset) {
    return disabledCropMode === AvatarCropPreset.FULL_BODY
        ? AvatarCropPreset.CLOSE_UP
        : AvatarCropPreset.FULL_BODY;
}
