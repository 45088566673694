// duplicated with LOGIC_MEDIA_TYPES
import type { LogicContainers } from "../commonConst";
import type { MediaTypes } from "./asset";
import type { FunctionDefinition, Hotspot, VariableType } from "@sundaysky/vlx-types";
import { DataElementContentTypes } from "./dataElement";
import { DataTableOutputTypes } from "./dataTable";

// this function does nothing, it's just a workaround so typescript will recognize the values of the object as a type
function t<V extends string, T extends {[key in string]: V}>(o: T): T {
    return o;
}

export const LogicMediaType = t({
    ...DataElementContentTypes,
    ...DataTableOutputTypes,
    List: "prioritized",
    ListSlot: "prioritized-slot"
});

// This type is defined as the values of LogicMediaType
export type LogicMediaTypes = typeof LogicMediaType[keyof typeof LogicMediaType];

// duplicated with LOGIC_TYPES
export enum LogicType {
    SceneValidation = "boolean",
    Story = "Story",
    Quality = "quality",
    Ratio = "ratio",
    NextScene = "scene",
    Animation = "animation",
    Image = "image",
    Video = "video",
    Text = "text",
    Audio = "audio",
    BackgroundAsset = "backgroundAsset",
    DataElementString = "string",
    DataElementNumber = "number",
    DataElementDate = "datetime",
    DataElementBoolean = "de_boolean",
    AnalyticCustomField = "analyticsField",
    Compound = "compound",
    Prioritized = "prioritized",
    PrioritizedSlot = "prioritized-slot",
    Color = "color"
}

// duplicated with LOGIC_CONTEXT
export enum LogicContext {
    Scene = "scene",
    Derived = "derived",
    Placeholder = "placeholder",
    Story = "story",
    StoryRatioAndQuality = "storyRatioAndQuality",
    Narration = "narration",
    Prioritized = "prioritized",
    Soundtrack = "soundtrack",
    BackgroundAsset = "backgroundAsset",
    Program = "program"
}

export enum ActionableDataValueType {
    Const = "const",
    ValueSetValue = "valueSetValue",
    DataElement = "dataElement",
    Asset = "asset",
    Animation = "animation",
    MappingTable = "mappingTable",
    Variable = "variable",
    Prioritized = "prioritized",
    PrioritizedSlot = "prioritized-slot",
}

export interface When {
    rhs?: any;
    lhs: ActionableData | null;
    operator: string;
}

export type Relation = "And" | "Or" | "Then" | {};

export type Value = any; // todo define value

export interface Rule {
    isLast?: boolean;
    key: string;
    convertedKey?: string; // id of ValueSetValue in case the rule was converted from data element with value set.
    value?: Value;
    whens: (When | Relation)[];
    show?: boolean;
    always?: boolean;
    name?: string;
}

export interface LogicJSON {
    defaultValue: any;
    defaultValueShow?: boolean;
    isLast?: boolean;
    outputType: LogicType;
    rules?: Rule[];
}

export type DynamoLogic = {
    logicId: string;
    inputName: string;
    logicData: LogicJSON;
};

export interface ValueSetValue {
    dn: string; // display name
    id: string;
}

export type ValueSet = Array<ValueSetValue>;
export type ReadonlyValueSet = ReadonlyArray<ValueSetValue>;

export type WireframeLogicContainer = Record<string, LogicJSON>;

export interface WireframeStoryLogic {
    [LogicContainers.BackgroundAsset]: {
        [MediaTypes.Video]: LogicJSON,
        [MediaTypes.Image]: LogicJSON
    },
    [LogicContainers.Soundtrack]: {
        [MediaTypes.Audio]: LogicJSON
    },
    [LogicContainers.VideoSettings] : {
        [LogicContext.StoryRatioAndQuality]: LogicJSON
    }
    [LogicContainers.DecisionPoint]: WireframeLogicContainer
}

export type WireframeScenesLogic = Record<string, WireframeLogicContainer>;

export type WireframeStoriesLogic = Record<string, WireframeStoryLogic>;

export interface WireframeProgramLogic {
    [LogicContainers.StorySelection] :{
        [LogicContainers.StorySelection] : LogicJSON
    }
}

export type WireframeLogic = WireframeScenesLogic & {
    [LogicContainers.Derived]?: WireframeLogicContainer,
    [LogicContainers.Analytics]?: WireframeLogicContainer,
    [LogicContainers.Master]?: WireframeLogicContainer,
    [LogicContainers.Program]?: WireframeProgramLogic
    [LogicContainers.Story]?: WireframeStoriesLogic
}

export interface CompoundValue {
    // TODO should relate to value
    show: boolean;
    outputType: string;
    id: string;
    value: Value;
    displayName: string;
}

export type HotspotValue = CompoundValue[];

export interface ActionableData {
    id: string;
    name?: string;
    value?: any;
    displayName?: string;
    mediaType: LogicMediaTypes;
    type: ActionableDataValueType;
    actions?: FunctionDefinition[];
    _objectType: Symbol;
}

export interface PrioritizedSlotActionableData extends ActionableData {
    type: ActionableDataValueType.PrioritizedSlot;
    slotIdx: number;
}

export interface VariableActionableData extends ActionableData {
    type: ActionableDataValueType.Variable;
    path: string;
}

export interface DecisionPointValue {
    nextDPLogicId: string;
    sceneId: string;
}

export type DataElementId = string;
export type VlxElementType = LogicType | "string";

export type VlxRuleValueString = string;
export type VlxRuleValueBoolean = boolean;
export type VlxRuleValueNumber = number;
export type VlxRuleValueEval = { eval: string };
export type VlxRuleValueRepo = { "repo-url": string, repository: string };

export type VlxRuleValue = VlxRuleValueString | VlxRuleValueBoolean | VlxRuleValueEval | VlxRuleValueRepo | VlxRuleValueNumber;

// Compound (hotpot) rule values
type EditorVariableCardinality<VarType extends VariableType, ValueType> = { type: VarType, value: ValueType };
// export type CompoundVlxRuleValueText = EditorVariableCardinality<"text", string>;
export type CompoundVlxRuleValueHotspot = EditorVariableCardinality<"hotspot", Hotspot>;
// export type CompoundVlxRuleValueCompound = EditorVariableCardinality<"compound", { actionRef: string }>;
export type CompoundVlxRuleValueTypes = CompoundVlxRuleValueHotspot | any;
export type CompoundVlxRuleValue = Record<string, CompoundVlxRuleValueTypes>;

export type VlxRuleValueVariants = VlxRuleValue | { source: VlxRuleValue } | { href: VlxRuleValue } | CompoundVlxRuleValue;

export type VlxRule = {
    key?: DataElementId
    when?: string,
    value: VlxRuleValueVariants,
};

export type VlxDerivedElement = {
    name: string,
    value: {
        rules: Array<VlxRule>,
        type: VlxElementType
    }
}
