import type { BasicMetadata, ConnectorType, SerializedDynamicElement, ValueSetItem } from "@sundaysky/customer-data-common-goblin-ds";
import { ALL_SYSTEM_ELEMENTS, DynamicElement, ElementType, getDefaultSourceFromTarget } from "@sundaysky/customer-data-common-goblin-ds";
import { getDataElementsAndValuesFromPlaceholderContent, getPlaceholderContentDynamicInfo, isPlaceholderHidden } from "./placeholderUtils";
import type { PlaceholderContent, PlaceholderSettings } from "./types/editorPlaceholder";
import { CommonPlaceholderType } from "./types/editorPlaceholder";
import type { SceneSkippingAudience } from "./types/editorSceneAudience";
import type { BrandRules } from "./types/editorBrandRules";

export type PlaceholderContentWithNameAndType = {
    placeholder_content: Object;
    placeholder_settings: Object;
    type: CommonPlaceholderType;
    name: string;
};

export type UsedInPlaceholder = {
    type: "element" | "value"
    sceneName: string
    usedInType: "placeholder"
    placeholderName: string
    placeholderType: CommonPlaceholderType
    isPlaceholderDynamic: boolean
    isPlaceholderHidden: boolean
}

type UsedInSkipSceneLogic = {
    type: "element" | "value"
    sceneName: string
    usedInType: "skipSceneLogic"
}

type UsedInBrandByAudienceLogic = {
    type: "element" | "value"
    usedInType: "brandByAudienceLogic"
}

export type UsedIn = UsedInPlaceholder | UsedInSkipSceneLogic | UsedInBrandByAudienceLogic;

type DynamicElementInUse = {
    elementId: string
    elementName: string
    usedIn: UsedIn[]
}
type DynamicElementValueInUse = {
    elementId: string
    elementName: string
    values: ValueInUse[]
}

type ValueInUse = {
    valueId: string
    value: string
    usedIn: UsedIn[]
}

export type DynamicElementValidationResult = {
    isValid: boolean;
    uniqueNameValidationError: boolean;
    emptyNameValidationError: boolean;
    invalidCharNameValidationError: boolean;
    removedUsedElementValidationError: boolean;
    removedUsedValueValidationError: boolean;
    missingElementValidationError: boolean;
    removedAllValuesWhenUsedInNarrationValidationError: boolean;
    emptyNameColumns: string[];
    nonUniqueNames: string[];
    invalidCharNames: string[];
    newElementsNames: string[];
    existingElementsNames: string[];
    existingElementsIds: Set<string>;
    updatedDynamicElementsIds: string[];
    removedValuesInUse: DynamicElementValueInUse[];
    removedValueSetInElementInUseInNarrationPH: DynamicElementInUse[];
    removedElementInUse: DynamicElementInUse[];
    dataElementsOrValuesUsedInById: Record<string, UsedIn[]>;
    updatedDynamicElementsById: Record<string, DynamicElement>;
    removedDynamicElementsIds: string[];
    missingUniqueIdentifierColumnValidationError: boolean;
}

export const SPECIAL_CHAR_VALIDATION = "Use only letters, numbers, spaces, and/or: _ , / \\ - %";

const getDynamicElementName = (dynamicElement: DynamicElement): string => dynamicElement?.target?.name;

export const isNameEmpty = (name: string): boolean => {
    return !name?.trim?.();
};

export const isNameWithInvalidChars = (name: string): boolean => {
    const format = /^[a-zA-Z\s\-\\,/_%\d]+$/;
    return !format.test(name);
};

const validateNamesExistAndUnique = (
    uniqueIdentifierField: string,
    newDynamicElements: SerializedDynamicElement[],
    updatedDynamicElementsById: Record<string, DynamicElement>,
    existingElementsByLocalId: Record<string, DynamicElement>,
    validationResult: DynamicElementValidationResult
) => {
    const fillNamesAndEmptyColumns = (dynamicElement: DynamicElement, emptyColumns: string[], names: string[], invalidCharNames: string[]) => {
        const name = getDynamicElementName(dynamicElement);
        if (isNameEmpty(name)) {
            emptyColumns.push(`Column ${dynamicElement.metadata.order + 1}`);
        }
        else {
            if (isNameWithInvalidChars(name)) {
                invalidCharNames.push(name);
            }
            names.push(name);
        }
    };

    const parsedNewElements = newDynamicElements.reduce<Pick<DynamicElementValidationResult, "newElementsNames" | "emptyNameColumns" | "invalidCharNames">>((acc, newDynamicElement) => {
        fillNamesAndEmptyColumns(DynamicElement.deserialize(newDynamicElement), acc.emptyNameColumns, acc.newElementsNames, acc.invalidCharNames);
        return acc;
    }, { newElementsNames: [], emptyNameColumns: [], invalidCharNames: [] });
    validationResult.newElementsNames = parsedNewElements.newElementsNames;

    const updatedAndExistingElementsArray = Object.values({ ...existingElementsByLocalId, ...updatedDynamicElementsById }).concat(ALL_SYSTEM_ELEMENTS);
    const parsedUpdatedAndExistingElements = updatedAndExistingElementsArray.reduce<Pick<DynamicElementValidationResult, "existingElementsNames" | "emptyNameColumns" | "invalidCharNames">>(
        (acc, existingDynamicElement) => {
            fillNamesAndEmptyColumns(existingDynamicElement, acc.emptyNameColumns, acc.existingElementsNames, acc.invalidCharNames);
            return acc;
        },
        { existingElementsNames: [], emptyNameColumns: [], invalidCharNames: [] });
    validationResult.existingElementsNames = parsedUpdatedAndExistingElements.existingElementsNames;

    const emptyNameColumns = [...parsedNewElements.emptyNameColumns, ...parsedUpdatedAndExistingElements.emptyNameColumns];
    if (emptyNameColumns.length) {
        validationResult.isValid = false;
        validationResult.emptyNameValidationError = true;
        validationResult.emptyNameColumns = emptyNameColumns;
    }

    const newAndExistingNames = [...validationResult.newElementsNames, ...validationResult.existingElementsNames];
    if (!newAndExistingNames.includes(uniqueIdentifierField)) {
        validationResult.isValid = false;
        validationResult.missingUniqueIdentifierColumnValidationError = true;
    }

    const invalidCharNames = [...parsedNewElements.invalidCharNames, ...parsedUpdatedAndExistingElements.invalidCharNames];
    if (invalidCharNames.length) {
        validationResult.isValid = false;
        validationResult.invalidCharNameValidationError = true;
        validationResult.invalidCharNames = invalidCharNames;
    }

    const nonUniqueNames = newAndExistingNames.filter((e, index, array) => index !== array.indexOf(e));
    if (nonUniqueNames.length) {
        validationResult.isValid = false;
        validationResult.uniqueNameValidationError = true;
        validationResult.nonUniqueNames = nonUniqueNames;
    }
};

const validateDynamicElementsExists = (dynamicElementsIds: string[], existingElementsIds: Set<string>, dynamicElementValidationResult: DynamicElementValidationResult) => {
    const isMissingElement = dynamicElementsIds.some(localId => !existingElementsIds.has(localId));
    if (isMissingElement) {
        dynamicElementValidationResult.isValid = false;
        dynamicElementValidationResult.missingElementValidationError = true;
    }
};

const validateDynamicElementsRemovedValuesNotInUse = (updatedDynamicElementsById: Record<string, DynamicElement>, existingElementsByLocalId: Record<string, DynamicElement>,
    dataElementsOrValuesUsedInById: Record<string, UsedIn[]>, dynamicElementValidationResult: DynamicElementValidationResult) => {
    const removedValuesInUse: DynamicElementValueInUse[] = Object.keys(updatedDynamicElementsById).reduce<DynamicElementValueInUse[]>((acc, elementId) => {
        const existingElement: DynamicElement = existingElementsByLocalId[elementId];
        const elementName: string = getDynamicElementName(existingElement);
        let dynamicElementValueInUse: DynamicElementValueInUse = {
            elementId,
            elementName,
            values: []
        };
        const updatedElement: DynamicElement = updatedDynamicElementsById[elementId];
        const updatedElementValuesIdsSet: Set<string> = new Set<string>(updatedElement.valueSet?.map(value => value.id) || []);
        const removedValues: ValueSetItem[] = existingElement?.valueSet?.filter(value => !updatedElementValuesIdsSet.has(value.id)) || [];
        removedValues.forEach(({ id: valueId, dn: value }) => {
            if (dataElementsOrValuesUsedInById[valueId]?.length) {
                dynamicElementValueInUse.values.push({
                    valueId,
                    value,
                    usedIn: dataElementsOrValuesUsedInById[valueId]
                });
            }
        });
        if (dynamicElementValueInUse.values.length) {
            acc.push(dynamicElementValueInUse);
        }
        return acc;
    }, []);
    if (removedValuesInUse.length) {
        dynamicElementValidationResult.isValid = false;
        dynamicElementValidationResult.removedUsedValueValidationError = true;
        dynamicElementValidationResult.removedValuesInUse = removedValuesInUse;
    }
};

const validateDynamicElementWithNoValueSetNotInUseInNarrationPlaceholder = (
    updatedDynamicElementsById: Record<string, DynamicElement>,
    dataElementsOrValuesUsedInById: Record<string, UsedIn[]>,
    dynamicElementValidationResult: DynamicElementValidationResult
) => {
    const removedValueSetInElementInUseInNarrationPH: DynamicElementInUse[] = Object.keys(updatedDynamicElementsById).reduce<DynamicElementInUse[]>((acc, elementId) => {
        const updatedDynamicElement = updatedDynamicElementsById[elementId];
        const isUpdateValueSetEmpty = !updatedDynamicElement.valueSet?.length;
        const elementName = getDynamicElementName(updatedDynamicElement);

        if (isUpdateValueSetEmpty && dataElementsOrValuesUsedInById[elementId]) {
            const usedIn = dataElementsOrValuesUsedInById[elementId].filter(e =>
                (e.usedInType === "placeholder") && (e as UsedInPlaceholder).placeholderType === CommonPlaceholderType.NARRATION);
            if (usedIn.length) {
                acc.push({ elementId, elementName, usedIn });
            }
        }
        return acc;
    }, []);
    if (removedValueSetInElementInUseInNarrationPH.length) {
        dynamicElementValidationResult.isValid = false;
        dynamicElementValidationResult.removedAllValuesWhenUsedInNarrationValidationError = true;
        dynamicElementValidationResult.removedValueSetInElementInUseInNarrationPH = removedValueSetInElementInUseInNarrationPH;
    }
};

const validateDynamicElementsRemovedNotInUse = (removedDynamicElementsIds: string[], existingElementsByLocalId: Record<string, DynamicElement>,
    dataElementsOrValuesUsedInById: Record<string, UsedIn[]>, dynamicElementValidationResult: DynamicElementValidationResult) => {
    const removedElementInUse: DynamicElementInUse[] = removedDynamicElementsIds.reduce<DynamicElementInUse[]>((acc, elementId) => {
        const existingElement: DynamicElement = existingElementsByLocalId[elementId];
        const elementName = getDynamicElementName(existingElement);
        if (existingElement && dataElementsOrValuesUsedInById[elementId]?.length) {
            acc.push({
                elementId,
                elementName,
                usedIn: dataElementsOrValuesUsedInById[elementId]
            });
        }
        return acc;
    }, []);
    if (removedElementInUse.length) {
        dynamicElementValidationResult.isValid = false;
        dynamicElementValidationResult.removedUsedElementValidationError = true;
        dynamicElementValidationResult.removedElementInUse = removedElementInUse;
    }
};

const validateIdentifierDynamicElementsWasNotRemoved = (uniqueIdentifierField: string, removedDynamicElementsIds: string[], existingElementsByLocalId: Record<string, DynamicElement>,
    dynamicElementValidationResult: DynamicElementValidationResult) => {
    const isIdRemoved = removedDynamicElementsIds.some((removedDynamicElementId) => {
        const existingElement: DynamicElement = existingElementsByLocalId[removedDynamicElementId];
        const elementName: string = getDynamicElementName(existingElement);
        return elementName === uniqueIdentifierField;
    });
    if (isIdRemoved) {
        dynamicElementValidationResult.isValid = false;
        dynamicElementValidationResult.missingUniqueIdentifierColumnValidationError = true;
    }
};

export const getDataElementsOrValuesUsedInById = (
    placeholdersBySceneId: Record<string, PlaceholderContentWithNameAndType[]>,
    sceneIdsToNames: Record<string, string>,
    sceneIdsToSkipSceneAudience: Record<string, SceneSkippingAudience>,
    brandByAudience: BrandRules
): Record<string, UsedIn[]> => {
    const usedInSkipSceneLogic = getDataElementsOrValuesUsedInSkipSceneLogicById(sceneIdsToSkipSceneAudience, sceneIdsToNames);
    const usedInBrandByAudience = getDataElementsOrValuesUsedInBrandByAudienceById(brandByAudience);
    const usedInPlaceholders = getDataElementsOrValuesUsedInPlaceholdersById(placeholdersBySceneId, sceneIdsToNames);
    return [...Object.entries(usedInSkipSceneLogic), ...Object.entries(usedInBrandByAudience)].reduce<Record<string, UsedIn[]>>((acc, [id, usedIn]) => {
        if (!acc[id]) {
            acc[id] = [];
        }
        acc[id] = [...new Set([...usedIn, ...acc[id]])];
        return acc;
    }, usedInPlaceholders);
};

const getDataElementsOrValuesUsedInSkipSceneLogicById = (
    sceneIdsToSkipSceneAudience: Record<string, SceneSkippingAudience>,
    sceneIdsToNames: Record<string, string>
): Record<string, UsedIn[]> => {
    return Object.entries(sceneIdsToSkipSceneAudience).reduce<Record<string, UsedIn[]>>((acc, [ sceneId, skipSceneAudience]) => {
        const dynamicElementInUseLocalId = skipSceneAudience?.by?.localId;
        const usedIn: Omit<UsedInSkipSceneLogic, "type"> = {
            sceneName: sceneIdsToNames[sceneId],
            usedInType: "skipSceneLogic"
        };

        addToRecordList<string, UsedIn>(dynamicElementInUseLocalId, { ...usedIn, type: "element" }, acc);

        const dynamicElementValueIdsUsedIn = skipSceneAudience?.valueIds;
        for (const dynamicElementValueIdInUse of dynamicElementValueIdsUsedIn) {
            addToRecordList<string, UsedIn>(dynamicElementValueIdInUse, { ...usedIn, type: "value" }, acc);
        }

        return acc;
    }, {});
};

export const getDataElementsOrValuesUsedInPlaceholdersById = (
    placeholdersBySceneId: Record<string, PlaceholderContentWithNameAndType[]>,
    sceneIdsToNames: Record<string, string>
): Record<string, UsedIn[]> => {
    return Object.keys(placeholdersBySceneId).reduce<Record<string, UsedIn[]>>((acc, sceneId) => {
        const scenePlaceholders: PlaceholderContentWithNameAndType[] = placeholdersBySceneId[sceneId];
        for (const scenePlaceholder of scenePlaceholders) {
            const placeholderType = scenePlaceholder.type;
            const placeholderDynamicInfo = getPlaceholderContentDynamicInfo(scenePlaceholder.placeholder_content as PlaceholderContent, placeholderType);
            const dataElementsInUse = getDataElementsAndValuesFromPlaceholderContent(scenePlaceholder.placeholder_content as PlaceholderContent, true);
            const usedIn: Omit<UsedInPlaceholder, "type"> = {
                sceneName: sceneIdsToNames[sceneId],
                usedInType: "placeholder",
                placeholderName: scenePlaceholder.name,
                placeholderType: placeholderType,
                isPlaceholderDynamic: placeholderDynamicInfo.isPersonalized,
                isPlaceholderHidden: !!isPlaceholderHidden(scenePlaceholder.placeholder_settings as PlaceholderSettings)
            };

            for (const dataElementInUse of dataElementsInUse) {
                addToRecordList<string, UsedIn>(dataElementInUse.id, { ...usedIn, type: "element" }, acc);

                for (const id of dataElementInUse.valueSetIds) {
                    addToRecordList<string, UsedIn>(id, { ...usedIn, type: "value" }, acc);
                }
            }
        }
        return acc;
    }, {});
};

const getDataElementsOrValuesUsedInBrandByAudienceById = (
    brandByAudience: BrandRules
): Record<string, UsedIn[]> => {
    const usedIn: Omit<UsedInBrandByAudienceLogic, "type"> = {
        usedInType: "brandByAudienceLogic"
    };
    let recordList = {};
    if (brandByAudience?.by?.localId) {
        addToRecordList<string, UsedIn>(brandByAudience?.by?.localId, { ...usedIn, type: "element" }, recordList);
    }
    if (brandByAudience?.cases?.length) {
        for (const ruleCase of brandByAudience.cases) {
            for (const valueId of (ruleCase?.valueIds || [])) {
                if (!recordList[valueId]) {
                    addToRecordList<string, UsedIn>(valueId, { ...usedIn, type: "value" }, recordList);
                }
            }
        }
    }
    return recordList;
};

const addToRecordList = <K extends string | number, T>(key: K, value: T, recordList: Record<K, T[]>) => {
    if (!recordList[key]) {
        recordList[key] = [];
    }
    recordList[key].push(value);
    return recordList;
};

export const getDynamicElementValidationResult = (
    newDynamicElements: SerializedDynamicElement[],
    updatedDynamicElementsInput: Array<{ id: string; dynamicElementData: SerializedDynamicElement }>,
    removedDynamicElementsInput: string[],
    uniqueIdentifierField: string,
    existingElementsByLocalId: Record<string, DynamicElement>,
    placeholdersBySceneId: Record<string, PlaceholderContentWithNameAndType[]>,
    sceneIdsToNames: Record<string, string>,
    sceneIdsToSkipSceneAudience: Record<string, any>,
    brandByAudience: BrandRules
): DynamicElementValidationResult => {
    const existingElementsIds: Set<string> = new Set<string>(Object.keys(existingElementsByLocalId));

    // dataElementId/dataElementValueId -> UsedIn[]
    const dataElementsOrValuesUsedInById: Record<string, UsedIn[]> = getDataElementsOrValuesUsedInById(placeholdersBySceneId, sceneIdsToNames, sceneIdsToSkipSceneAudience, brandByAudience);

    const updatedDynamicElementsById = updatedDynamicElementsInput.reduce<Record<string, DynamicElement>>((acc, cur) => {
        acc[cur.id] = DynamicElement.deserialize(cur.dynamicElementData);
        return acc;
    }, {});
    const updatedDynamicElementsIds = Object.keys(updatedDynamicElementsById);

    let validationResult: DynamicElementValidationResult = {
        isValid: true,
        emptyNameValidationError: false,
        invalidCharNameValidationError: false,
        uniqueNameValidationError: false,
        missingElementValidationError: false,
        removedUsedElementValidationError: false,
        removedUsedValueValidationError: false,
        removedAllValuesWhenUsedInNarrationValidationError: false,
        emptyNameColumns: [],
        invalidCharNames: [],
        newElementsNames: [],
        nonUniqueNames: [],
        existingElementsNames: [],
        existingElementsIds,
        updatedDynamicElementsIds,
        dataElementsOrValuesUsedInById,
        updatedDynamicElementsById,
        removedDynamicElementsIds: removedDynamicElementsInput,
        removedElementInUse: [],
        removedValueSetInElementInUseInNarrationPH: [],
        removedValuesInUse: [],
        missingUniqueIdentifierColumnValidationError: false
    };

    // 1.1 Validate New & Update dynamic elements have names and that they're unique
    validateNamesExistAndUnique(uniqueIdentifierField, newDynamicElements, updatedDynamicElementsById, existingElementsByLocalId, validationResult);

    // 2. Validate updated dynamic elements
    validateDynamicElementsExists(updatedDynamicElementsIds, existingElementsIds, validationResult);
    validateDynamicElementsRemovedValuesNotInUse(updatedDynamicElementsById, existingElementsByLocalId, dataElementsOrValuesUsedInById, validationResult);
    validateDynamicElementWithNoValueSetNotInUseInNarrationPlaceholder(updatedDynamicElementsById, dataElementsOrValuesUsedInById, validationResult);

    // 3. Validate removed dynamic elements
    validateDynamicElementsExists(removedDynamicElementsInput, existingElementsIds, validationResult);
    validateDynamicElementsRemovedNotInUse(removedDynamicElementsInput, existingElementsByLocalId, dataElementsOrValuesUsedInById, validationResult);
    validateIdentifierDynamicElementsWasNotRemoved(uniqueIdentifierField, removedDynamicElementsInput, existingElementsByLocalId, validationResult);

    return validationResult;
};

export const getDefaultSourceFromStringElementName = (connectorType, name: string): string => {
    const target = getStringElementTarget(name);
    return getDefaultSourceFromTarget(connectorType, target).name;
};

export const getStringElementTarget = (name: string): BasicMetadata => {
    return { name, type: ElementType.STRING };
};

export const getStringElementSource = (name: string): BasicMetadata => {
    return { name, type: ElementType.STRING };
};

export const createSerializedDynamicElement = (source: BasicMetadata, target: BasicMetadata, valueSet: ValueSetItem[], index: number, pii: boolean): SerializedDynamicElement => {
    const dynamicElement = new DynamicElement(
        source,
        target,
        valueSet,
        [],
        { order: index },
        null,
        pii ?? false
    );
    return dynamicElement.serialize();
};

export const buildDynamicElement = (name: string, valueSet: ValueSetItem[], index: number, connectorType: ConnectorType, pii?: boolean): SerializedDynamicElement => {
    const target = getStringElementTarget(name);
    return createSerializedDynamicElement(getDefaultSourceFromTarget(connectorType, target), target, valueSet, index, pii ?? false);
};
