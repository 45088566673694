type ColorCombinationValues = "A" | "B" | "C" | "D" | "E";

export enum ColorNames {
    PRIMARY = "primary_color",
    SECONDARY = "secondary_color",
    ACCENT1 = "accent1_color",
    ACCENT2 = "accent2_color"
}

// DO NOT CHANGE THE MAPPING!
const ColorCombinationMapping : {[key in ColorCombinationValues]: {[key in ColorNames]: ColorNames}} = {
    "A": {
        [ColorNames.PRIMARY]: ColorNames.PRIMARY,
        [ColorNames.SECONDARY]: ColorNames.SECONDARY,
        [ColorNames.ACCENT1]: ColorNames.ACCENT1,
        [ColorNames.ACCENT2]: ColorNames.ACCENT2
    },
    "B": {
        [ColorNames.PRIMARY]: ColorNames.SECONDARY,
        [ColorNames.SECONDARY]: ColorNames.PRIMARY,
        [ColorNames.ACCENT1]: ColorNames.ACCENT2,
        [ColorNames.ACCENT2]: ColorNames.ACCENT1
    },
    "C": {
        [ColorNames.PRIMARY]: ColorNames.ACCENT1,
        [ColorNames.SECONDARY]: ColorNames.ACCENT2,
        [ColorNames.ACCENT1]: ColorNames.PRIMARY,
        [ColorNames.ACCENT2]: ColorNames.SECONDARY
    },
    "D": {
        [ColorNames.PRIMARY]: ColorNames.ACCENT2,
        [ColorNames.SECONDARY]: ColorNames.ACCENT1,
        [ColorNames.ACCENT1]: ColorNames.SECONDARY,
        [ColorNames.ACCENT2]: ColorNames.PRIMARY
    },
    "E": {
        [ColorNames.PRIMARY]: ColorNames.PRIMARY,
        [ColorNames.SECONDARY]: ColorNames.SECONDARY,
        [ColorNames.ACCENT1]: ColorNames.ACCENT2,
        [ColorNames.ACCENT2]: ColorNames.ACCENT1
    }
};

const ColorCombinationFriendlyName : {[key in ColorCombinationValues]: string} = {
    A: "Primary focus",
    B: "Secondary focus",
    C: "Accent 1 focus",
    D: "Accent 2 focus",
    E: "Accent 1 & 2 swap "
};

export const getColorNameByCombination = (colorCombination: ColorCombinationValues, colorName: string) => {
    return ColorCombinationMapping[colorCombination][colorName] ?? colorName;
};

export const mapSceneColorByCombination = (colorPlaceholders: Record<string, any>, colorCombination: ColorCombinationValues): Record<string, any> => {
    return Object.entries(colorPlaceholders).reduce((acc, bcColor) => {
        acc[getColorNameByCombination(colorCombination, bcColor[0])] = bcColor[1];
        return acc;
    }, {});
};

export const getColorCombinationFriendlyName = (colorCombination: ColorCombinationValues) => {
    return ColorCombinationFriendlyName[colorCombination];
};
