import { ACCOUNT_LIBRARY_PROGRAM_NAME } from "./commonConst";
import { replaceSpecialChar } from "./generalUtils";
import { getDefaultBreathingValue, getDefaultDurationValue, getDefaultOverrideLogoValue, getDefaultSoundValue } from "./placeholderUtils";
import { EditorLibraryMediaType } from "./types/asset";
import type {
    AlternativeVoiceSetting,
    AvatarAlignmentSetting,
    AvatarCropSetting,
    BreathingSetting,
    BreathingType,
    ButtonSettings,
    ButtonShowContentV7,
    ButtonShowContentV8,
    ButtonShowContentV9,
    ColorSetting,
    DurationSetting,
    DurationType,
    FontSizeSetting,
    HiddenSetting,
    MediaSettings,
    OverrideLogoSetting,
    SoundSetting,
    TextFitSetting,
    TextHorizontalAlignmentSetting,
    TextSettings,
    TextStyleSetting,
    TextVerticalAlignmentSetting
} from "./types/editorPlaceholder";
import {
    Adjustment,
    AvatarCropPreset,
    ButtonActions,
    ButtonShowContentItemElement,
    CommonPlaceholderType,
    ContentType,
    FontSizeUnit,
    GlobalSettingKey,
    HorizontalAlignment,
    MediaSettingKey,
    PlaceholderIntentName,
    RichTextStyleSetting,
    SoundUnit,
    TextFit,
    TextSettingKey,
    VerticalAlignment
} from "./types/editorPlaceholder";
import moment from "moment";


export const buildHiddenSetting = (): HiddenSetting => {
    return {
        useValue: true,
        value: {
            isHidden: false
        }
    };
};

export const buildColorSetting = (): ColorSetting => {
    return {
        useValue: false,
        value: {
            customColor: null,
            ccDofLocalId: null
        }
    };
};

export const buildFontSizeSetting = (): FontSizeSetting => {
    return {
        useValue: false,
        value: {
            fontSize: {
                value: 100,
                unit: FontSizeUnit.EM
            }
        }
    };
};

export const buildTextHorizontalAlignmentSetting = (): TextHorizontalAlignmentSetting => {
    return {
        useValue: false,
        value: {
            horizontalAlignment: HorizontalAlignment.Center
        }
    };
};

export const buildTextVerticalAlignmentSetting = (): TextVerticalAlignmentSetting => {
    return {
        useValue: false,
        value: {
            verticalAlignment: VerticalAlignment.Center
        }
    };
};

export const buildTextStyleSetting = (): TextStyleSetting => {
    return {
        useValue: false,
        value: {
            style: "" //TODO: default value. Should we use id from brand??
        }
    };
};

export const buildTextFitSetting = (): TextFitSetting => {
    return {
        useValue: false,
        value: {
            fit: TextFit.ResizeFont
        }
    };
};

export const buildBreathingSetting = (value: Record<"breathing", BreathingType> | null): BreathingSetting => {
    return {
        useValue: value !== null,
        value: value
    };
};

export const buildMediaSoundSetting = (useValue: boolean = true, value: number = 100): SoundSetting => {
    return {
        useValue,
        value: {
            value,
            unit: SoundUnit.Percent
        }
    };
};

export const buildOverrideLogoSetting = (): OverrideLogoSetting => {
    return {
        useValue: false,
        value: {
            initialized: false
        }
    };
};

export const buildDurationSetting = (useValue: boolean, duration: DurationType): DurationSetting => {
    return {
        useValue,
        value: {
            duration
        }
    };
};

export const buildMediaSettings = (intentName: PlaceholderIntentName): MediaSettings => {

    const mediaAdjustment = [PlaceholderIntentName.Logo, PlaceholderIntentName.Icon].includes(intentName)
        ? Adjustment.ScaleToFit
        : Adjustment.ScaleToFill;

    const settings: MediaSettings = {
        type: ContentType.Media,
        settings: {
            [GlobalSettingKey.IsHidden]: buildHiddenSetting(),
            [MediaSettingKey.Adjustment]: {
                useValue: true,
                value: { mediaAdjustment: mediaAdjustment }

            },
            [MediaSettingKey.Alignment]: {
                useValue: false,
                value: { mediaAlignment: { vertical: VerticalAlignment.Center, horizontal: HorizontalAlignment.Center } }
            },
            [MediaSettingKey.Color]: buildColorSetting(),
            [MediaSettingKey.Breathing]: getDefaultBreathingValue(intentName),
            [MediaSettingKey.Duration]: getDefaultDurationValue(),
            [MediaSettingKey.Sound]: getDefaultSoundValue(),
            [MediaSettingKey.OverrideLogo]: getDefaultOverrideLogoValue()
        }
    };

    return settings;
};

export const buildButtonSettings = (): ButtonSettings => {

    const settings: ButtonSettings = {
        type: ContentType.Button,
        settings: {
            [GlobalSettingKey.IsHidden]: buildHiddenSetting()
        }
    };

    return settings;
};

export const buildTextSettings = (): TextSettings => {
    const settings: TextSettings = {
        type: ContentType.Text,
        settings: {
            [TextSettingKey.Color]: buildColorSetting(),
            [TextSettingKey.FontSize]: buildFontSizeSetting(),
            [TextSettingKey.BackgroundColor]: buildColorSetting(),
            [TextSettingKey.HorizontalAlignment]: buildTextHorizontalAlignmentSetting(),
            [TextSettingKey.VerticalAlignment]: buildTextVerticalAlignmentSetting(),
            [GlobalSettingKey.IsHidden]: buildHiddenSetting(),
            [TextSettingKey.Style]: buildTextStyleSetting(),
            [TextSettingKey.Fit]: buildTextFitSetting()
        }
    };

    return settings;
};

export const buildAvatarCropSetting = (): AvatarCropSetting => {
    return {
        useValue: true, // There's always a default value
        value: {
            crop: AvatarCropPreset.FULL_BODY
        }
    };
};

export function buildAvatarAlignmentSetting(): AvatarAlignmentSetting {
    return {
        useValue: true, // There's always a default value
        value: {
            alignment: {
                vertical: VerticalAlignment.Bottom,
                horizontal: HorizontalAlignment.Center
            }
        }
    };
}

export const buildNarrationAlternativeVoiceSetting = (): AlternativeVoiceSetting => {
    return {
        useValue: false,
        value: null
    };
};

export const getDefaultButtonShowContentV7 = (text: string): ButtonShowContentV7 => {
    return [
        { name: "text", content: [text], type: CommonPlaceholderType.TEXT, isContentSameAsButtonText: true },
        { name: "url", content: [""], type: CommonPlaceholderType.TEXT, isContentSameAsButtonText: false },
        { name: "label", content: [""], type: CommonPlaceholderType.TEXT, isContentSameAsButtonText: true },
        { name: "report", content: [""], type: CommonPlaceholderType.TEXT, isContentSameAsButtonText: true }
    ];
};

export const getDefaultButtonShowContentV8 = (text: string): ButtonShowContentV8 => {
    return [
        { name: "text", content: [text], type: CommonPlaceholderType.TEXT, isContentSameAsButtonText: true },
        { name: "url", content: [""], type: CommonPlaceholderType.TEXT, isContentSameAsButtonText: false },
        { name: "label", content: [""], type: CommonPlaceholderType.TEXT, isContentSameAsButtonText: true },
        { name: "report", content: [""], type: CommonPlaceholderType.TEXT, isContentSameAsButtonText: true }
    ];
};

export const getDefaultButtonShowContentV9 = <TextShow, RichTextShow>(text: string): ButtonShowContentV9<TextShow, RichTextShow> => {
    return {
        [ButtonShowContentItemElement.Text]: {
            content: [{
                content: text,
                style: {
                    bold: RichTextStyleSetting.UNSET,
                    italic: RichTextStyleSetting.UNSET
                }
            }] as RichTextShow,
            isContentSameAsButtonText: true
        },
        [ButtonShowContentItemElement.URL]: {
            content: [{
                content: ""
            }] as TextShow,
            isContentSameAsButtonText: false
        },
        [ButtonShowContentItemElement.Report]: {
            content: [{
                content: ""
            }],
            isContentSameAsButtonText: true
        }
    };
};

export const initiateButtonActionShow = <ButtonShowContent, ButtonActionShow>(defaultValue: ButtonShowContent): ButtonActionShow => {
    const buttonActions = {} as ButtonActionShow;
    Object.keys(ButtonActions).forEach(key => {
        buttonActions[key] = defaultValue;
    });
    return buttonActions;
};

export const generateEditorProjectId = (programName: string, accountName: string, source: string, programId: string): string => {
    return `${source}/${replaceSpecialChar(accountName, "_")}/${replaceSpecialChar(programName, "_")}/${programId}`;
};

export const isEditorAccountLibraryProgram = (programName: string): boolean => {
    return programName === ACCOUNT_LIBRARY_PROGRAM_NAME;
};

export const ANIMATOR_TEST_ACCOUNT = "0015a00002oTaPWAA0";

export const isAnimatorTestAccount = (accountId: string): boolean => {
    return accountId === ANIMATOR_TEST_ACCOUNT;
};

export const getAssetLibraryMediaType = (mediaTypeStr: string): EditorLibraryMediaType => {
    switch (mediaTypeStr) {
        case EditorLibraryMediaType.Image:
            return EditorLibraryMediaType.Image;
        case EditorLibraryMediaType.Video:
            return EditorLibraryMediaType.Video;
        default:
            throw Error(`Unsupported media type ${mediaTypeStr}`);
    }
};

export const ANIMATOR_TEST_VSB_NAME = "TestLayout.vsb";

export const VIEWER_PROFILE_CONTENT_VERSION: string = "1";

export const IDENTIFIER_DYNAMIC_ELEMENT_NAME: string = "id";

export const SYSTEM_ELEMENT_ORIGIN: string = "system";

export const getDuplicateName = (sourceName: string, usedNames: string[]): string => {
    let duplicatedName = `Copy of ${sourceName}`;
    let index = 1;
    while (usedNames.includes(duplicatedName.toLowerCase())) {
        duplicatedName = `Copy ${++index} of ${sourceName}`;
    }

    return duplicatedName;
};

const MAX_FILENAME_LENGTH = 180;

export function createDownloadedVideoFileName(
    programName: string,
    viewerProfileName: string | null,
    allScenesSelected: boolean,
    selectedSceneNumbers: number[],
    fileFormat: string,
    includeExtension = true
): string {
    const fullName = viewerProfileName
        ? `${programName}_${viewerProfileName}`
        : programName;
    const fileExtension = `.${fileFormat}`;

    let sceneSuffix = "";
    if (!allScenesSelected) {
        const sceneNumbers = selectedSceneNumbers.join("_");
        const suffixResult = selectedSceneNumbers?.length > 3 ? `_Scenes${sceneNumbers}_etc` : `_Scenes${sceneNumbers}`;
        sceneSuffix += suffixResult;
    }

    return (
        fullName.slice(
            0,
            MAX_FILENAME_LENGTH
            - sceneSuffix.length
            - fileExtension.length
        )
        + sceneSuffix
        + (includeExtension ? fileExtension : "")
    );
}

/*
    * This function is used to format the date in the transcript file and file name.
    * @param date - the date to format default is the current date
    * @returns the formatted date
 */
export const getTranscriptFormattedDate = (date?: Date): string => {
    return moment(date).format("MMM D YYYY, hh:mm A");
};
