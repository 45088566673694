/**
 * this file describes the shape of VideoSpec Resource
 * https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/164200459/Smart-Video+API#Smart-VideoAPI-VideoSpecResource
 */

type URI = string;

type Href = {
    href: URI;
    role?: string;
};

export enum RendererGeneration {
    V2019 = "V2019"
}

// IMPORTANT: for all the enums below, their string value must be the exact string that is sent to RaaS via videospec.

export enum Renderer {
    V2018 = "2",
    V2019 = "3"
}

export enum VideoQualityProfile {
    SD = "sd",
    SD_INTERMEDIATE = "sd-intermediate",
    SD_HIGH = "sd-high",
    HD_READY = "hd-ready",
    HD_READY_INTERMEDIATE = "hd-ready-intermediate",
    HD_READY_HIGH = "hd-ready-high",
    HD = "hd"
}

export enum VideoAspectRatio {
    FULL_LANDSCAPE = "16:9",
    FULL_PORTRAIT = "9:16",
    SQUARE = "1:1",
    PORTRAIT = "4:5"
}

export enum RendererResolutionFactor {
    FACTOR1 = "1.0",
    FACTOR2 = "2.0"
}

export enum VideoFormat {
    m3u8 = "m3u8", // better name?
    webm = "webm",
    mp4 = "mp4"
}

//////////////////////////////////////////////////////////

export type Analytics = {
    story: string;
    storyId: string;
};
export type OverAllLayer = {
    content: any;
    audioFadeOutDuration?: number;
}
export type Background = {
    image?: Href | null;
    audio?: Href | null;
    video?: Href | null;
};

export type LogicDataElements = {};

export type Settings = {};

export type Formats = {
    video: {
        format: VideoFormat;
    };
};

export type Dimensions = {
    width: number;
    height: number;
};

export type Output = {
    formats: Formats;
    renderer?: {
        rendererGeneration: RendererGeneration;
    };
    videoAspectRatio?: VideoAspectRatio;
    videoQualityProfile?: VideoQualityProfile
    dimensions?: Dimensions;
};

export enum TransitionStyle {
    Overlay = "overlay",
    Underlay = "underlay"
}

export type Transition = {
    duration: string | number; // time in decimals, ex 0.52 (13 frames). Must be a dividend of 0.04 (1 second / 25 frames = 0.04)
    style: TransitionStyle;
};

export type Scene = {
    name: string;
    animation: Href;
    data: any;
    transition: Transition;
};

export type Lineup = Scene[];

export type VideoSpec = {
    lineup: Lineup;
    projectid: string;
    output: Output;
    background?: Background;
    animationBase?: string;
    "logic-data-elements"?: LogicDataElements;
    settings?: Settings;
    analytics?: Analytics;
    backgrounds: OverAllLayer[]
    foregrounds?: OverAllLayer[]
};

export type VolumeSettings = {
    value: number;
    unit: "%"
};
