import type { Asset, AssetTypes } from "./asset";

export enum DataTableOutputTypes {
    String = "string",
    URL = "url",
    Color = "color"
}

export interface DataTableInput {
    displayName: string;
    id?: string;
    name: string;
    type: string;
}

export interface DataTableOutput {
    id: string;
    name: string;
    type: string;
    text?: string; // appears only in DataLibraryController: downloadMappingTableAndConvertToCsvContent
}

export type DataTableBody = {
    scheme: {
        inputs: DataTableInput[],
        outputs: DataTableOutput[],
    },
    data: {[key: string]: any}[]
};

export interface DataTable extends Asset {
    type: AssetTypes.mappingTable;
    graphQLId?: string;
    graphQLUpdated?: string;
    url?: string;
    decisionTablesLocation: string;
    mappingTableScheme: {
        inputs: DataTableInput[];
        outputs: DataTableOutput[];
    };
}